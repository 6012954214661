<div class="banner__slider">
    <div>
        <div class="container">
            <div class="slider__grid">
                <div class="item__half--one">
                    <h3 class="is-hidden-mobile">About Health Professionals</h3>
                    <h1 class="no-mg-bottom-mobile has-margin-bottom-xl has-margin-top-sm has-text-centered-mobile">
                        Redefining healthcare with the best AHP and HSS staff.</h1>

                </div>
                <div class="item__half--two">
                    <img src="../assets/png/nurses-put-first.webp" alt="" />
                </div>
            </div>
        </div>
    </div>
</div>

<section class="section light__green--bg has-padding-top-none curved">
    <div class="container">
        <h3>Let us make a difference to your organisation - call today <a href="tel:020 7201 1140">020 7201 1140</a>
        </h3><br />
        <p>With over two decades of excellence, our Health Science Service Staff (HSS) and Allied Health Professionals (AHP) have been pivotal in elevating patient care standards across numerous healthcare settings.</p>

        <p>Our commitment goes beyond just providing skilled professionals. We emphasize continual learning and adaptation to the latest healthcare advancements. Our professionals are not just experts in their fields; they are also compassionate individuals who prioritize patient well-being above all else.</p>

        <p>The cornerstone of our service is the advanced technology that seamlessly integrates with existing healthcare systems. This integration allows for real-time data exchange, ensuring that our teams are always equipped with the most current information, enhancing the accuracy and efficiency of patient care. Our technology solutions extend our capabilities, enabling us to respond swiftly to the dynamic needs of healthcare providers.</p>

        <p>Whether it's a routine check-up or an emergency situation, our HSS and AHP teams are ready to step in with their expertise. We understand the critical nature of timely and professional health services, which is why we are just a phone call away. Reach out to us at <a href="tel:020 7201 1140">020 7201 1140</a> for reliable, high-quality healthcare support. Experience the difference with a team that's more than just service providers; we're your partners in health.</p>
    </div>
</section>

<section class="has-padding-top-lg has-padding-bottom-lg">
    <div class="container">
        <h2 class="has-text-centered">Testimonial</h2>
        <!-- <hr class="short__hr" /> -->
        <i>
            <h4 class="has-text-centered has-margin-top-md">“Whenever I have spoken to members of the team on the phone,
                I’ve always found them polite and helpful, and have built a rapport with them all. In our line of work I
                feel it is so important to have this type of relationship and I am happy to say that I have this with
                the Health Professionals’ team.” </h4>
        </i>
        <b>
            <h4 class="has-text-centered has-margin-top-md">Emma Hill, Temporary Staffing Team Leader, Ashford & St
                Peter’s Hospitals NHS Trust </h4>
        </b>
    </div>
    <div class="level-item">
        <a routerLink="/Q&A" class="lime__green--btn has-text-centered-mobile is-block-mobile">Read more</a>
    </div>
</section>


<div class="curved__top">
    <section class="section light__green--bg has-padding-top-none">
        <div class="container" *ngIf="formSubmitted && formFailure">
            <h2 class="has-text-centered">We were unable to process your form submission</h2>
        </div>
        <div class="container" [hidden]="formSubmitted && formSuccess">
            <h2 class="has-text-centered">Contact</h2>
            <div class="white__wrapper has-margin-top-lg">
                <form [formGroup]="formGroup" (submit)="processForm()" class="has-margin-top-md">
                    <div class="field is-horizontal has-padding-bottom-md">
                        <div class="field-label is-normal is-flex is-align-items-center">
                            <label class="label">Name</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <input formControlName="firstName" class="input" type="text" placeholder="First name"
                                    [ngClass]="formSubmitted && f.firstName.errors ? 'is-invalid' : ''">
                            </div>
                            <div class="field">
                                <input formControlName="lastName" class="input" type="text" placeholder="Last name"
                                    [ngClass]="formSubmitted && f.lastName.errors ? 'is-invalid' : ''">
                            </div>
                        </div>
                    </div>

                    <div class="field is-horizontal has-padding-bottom-md">
                        <div class="field-label is-normal is-flex is-align-items-center">
                            <label class="label">Phone</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <input formControlName="phone" class="input" type="tel"
                                    [ngClass]="formSubmitted && f.phone.errors ? 'is-invalid' : ''">
                            </div>
                        </div>
                    </div>

                    <div class="field is-horizontal has-padding-bottom-md">
                        <div class="field-label is-normal is-flex is-align-items-center">
                            <label class="label">Email</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <input formControlName="email" class="input" type="email"
                                    [ngClass]="formSubmitted && f.email.errors ? 'is-invalid' : ''">
                            </div>
                        </div>
                    </div>
                    <div class="field is-horizontal has-padding-bottom-md">
                        <div class="field-label is-normal is-flex is-align-items-center">
                            <label class="label">Your Questions</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <textarea style="height:200px; max-width: none;" formControlName="questions"
                                    class="input" rows="3" cols="5" placeholder="Expand with right corner." type="text"
                                    [ngClass]="formSubmitted && f.questions.errors ? 'is-invalid' : ''">
                </textarea>

                            </div>
                        </div>
                    </div>

                    <hr />

                    <div class="field is-horizontal has-padding-bottom-md is-justify-content-space-between">
                        <div class="is-normal is-flex has-padding-bottom-md is-align-items-center">
                            <label class="label">Do you consent to us calling you?</label>
                        </div>
                        <div class="field">
                            <div class="control">
                                <div class="containered custom-radio">
                                    <label class="radio">
                                        <input class="has-margin-right-sm" type="radio" value="Yes"
                                            formControlName="callingConsent"
                                            [ngClass]="formSubmitted && f.callingConsent.errors ? 'is-invalid' : ''">
                                        <a class="my-button-one">Yes</a>
                                    </label>
                                    <label class="radio">
                                        <input class="has-margin-right-sm" type="radio" value="No"
                                            formControlName="callingConsent"
                                            [ngClass]="formSubmitted && f.callingConsent.errors ? 'is-invalid' : ''">
                                        <a class="my-button">No</a>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- CAPTCHA -->
                    <!-- <div class="g-recaptcha" style="transform: scale(0.77); -webkit-transform: scale(0.77); transform-origin: 0 0; -webkit-transform-origin: 0 0;" data-theme="light">
                        <re-captcha (resolved)="resolved($event)" siteKey="6LfaYXcUAAAAAOBGI_d6YSUs2Fb2rVVf7ajE2MiJ"></re-captcha>
                    </div> -->
                    <div class="submit-or-loading">
                        <button *ngIf="!isLoading" class="dark__blue--btn has-margin-top-lg centered" type="submit">
                            Submit Form
                        </button>

                        <div *ngIf="isLoading" class="spinner-container">
                            <div class="spinner"></div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="container is-flex is-justify-content-center is-flex-direction-column"
            *ngIf="formSubmitted && formSuccess && !isLoading">
            <h2 class="has-text-centered m-auto">Thank You for submitting our form. Our recruitment team will reach out
                to you as soon possible.</h2>

        </div>
        <div class="container is-flex is-justify-content-center is-flex-direction-column"
            *ngIf="formSubmitted && formFailure && !isLoading">
            <h2 class="has-text-centered">We were unable to process your form submission</h2>
        </div>
    </section>
</div>