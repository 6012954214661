<section class="section">
  <div class="container">
    <h2>Terms of Use of the Health Professionals Websites</h2>

    <p>
      The Health Professionals website (“our website”) is comprised of various webpages operated by Health Professionals Recruitment Services Limited (a company registered in England, number 3460761; registered office: 9-11 New Road, Bromsgrove, Worcestershire, B60 2JF)(“we”, “us”, “our”). Your use of our website constitutes your agreement to all such terms, conditions, and notices.
    </p>
    <p><span class="SubHead">Modification of these terms of use</span></p>
    <p>
      We reserve the right to change the terms, conditions, and notices under which our website is offered at any time.
    </p>
    <p><span class="SubHead">Blog entries</span></p>
    <p>
      All blog entries are produced by members of our team. We endeavour to ensure that any factual information is accurate, but if you are aware of anything that may be incorrect or misleading, please immediately inform us of the precise nature of the issue and which blog entry it appears in – you can send to website@healthprofessionals.co.uk.  Any opinions voiced in a blog entry is, however, that of the writer of the individual entry concerned and is not necessarily the opinion of Health Professionals or anyone else at our company - its inclusion in our blog is not to be taken as an endorsement of that opinion for any purposes.
    </p>
    <p><span class="SubHead">Representations </span></p>
    <p>
      Any information or representations in our website are made in good faith and to the best of our knowledge, but we cannot accept responsibility for any errors and any representations on which you want to be able to rely before dealing with us must be confirmed by us by email in order for that representation to have any legal effect.
    </p>
    <p><span class="SubHead">No unlawful or prohibited use </span></p>
    <p>
      You may not use our website for any unlawful purpose nor in any manner which could damage, disable, overburden, or impair our website or interfere with any other party's use and enjoyment of it. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through our website.
    </p>
    <p><span class="SubHead">Links to third party websites </span></p>
    <p>
      Our website may contain links to other websites ("Linked Sites"). The Linked Sites are not under our control and we are not therefore responsible for the contents of any Linked Site. The inclusion of any link does not imply endorsement by us of the site or any association with its operators.
    </p>
    <p><span class="SubHead">Copyright </span></p>
    <p>
      All contents of our website including our blog entries are the copyright of Health Professionals Recruitment Services Limited. All rights are reserved.
    </p>
    <p><span class="SubHead">Applicable laws and dispute jurisdiction </span></p>
    <p>
      These terms will be interpreted in accordance with the laws of England and Wales and, in the unlikely event of a dispute, be subject to the exclusive jurisdiction of English courts of law.
    </p>
    <h2>MyNurseApp Terms of Use </h2>
    <p>
      HealthPro NurseApp (“MyNurseApp”) is an availability and booking management service application for mobile phone that is made available to Health Professionals workers in the UK and by using MyNurseApp, you agree to these terms.
    </p>
    <p><span class="SubHead">Passcode</span></p>
    <p>
      Access to our pages on a mobile phone is granted on the basis that a passcode is enabled on your phone for added security of your personal data. We also ask that you only view our content on a phone that is not a jail-broken or rooted device.
    </p>
    <p><span class="SubHead">Uploading content </span></p>
    <p>
      If in the future we allow you to upload images or other content to MyNurseApp, you must not upload or store inappropriate or illegal images or content.
    </p>
    <p>
      Cookies
    </p>
    <p>
      Health Professionals will use cookies technologies (‘cookies’) when you register, to authenticate you when you use the service, and to generally improve your experience of using MyNurseApp. By using MyNurseApp, you accept our use of cookies as set out here. It is your duty to ensure the security of your information.
    </p>
    <p>
      Cookies will also be used to collect information about your use of this website, for example, whether you encounter any error messages. This information helps us improve MyNurseApp’s performance and helps us make changes that enhanced your experience of using MyNurseApp.
    </p>
    <p><span class="SubHead">Security </span></p>
    <p>
      Please keep your mobile device secure and log out of MyNurseApp if you are not using it. If you suspect that someone else knows your passcode or other security details, you must contact us immediately. You must ensure that your account details, and other information you provided, are correct and up to date and notify us immediately of any changes.
    </p>
    <p><span class="SubHead">Misuse of your phone by a third party </span></p>
    <p>
      If you decide to save your password in the browser in your mobile phone and someone is able to access and tamper with it as a result for whatever reason or if you share your accounts access details with anyone, you are responsible for whatever is done in your account. So, if your phone is lost or stolen or simply accessed by someone with a grudge against you when you are not looking, you could find your account being tampered with and you will be held responsible for the consequences. So, if in doubt, do NOT save it in the browser, as this is the only failsafe way to protect your account. Also, we strongly recommend that you set your phone to lock after a short time of being idle and only allow access upon use of a PIN, password, thumbprint or face recognition.
    </p>
    <p><span class="SubHead">Live shifts  </span></p>
    <p>
      All live shifts details are obtained from the NHS by our automated system without human input and we are therefore unable to accept responsibility for any errors it may contain as their content is not within our control. However, in our experience, such information is almost invariably reliable and problems do not normally occur.
    </p>
    <p><span class="SubHead">Bookings  </span></p>
    <p>
      We will look to book you into shifts that match your availability, hospital preference and preferred shift pattern but we do guarantee to do so, given the nature of our service.
    </p>
    <p><span class="SubHead">Availability </span></p>
    <p>
      Once your availability is set on our system, please provide us with 24 hours' notice of any changes. Our NurseApp will allow you to add your availability into the future, but we only focus on a 3-month future period. Certain functionality (such as uploading content to MyNurseApp) will need to access information on your device to work. By using such functionality, you consent to MyNurseApp accessing your device and information. If we can, we may ask you again before MyNurseApp accesses your device or information.
    </p>
    <p><span class="SubHead">Applicable rate of pay  </span></p>
    <p>
      Please keep your mobile device secure and log out of MyNurseApp if you are not using it. If you suspect that someone else knows your passcode or other security details, you must contact us immediately. You must ensure that your account details, and other information you provided, are correct and up to date and notify us immediately of any changes.
    </p>
    <p><span class="SubHead">Use of location data</span></p>
    <p>
      In the future we may make use of location data sent from your mobile device. If you use these services, you consent to use of your location data and queries to provide and improve location-based and road traffic-based products and services. You can turn off this functionality at any time by turning off the location services settings for MyNurseApp or for your phone in general. Access to maps is subject to the following terms and conditions (depending on your operating system); (Google Maps/ Google Earth APIs Terms and Conditions available at https://www.google.com/accounts/TOS - when you access Google Maps; iOS Maps Terms of Use available at http://www.apple.com/legal/sla/ - when you access iOS Maps.
    </p>
    <p><span class="SubHead">Use of NurseApp  </span></p>
    <p>
      You agree:
    </p>
    <ul>
      <li>not to use the HealthPro NurseApp in any unlawful manner, for any unlawful purpose or in any manner inconsistent with this agreement, or act fraudulently or maliciously, for example, by hacking into or inserting malicious code into MyNurseApp or iOS, Android, BlackBerry, Windows or other operating systems; </li>
      <li>not to upload, store or share inappropriate, unlawful or illegal images or content that breaches the rights of others; </li>
      <li><u>not collect or harvest any information or data from NurseApp or our systems or attempt to decipher any transmissions to or from the servers running MyNurseApp;</u> </li>
      <li>not to share the functionality, interface and/or your login with any of our competitors.</li>
    </ul>
    <p><span class="SubHead">Charges </span></p>
    <p>
      We do not charge you for using MyNurseApp, but your service provider may apply network charges for using MyNurseApp, as an internet connection is always required for its use, given its nature as a web application.
    </p>
    <p><span class="SubHead">Liability for Down Time or System Errors </span></p>
    <p>
      If something goes wrong with MyNurseApp, we will endeavour to address the issue as quickly as possible. It should be noted, however, that we cannot be held responsible for any loss as a result of using MyNurseApp or of its temporary failure or non-availability.
    </p>
    <p><span class="SubHead">No Obligation to Provide Work  </span></p>
    <p>
      Similarly, registration with us for work and for use of MyNurseApp does not impose any obligation on us to provide you with work. However, obviously it is in our best interests to do so where we reasonably can.
    </p>
    <p><span class="SubHead">Your Warranty of Accuracy  </span></p>
    <p>
      You warrant to us when signing up for a MyNurseApp account that all the information you to us is accurate and up-to-date and that you will inform us immediately that any such information changes. Where relevant, you warrant that you have proof of that information - for example, regarding your qualifications and experience - and you agree to supply it to us promptly on demand.
    </p>
    <p><span class="SubHead">NurseApp and other Terms and Conditions  </span></p>
    <p>
      These terms relate to your use of MyNurseApp and supplement other obligations between you and Health Professionals to which you are legally subject, such as your terms of engagement, confidentiality terms, data protection terms and related or other codes of conduct.
    </p>
    <p><span class="SubHead">Changes to NurseApp Terms and Conditions  </span></p>
    <p>
      We may change these terms from time to time and you will be notified of any new version by SMS, email or  when you next sign in to MyNurseApp - at that point you will be told that they have been revised and that if you proceed to use MyNurseApp, the new terms will then apply from then on. The new terms may be displayed on-screen and you will be required to read and accept them to continue your use of MyNurseApp.
    </p>
    <p><span class="SubHead">Applicable laws and jurisdiction  </span></p>
    <p>
      These terms will be interpreted strictly in accordance with the Laws of England and Wales and in the unlikely event of a dispute, that dispute will be subject to the exclusive jurisdiction of English courts of law.
    </p>
    <p><span class="SubHead">Privacy Statement </span></p>
    <p>
      Health Professionals takes privacy very seriously, so before you use MyNurseApp, please read the terms of our privacy statement which will apply to your use of MyNurseApp. Our privacy terms may be accessed on our website at any time at https://www.healthprofessionals.co.uk/privacy
    </p>


  </div>
</section>
