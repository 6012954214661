import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-live-shifts-grid',
  templateUrl: './live-shifts-grid.component.html',
  styleUrls: ['./live-shifts-grid.component.css']
})
export class LiveShiftsGridComponent implements OnInit {
  liveShifts: Object[];

  constructor() {
    this.liveShifts = [
      
    ]
  }

  ngOnInit() {
  }

}
