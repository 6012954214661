<section class="curved">
    <div class="section light__green--bg has-padding-top-none ">
        <div class="container">
            <h2 class="has-text-centered has-padding-bottom-lg">Case Study: Contract Execution for Ashford and St
                Peter's Hospitals NHS
                Foundation Trust</h2>

            <h4 class="has-text-centered">Customer Profile</h4>
            <p>Ashford and St Peter's Hospitals NHS Foundation Trust, inaugurated on the 1st of April 1998, is
                Surrey's foremost provider of acute hospital services. It caters to the healthcare needs of over
                380,000 people across Runnymede, Spelthorne, Woking, and select areas of Elmbridge, Hounslow, and
                Surrey Heath, among others. With approximately 4,400 employees and a £429 million turnover for the
                2022/23 period, the Trust primarily functions through two sites: St Peter's Hospital in Chertsey and
                Ashford Hospital in Ashford. These sites offer comprehensive services including critical care,
                accident and emergency, outpatient services, and community health services.</p>

            <h4 class="has-text-centered has-padding-top-lg">Customer Requirements</h4>
            <p>Specialist staffing in departments such as A&E and Intensive Therapy Units (ITU) was a key need for
                Ashford and St Peter's Hospitals NHS Foundation Trust. Health Professionals, maintaining a
                relationship with the Trust since the early 2000s, has consistently delivered highly qualified
                nurses and applied advanced technology to meet these specialised needs. The contribution of Health
                Professionals has been essential in bolstering the Trust’s capacity, particularly in neonatal and
                ITU services. Our support has been notably significant in the Emergency Department and ITU staffing.
            </p>
            <h4 class="has-text-centered has-padding-top-lg">Compliance</h4>
            <p>Health Professionals upholds stringent recruitment and training protocols to ensure all staff
                provided to the Trust are fully credentialed and meet the highest ethical standards. Our commitment
                to compliance is substantiated by our most recent PwC audit, which reflected a 100% compliance rate
                in the inspection of worker records. This dedication is vital for upholding the standard of care and
                ensuring patient safety.</p>
            <h4 class="has-text-centered has-padding-top-lg">Contract Management and Savings Delivery</h4>
            <p>The contract management strategy employed by Health Professionals with the Trust has been centred on
                providing sustainable and economically viable staffing solutions. Utilising technological tools such
                as the <a routerLink="/mynurseapp">MyNurseApp</a> has improved scheduling effectiveness, enhanced communication, and introduced
                greater flexibility, thereby diminishing the need for more costly staffing resources. This
                methodology has facilitated not only a smooth operational cadence but also significant financial
                savings for the Trust.</p>
            <h4 class="has-text-centered has-padding-top-lg">Conclusion</h4>
            <p>The enduring partnership with Ashford and St Peter's Hospitals NHS Foundation Trust illustrates
                Health Professionals' proficiency in delivering customised staffing solutions that precisely cater
                to the healthcare sector's requirements. This collaboration has been instrumental in upholding
                superior patient care standards and has exemplified Health Professionals as an efficient,
                dependable, and ethically oriented partner in the healthcare staffing field.</p>
        </div>
    </div>
</section>