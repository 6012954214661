import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nurse-of-the-month',
  templateUrl: './nurse-of-the-month.component.html',
  styleUrls: ['./nurse-of-the-month.component.css']
})
export class NurseOfTheMonthComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
