import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ashford-stpeters',
  templateUrl: './ashford-stpeters.component.html',
  styleUrls: ['./ashford-stpeters.component.scss']
})
export class AshfordStpetersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
