<section class="section has-padding-bottom-lg">
    <div class="container">
        <h2 class="has-text-centered">Frequently Asked Questions</h2>
    </div>
</section>

<div class="curved">
    <section class="section light__green--bg has-padding-top-none has-padding-bottom-none">
        <div class="container">
            <details open>
                <summary>Q. How can I find out more about your rates, terms and conditions and other questions specific to the opportunity that I’m looking to fill?</summary>
                <p>Either call us on +44 (0) 207 201 1143 or email us with your contact details so that we can call you back. One of our team will be able to help you with all this detail there and then. </p>
            </details>
            <details>
                <summary>Q. Do you only operate on a preferred supplier or exclusive supplier basis?</summary>
                <p>We don’t stipulate our working terms, however, we find that once a client has worked with us on a project, they prefer to work either in a preferred or exclusive capacity. It’s great testimony to our capabilities and service and, of course,
                    delivers the service that each client is looking for.</p>
            </details>
            <details>
                <summary>Q. Can you supply specialist nurses – for example, an oncology nurse – at little or no notice? For instance, could you supply a nurse to help us tonight?</summary>
                <p>We manage an on-call list for exactly this purpose – to supply specialist nurses with same day availability. We will check with each nurse that they are able to work later that day and call you to confirm. We find this service helps commercial
                    and public sector organisations manage unexpected staff absences and provide seamless cover.
                </p>
            </details>
            <details>
                <summary>Q. As part of our Health and Safety (H&SE) requirements, we need to have a nurse available to employees on our sites. Can you help with this?</summary>
                <p>Yes, our occupational health Nurses are highly trained and equipped to assist organisations with a wide range of occupational health procedures.</p>
            </details>
            <details>
                <summary>Q. We have a project coming up where we need to have a nurse on standby to ensure we meet H&SE legislation. Can you help with this?</summary>
                <p>occupational health nursing is one of our specialist areas. Unlike other nursing agencies, we have registered nurse recruiters with recent occupational Health experience that can assist you in selecting the right candidates. It is important
                    for us to be sure our service matches your requirements exactly. </p>
            </details>
            <details>
                <summary>Q. How do you vet assignees?</summary>
                <p>
                    All of our candidates undergo a thorough face-to-face interview process incorporating core NHS standards at our office (or at our Interview Centres throughout the UK) with our registered nursing team. During this process, their necessary documentation
                    is scanned into our secure document management system. Our registration process is regularly audited by the NHS and we work to the NHS Employers Employment Checks and Standards.
                </p>
                <p>
                    In addition, we are NHS suppliers under NHS contracts and have received the highest level of commendation by the CQC for our recruitment processes so you can be sure that the candidates we present have been very carefully vetted.
                </p>
            </details>
            <details>
                <summary>Q. Do you offer ongoing training and support? What form does this take and how do you manage it?
                </summary>
                <p>
                    We offer a comprehensive and ongoing training process to make it as easy as possible for our nurses to keep their skills and knowledge current. In addition, we regularly hold day and evening events for our nurses which have included presentations from
                    specialist teams for research, ITU and IVF nurses, and specialist events for theatre nurses.
                </p>
                <p>
                    In addition, we pride ourselves on the support we offer from assisting with the immigration processes when a candidate moves to the UK from abroad to social events that allow nurses to network with peers and colleagues and share ideas, experiences and
                    informal support.
                </p>
            </details>
            <details>
                <summary>Q. What experience and training do your nurses have?</summary>
                <p>We request that our carers have a minimum of 6 months experience and nurses must have experience and up-to-date skills.</p>
            </details>

            <!--<a routerLink="/" class="dark__blue--btn centered">Have another question? Send it to us!</a>-->
        </div>
    </section>
</div>