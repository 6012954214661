<div class="curved">
    <section class="section light__green--bg has-padding-top-none has-padding-bottom-none">
        <div class="container">
            <h2 class="has-text-centered">Refer a Friend Scheme at Health Professionals</h2>
        </div>
    </section>
</div>
<br><br>
<div class="">
    <section class="section light__green--bg has-padding-top-none has-padding-bottom-xl">
        <div class="container">
            <div class="columns is-vcentered is-multiline">
                <div class="column is-8 has-padding-top-none">
                    <br><br>
                    <h2>Referral Scheme</h2>
                    <p> At Health Professionals, we highly value our dedicated team of nurses. We are always on the
                        lookout for outstanding health professionals who can contribute positively to our mission. To
                        help us with this, we are excited to introduce our "Refer a Friend" scheme. </p>

                    <p>If you know of exceptional individuals in the nursing field who would be a great fit for our
                        team, we'd love to meet them! And to show our appreciation, we are offering up to £500 for each
                        successful referral. </p>

                    <h4>Here's How it Works:</h4>
                    <ul></ul>
                    <li>Refer a Registered Nurse or Specialist Nurse (A&E, ITU, Medical, Surgical, Paediatric, Mental
                        health) to Health Professionals.</li>
                    <li>Once your referral registers with us and works 250 hours, you'll receive a £250 bonus.</li>
                    <li>If your referral works 500 hours with us, you'll receive an additional £250, totalling a £500
                        bonus.</li>


                    <h4>Claiming Your Bonus:</h4>
                    <p>Once your referral has completed 250 hours, please contact our recruitment team. Make sure to do
                        this within three months of your referred friend's registration completion.</p>

                </div>
                <div class="column is-4 has-padding-top-none">
                    <img src="../assets/png/nurses-put-first.webp" />
                </div>


            </div>
        </div>
        <div class="has-padding-top-lg has-padding-bottom-lg">
            <div class="container">
                <div class="" *ngIf="formSubmitted && formFailure">
                    <h2 class="has-text-centered">We were unable to process your form submission
                    </h2>
                </div>
                <div class="" [hidden]="formSubmitted">
                    <h2 class="has-text-centered">Get your referral bonus!</h2>
                    <div class="white__wrapper has-margin-top-lg">
                        <form [formGroup]="formGroup" (submit)="formSubmission()" class="has-margin-top-md">
                            <div class="field is-horizontal has-padding-bottom-md">
                                <div class="field-label is-normal is-flex is-align-items-center">
                                    <label class="label">Your Details</label>
                                </div>
                                <div class="field-body">
                                    <div class="field">
                                        <input formControlName="refereeName" class="input" type="text"
                                            placeholder="Your Full Name"
                                            [ngClass]="f.refereeName.errors && (f.refereeName.dirty || f.refereeName.touched || formSubmitted) ? 'is-danger' : ''">
                                        <p *ngIf="f.refereeName.errors?.required && (f.refereeName.dirty || f.refereeName.touched || formSubmitted)"
                                            class="help is-danger">
                                            Your Full Name
                                        </p>
                                    </div>
                                    <div class="field">
                                        <input formControlName="refereeEmail" class="input" type="email"
                                            placeholder="Your Email"
                                            [ngClass]="(f.refereeEmail.invalid || f.refereeEmail.errors) && (f.refereeEmail.dirty || f.refereeEmail.touched || formSubmitted) ? 'is-danger' : ''">
                                        <p *ngIf="f.refereeEmail.errors && !f.refereeEmail.errors?.required && (f.refereeEmail.dirty || f.refereeEmail.touched) "
                                            class="help is-danger">
                                            This email is invalid
                                        </p>
                                        <p *ngIf="f.refereeEmail.errors?.required && (f.refereeEmail.dirty || f.refereeEmail.touched || formSubmitted)"
                                            class="help is-danger">
                                            Please enter your email address
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="field is-horizontal has-padding-bottom-md">
                                <div class="field-label is-normal is-flex is-align-items-center">
                                    <label class="label">Friend Details</label>
                                </div>
                                <div class="field-body">
                                    <div class="field">
                                        <input formControlName="friendName" class="input" type="text"
                                            placeholder="Friend Full Name"
                                            [ngClass]="f.friendName.errors && (f.friendName.dirty || f.friendName.touched || formSubmitted) ? 'is-danger' : ''">
                                        <p *ngIf="f.friendName.errors?.required && (f.friendName.dirty || f.friendName.touched || formSubmitted)"
                                            class="help is-danger">
                                            Please enter your friends name
                                        </p>
                                    </div>
                                    <div class="field">
                                        <input formControlName="friendEmail" class="input" type="email"
                                            placeholder="Friend Email"
                                            [ngClass]="(f.friendEmail.invalid || f.friendEmail.errors) && (f.friendEmail.dirty || f.friendEmail.touched || formSubmitted) ? 'is-danger' : ''">
                                        <p *ngIf="f.friendEmail.errors && !f.friendEmail.errors?.required && (f.friendEmail.dirty || f.friendEmail.touched) "
                                            class="help is-danger">
                                            This email is invalid
                                        </p>
                                        <p *ngIf="f.friendEmail.errors?.required && (f.friendEmail.dirty || f.friendEmail.touched || formSubmitted)"
                                            class="help is-danger">
                                            Please enter your friends email address
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="field is-horizontal has-padding-bottom-md">
                                <div class="field-label is-normal is-flex is-align-items-start">
                                    <label class="label">Friend Phone</label>
                                </div>
                                <div class="field-body">
                                    <div class="field">
                                        <input formControlName="friendPhone" class="input" type="phone"
                                            [ngClass]="f.friendPhone.errors && (f.friendPhone.dirty || f.friendPhone.touched || formSubmitted) ? 'is-danger' : ''">
                                        <p *ngIf="f.friendPhone.errors?.minlength && (f.friendPhone.dirty || f.friendPhone.touched || formSubmitted)"
                                            class="help is-danger">
                                            This number is not long enough
                                        </p>
                                        <p *ngIf="f.friendPhone.errors?.maxlength && (f.friendPhone.dirty || f.friendPhone.touched)"
                                            class="help is-danger">
                                            This number is too long
                                        </p>
                                        <p *ngIf="f.friendPhone.errors?.required && (f.friendPhone.dirty || f.friendPhone.touched || formSubmitted)"
                                            class="help is-danger">
                                            Please enter your friends phone number
                                        </p>
                                        <p *ngIf="(!f.friendPhone.errors?.maxlength && !f.friendPhone.errors?.minlength && !f.friendPhone.errors?.required) && f.friendPhone.errors && (f.friendPhone.dirty || f.friendPhone.touched)"
                                            class="help is-danger">
                                            This phone number is invalid
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="field is-horizontal has-padding-bottom-md is-justify-content-space-between">
                                <div
                                    class="has-padding-bottom-md is-normal is-flex is-justify-content-center is-align-items-center">
                                    <label class="label">Does your friend have at least 6 months NHS
                                        experience?</label>
                                </div>
                                <div class="field">
                                    <div class="control is-flex">
                                        <div class="containered custom-radio">
                                            <label class="radio">
                                                <input class="has-margin-right-sm" type="radio" value="Yes"
                                                    formControlName="nhsExperience6Months"
                                                    [ngClass]="formSubmitted && f.nhsExperience6Months.errors ? 'is-invalid' : ''">
                                                <a class="my-button-one">Yes</a>
                                            </label>
                                            <label class="radio">
                                                <input class="has-margin-right-sm" type="radio" value="No"
                                                    formControlName="nhsExperience6Months"
                                                    [ngClass]="formSubmitted && f.nhsExperience6Months.errors ? 'is-invalid' : ''">
                                                <a class="my-button">No</a>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="field is-horizontal has-padding-bottom-md is-justify-content-space-between">
                                <div
                                    class="has-padding-bottom-md is-normal is-flex is-justify-content-center is-align-items-center">
                                    <label class="label">Does your friend have the right to work in the
                                        UK?</label>
                                </div>
                                <div class="field">
                                    <div class="control is-flex">
                                        <div class="containered custom-radio">

                                            <label class="radio">
                                                <input class="has-margin-right-sm" type="radio" value="Yes"
                                                    formControlName="rightToWork"
                                                    [ngClass]="formSubmitted && f.rightToWork.errors ? 'is-invalid' : ''">
                                                <a class="my-button-one">Yes</a>
                                            </label>
                                            <label class="radio">
                                                <input class="has-margin-right-sm" type="radio" value="No"
                                                    formControlName="rightToWork"
                                                    [ngClass]="formSubmitted && f.rightToWork.errors ? 'is-invalid' : ''">
                                                <a class="my-button">No</a>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="field is-horizontal has-padding-bottom-md is-justify-content-space-between">
                                <div class="is-normal is-flex is-justify-content-center is-align-items-center">
                                    <label class="label">What is your friends' speciality?</label>
                                </div>
                                <div class="field is-horizontal">
                                    <input type="text" class="input" name="specialistArea"
                                        placeholder="Specialist Area" formControlName="specialistArea"
                                        [ngClass]="formSubmitted && f.specialistArea.errors ? 'is-invalid' : ''">
                                </div>
                            </div>

                            <button type="submit" class="dark__blue--btn has-margin-top-lg centered"
                                [disabled]="formGroup.invalid">Submit Form</button>


                        </form>

                    </div>

                </div>
                <div class="container is-flex is-justify-content-center is-flex-direction-column"
                    *ngIf="formSubmitted">
                    <h2 class="has-text-centered m-auto">Thank You for submitting the form</h2>

                </div>

            </div>
        </div>
        <div class="container" *ngFor="let item of items">
            <article class="" (click)="item.show = !item.show">
                <div class="message-header">
                    <p>{{ item.question }}</p>
                    <i [ngClass]="{'bi-plus': !item.show, 'bi-dash': item.show, 'rotate': item.show}"
                        class="bi"></i>
                </div>
                <div [@openClose]="item.show ? 'open' : 'closed'" class="message-body">
                    {{ item.answer }}
                </div>
            </article>
        </div>
    </section>
</div>