<div class="curved">
    <section class="section light__green--bg has-padding-top-none has-padding-bottom-none">
        <div class="container">
            <h2 class="has-text-centered">Why Choose Us?</h2>
            <div class="columns has-margin-top-lg tiles">
                <div class="column is-3">
                    <div class="tile__card has-text-centered">
                        <h4>MyMed Points</h4>
                        <figure class="is-rounded image">
                            <img src="../assets/svg/why-choose-1.svg" alt="" class="is-egg" />
                        </figure>
                        <p>MyMedApp points boost your pay. The more you work, the more you gain.</p>
                    </div>
                </div>
                <div class="column is-3">
                    <div class="tile__card has-text-centered">
                        <h4>Training</h4>
                        <figure class="is-rounded image">
                            <img src="../assets/svg/why-choose-3.svg" alt="" class="is-egg" />
                        </figure>
                        <p>We contribute to your training courses, helping you gain new skills.</p>
                    </div>
                </div>
                <div class="column is-3">
                    <div class="tile__card has-text-centered">
                        <h4>Weekly Pay</h4>
                        <figure class="is-rounded image">
                            <img src="../assets/svg/why-choose-2.svg" alt="" class="is-egg" />
                        </figure>
                        <p>Run by a dedicated professional team.</p>
                    </div>
                </div>
                <div class="column is-3">
                    <div class="tile__card has-text-centered">
                        <h4>Always available</h4>
                        <figure class="is-rounded image">
                            <img src="../assets/svg/why-choose-4.svg" alt="" class="is-egg" />
                        </figure>
                        <p>24/7 365 days a year.</p>
                    </div>
                </div>
            </div>

            <a routerLink="/about" class="lime__green--btn mt-6 mb-5 centered">Find out more</a>
        </div>
    </section>
</div>