<section class="has-padding-top-lg ">
    <div class="container">
        <div class="has-padding-bottom-lg">
            <h1>The Doctors Onboarding Process</h1>
        </div>
        <div class="has-text-centered has-padding-bottom-xl">
            <button class="button is-primary button-container" (click)="expandAll()" [disabled]="!isCollapsed">Open</button>
            <button class="button is-warning" (click)="collapseAll()" [disabled]="isCollapsed">Close</button>
        </div>
        <ul class="timeline">

            <li class="timeline">
                <div class="icon signup"></div>
                <details class="panel" #detailElements>
                    <summary>Sign up</summary>
                    <p>Sign up to MyMedApp from our website. 
                    </p>
                </details>
            </li>

            <li class="timeline">
                <div class="icon nurseapp"></div>
                <details class="panel" #detailElements>
                    <summary>Access to MedApp</summary>
                    <p>It takes less than a minute!</p>
                </details>
            </li>

            <li class="timeline">
                <div class="icon docs"></div>
                <details class="panel" #detailElements>
                    <summary>Documents</summary>
                    <p>As directed by the team, complete the registration forms found in the menu.</p>
                </details>
            </li>

            <li class="timeline">
                <div class="icon review"></div>
                <details class="panel" #detailElements>
                    <summary>Interview</summary>
                    <p>After the application, you will undergo a short interview to assess your suitability for working with us.</p>
                </details>
            </li>

            <li class="timeline">
                <div class="icon hospital"></div>
                <details class="panel" #detailElements>
                    <summary>Complete necessary compliance</summary>
                    <p>The last step! This won't take long.</p>
                </details>
            </li>

            <li class="timeline">
                <div class="icon start"></div>
                <details class="panel" #detailElements>
                    <summary>Ready to Work</summary>
                    <p>Our on-call bookings team and automated bookings will ensure a steady stream of shifts according to your availability. </p>
                </details>
            </li>

        </ul>
    </div>
</section>