<section class="section has-padding-top-sm">
    <div class="container">
        <div class="columns is-multiline is-vcentered">
            <div class="column is-6">
                <img src="../assets/png/app-at-glance.webp" alt="" class="is-egg" />

            </div>
            <div class="column is-6">
                <h2 class="has-margin-bottom-lg">Call to action</h2>
                <p>
                    In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.
                </p>
                <a routerLink="/" class="lime__green--btn has-margin-top-lg">Join us</a>
            </div>
        </div>
    </div>
</section>