<div class="has-padding-top-xl is-hidden-mobile">
</div>
<div class="has-padding-top-lg is-hidden-desktop is-hidden-tablet">
</div>

<section class="banner__slider__one">
    <div class="container">
        <div class="slider__grid__one">
            <div class="item__half--one">
                <div class="ft-container">
                    <div class="ft-image">
                        <a href="https://www.ft.com/content/ff77ecd7-0dea-4624-b780-fd302df3cefd" target="_blank">
                            <img src="../assets/svg/ft-photo.svg" alt="banner Image" />
                        </a>
                    </div>

                    <h6 class="is-hidden-desktop is-hidden-tablet has-margin-bottom-md" style="color: #1d3c5e;">The only
                        healthcare recruitment agency awarded <b>Gold🥇</b> in the
                        <b>Financial Times</b> and
                        <b>Statista</b> ranking of the <b>UK's leading recruiters 2024. </b><a
                            routerLink="/award-winning-approach"><b>Find out more</b></a>
                    </h6>
                </div>
                <h3 class="is-hidden-mobile has-padding-top-lg">Health Professionals</h3>
                <h1 class="has-margin-bottom-xl has-margin-top-lg  has-text-centered-mobile">
                    Consistent and reliable healthcare recruitment.
                </h1>
                <ul class="button-links is-hidden-mobile">
                    <li class="has-padding-top-lg"><a routerLink="/agency-nurses"
                            class="dark__blue--btn has-padding-top-lg">Agency Nurses</a></li>
                    <li class="has-padding-top-lg"><a routerLink="/doctors"
                            class="lime__green--btn has-padding-top-lg">Locum Doctors</a></li>
                    <li class="has-padding-top-lg"><a routerLink="/allied-health"
                            class="dark__blue--btn has-padding-top-lg light-blue">AHP/HSS</a></li>
                </ul>
            </div>
            <div class="item__half--two">
                <h6 class="is-hidden-mobile has-margin-bottom-xl custom-margin" style="color: #1d3c5e; line-height: 1.5;">The only healthcare recruitment agency awarded <b>Gold🥇</b> in the <b>Financial Times</b> and
                    <b>Statista</b> ranking of the <b>UK's leading recruiters 2024. </b><a
                        routerLink="/award-winning-approach"><b>Find out more</b></a>
                </h6>
                <img class="has-margin-left-md" src="../assets/png/slider-hearts.webp" alt="Slider Image" />

            </div>
            <div class="centered has-padding-top-md stretched-button">
                <a routerLink="/agency-nurses" class="dark__blue--btn has-margin-right-sm">Agency Nurses</a>
                <a routerLink="/doctors" class="lime__green--btn has-margin-right-sm">Locum Doctors</a>
                <a routerLink="/allied-health"
                    class="dark__blue--btn has-margin-right-sm light-blue">AHP/HSS</a>

            </div>
        </div>
    </div>
</section>

<div class="has-padding-bottom-xl is-hidden-mobile">
</div>
<!-- <section class="section light__green--bg has-padding-top-lg has-padding-bottom-lg">
    <div class="container">
        <nav class="level">
            <div class="level-left">
                <div class="level-item" style="display: flex; align-items: center;">
                    <img src="../assets/png/drop-counter.png" alt="Description" style="margin-right: 1rem;">
                    <h4 class="has-text-centered-mobile">
                        IV Training 24 Oct 2023 Fully Booked
                    </h4>
                </div>
            </div>
            <div class="level-right">
                <a routerLink="/join-us" class="lime__green--btn has-text-centered-mobile is-block-mobile">Register for next one</a>
            </div>
        </nav>
    </div>
</section> -->

<app-why-choose-us></app-why-choose-us>
<app-cta-banner></app-cta-banner>
<!-- <section class="section has-padding-top-sm has-padding-bottom-xl">
    <div class="container">
        <img src="../assets/svg/mynurse-logo.svg" alt="My Nurse App Logo" style="width:275px;" class="centered" />
        <div class="columns is-multiline is-vcentered">
            <div class="column is-6">
                <img src="../assets/png/app-at-glance.webp" alt="App at a glance" />
            </div>
            <div class="column is-6">
                <h4>Great control, certainty, compliance & income, all at the touch of a button</h4>
                <h5>Features of MyNurseApp At-a-glance</h5>
                <ul class="line__bullet--list">
                    <li>Live Shifts</li>
                    <li>Booking</li>
                    <li>Compliance</li>
                    <li>Calendar Management</li>
                </ul>
                <a routerLink="/mynurseapp" class="dark__blue--btn has-margin-top-lg">Learn more</a>

            </div>
        </div>
    </div>
</section> -->
<section class="has-padding-bottom-md">
    <div class="curved">
        <div class="section light__green--bg has-padding-top-none has-padding-bottom-none ">
            <h2 class="has-text-centered has-padding-bottom-lg">Testimonials</h2>
            <app-testimonials-slider></app-testimonials-slider>
            <div class="section container">
                <a routerLink="/testimonials" class="lime__green--btn centered" style="margin-top: 0;">Find out
                    more...</a>
            </div>
        </div>
    </div>
</section>
<!-- <app-hear-from-others></app-hear-from-others> -->

<!-- <app-cta-banner></app-cta-banner> -->