import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-live-shift-item',
  templateUrl: './live-shift-item.component.html',
  styleUrls: ['./live-shift-item.component.css']
})
export class LiveShiftItemComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
