import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-doctor-why-choose-us',
  templateUrl: './doctor-why-choose-us.component.html',
  styleUrls: ['./doctor-why-choose-us.component.scss']
})
export class DoctorWhyChooseUsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
