<div class="curved">
    <section class="section light__green--bg has-padding-top-lg has-padding-bottom-none">
        <div class="container">
            <h2 class="has-text-centered">Ambassadors</h2>
            <!-- <hr class="short__hr" /> -->
            <h4 class="has-text-centered has-margin-top-md">Get an inside view on what it's like to be a HP Nurse with our new Ambassadors program</h4>
            <a routerLink="/contact" class="lime__green--btn mt-6 mb-5 centered">Learn more about our ambassadors</a>

            <!-- <h6></h6> -->
        </div>
    </section>
</div>
<br><br>


<section class="has-padding-top-lg ">
    <div class="container">
        <div class="has-padding-bottom-lg has-text-centered">
            <h3>Our ambassadors can give you an inside view of this process and beyond!</h3>
        </div>
        <div class="has-text-centered has-padding-bottom-xl">
            <button class="button is-primary button-container" (click)="expandAll()" [disabled]="!isCollapsed">Open</button>
            <button class="button is-warning" (click)="collapseAll()" [disabled]="isCollapsed">Close</button>
        </div>
        <ul class="timeline">

            <li class="timeline">
                <div class="icon signup"></div>
                <details class="panel" #detailElements>
                    <summary>Sign up</summary>
                    <p>Sign up to Nurseapp from our website. 
                    </p>
                    <a routerLink="/contact" class="lime__green--btn mt-6 mb-5 centered">Sign up instantly</a>

                </details>
            </li>

            <li class="timeline">
                <div class="icon nurseapp"></div>
                <details class="panel" #detailElements>
                    <summary>Access to Nurseapp</summary>
                    <p>As directed by the team, complete the registration forms found in the menu.</p>
                    <img src="../assets/png/registering-image.png" style="border-radius: 110px;"/>

                </details>
            </li>

            <li class="timeline">
                <div class="icon docs"></div>
                <details class="panel" #detailElements>
                    <summary>Digital Application</summary>
                    <p>The application is completely paperless!</p>
                </details>
            </li>

            <li class="timeline">
                <div class="icon review"></div>
                <details class="panel" #detailElements>
                    <summary>Interview</summary>
                    <p>After the application, you will undergo a short interview to assess your suitability for working with us.</p>
                </details>
            </li>

            <!-- <li class="timeline">
                <div class="icon hospital"></div>
                <details class="panel" #detailElements>
                    <summary>The last stretch.</summary>
                    <p>This won't take long. Just some necessary compliance to get you working in the trusts you want.</p>
                </details>
            </li> -->

            <li class="timeline">
                <div class="icon start"></div>
                <details class="panel" #detailElements>
                    <summary>Ready to Work</summary>
                    <p>Our on-call bookings team and automated bookings will ensure a steady stream of shifts according to your availability. </p>
                    <img class="has-padding-bottom-lg" src="../assets/gifs/nurseapp-explainer.gif" alt="nurseapp-explainer" style="border-radius: 10px;   margin: auto;
                    display: block;"/>

                </details>
            </li>

        </ul>
    </div>
</section>