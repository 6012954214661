import { Injectable, Renderer2, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class SeoService {
  
  addedNames: string[];
  addedProperties: string[];

  constructor(private renderer: Renderer2,
    private meta: Meta,
    private title: Title,
    @Inject(DOCUMENT) private doc: Document) {

    this.addedNames = [];
    this.addedProperties = [];

  }

  addTags(meta: any): void | null {
    if (!meta) {
      return null;
    }

    
    this.removeTags();

    for (const key in meta) {
      switch (key) {
        case 'title':
          this.title.setTitle(meta.title);
          this.addTag('name', 'twitter:title', meta[key]);
          this.addTag('property', 'og:title', meta[key]);
          break;
        case 'keywords':
          this.addTag('name', 'keywords', meta[key]);
          break;
        case 'description':
          this.addTag('name', 'description', meta[key]);
          this.addTag('name', 'twitter:description', meta[key]);
          this.addTag('property', 'og:description', meta[key]);
          break;
        case 'canonical_url':
          this.addTag('name', 'twitter:url', meta[key]);
          this.addTag('property', 'og:url', meta[key]);
          this.updateCanonicalUrl(meta[key]);
          break;
        case 'robots':
          this.addTag('name', 'robots', meta[key]);
          break;
        case 'schema':
          this.updateSchema(meta[key]);
          break;
        case 'image':
          this.addTag('name', 'twitter:image', meta[key]);
          this.addTag('property', 'og:image', meta[key]);
          break;
        default:
      }
    }
  }

  addTag(type: string, tag: string, content: string): void {
    switch (type) {
      case 'name':
        this.meta.updateTag({ name: tag, content: content });
        this.addedNames.push(`name='${tag}'`);
        break;
      case 'property':
        this.meta.updateTag({ property: tag, content: content });
        this.addedProperties.push(`property='${tag}'`);
        break;
    }
  }

  removeTags(): void {
    for (let i = 0; i < this.addedNames.length; i += 1) {
      this.meta.removeTag(this.addedNames[i]);
    }

    for (let i = 0; i < this.addedProperties.length; i += 1) {
      this.meta.removeTag(this.addedProperties[i]);
    }
  }

  replaceTitle(replaceThis: string, replaceWith: string) {

    if (this.addedNames.indexOf(`name="twitter:title"`) > -1) {
      this.meta.removeTag(`name="twitter:title"`);
    }

    if (this.addedProperties.indexOf(`property="og:title"`) > -1) {
      this.meta.removeTag(`property="og:title"`);
    }

    const title = this.title.getTitle().replace(replaceThis, replaceWith);
    this.title.setTitle(title);
    this.addTag('name', 'twitter:title', title);
    this.addTag('property', 'og:title', title);
  }

  updateCanonicalUrl(url: string) {
    const head = this.doc.getElementsByTagName('head')[0];
    var element: HTMLLinkElement|null = this.doc.querySelector(`link[rel='canonical']`) || null
    
    if (element == null) {
      element= this.doc.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }

    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url);
  }

  updateSchema(data: any) {
    const head = this.doc.getElementsByTagName('head');
    const schemaElements = this.doc.getElementsByClassName('schema');
    if (schemaElements) {
      for (let i = 0; i < schemaElements.length; i += 1) {
        this.renderer.removeChild(head[0], schemaElements[i]);
      }
    }
    const schema = this.renderer.createElement('script');
    this.renderer.addClass(schema, 'schema');
    this.renderer.setAttribute(schema, 'type', 'application/ld+json');
    schema.innerHTML = JSON.stringify(data, null, 2);
    this.renderer.appendChild(head[0], schema);
  }

}
