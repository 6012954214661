<div class="curved">
  <section class="section light__green--bg has-padding-top-none has-padding-bottom-none">
    <div class="container">
      <h2 class="has-text-centered">Health Professionals' Nurse of the Month Campaign</h2>
      <!-- <hr class="short__hr" />
          <h4 class="has-text-centered has-margin-top-md">The UK's Leading Specialist Recruitment Agency for Travel
              Nurses, Full-Time Positions, and Occupational Health Nurses</h4>
          <h6></h6> -->
    </div>
  </section>
</div>
<br><br>
<div class="">
  <section class="section light__green--bg has-padding-top-none has-padding-bottom-xl">
    <div class="container">
      <div class="columns is-vcentered is-multiline">
        <div class="column is-6 has-padding-top-none">
          <br><br>

          <p>At Health Professionals, we believe in celebrating the exceptional commitment, skill, and compassion of our
            nursing staff. We understand the invaluable role our nurses play, and the significant impact they have on
            the lives of their patients. To honour these remarkable individuals, we have established our Nurse of the
            Month Campaign.</p>

          <p>Each month, we recognise a nurse whose exceptional dedication and compassionate care has made a significant
            difference in patient care. Our selection is not arbitrary but based on substantial positive feedback from
            patients, their families, and peers.</p>

          <p>Curious about our past winners? You can read all about their stories, achievements, and the positive impact
            they've had on their patients' lives in our <a href="https://www.healthprofessionals.co.uk/blog/">blog</a>
            posts.</p>

          <p>In May, we are thrilled to celebrate <b>Hugo L.</b>, a nurse who embodies our commitment to outstanding
            care. His extraordinary dedication to his patients has not only earned him our prestigious Nurse of the
            Month title but also garnered incredible feedback from his patients and their families in the NHS Paediatric
            ward.</p>

          <p>From ensuring the parents were involved in their child's care, to lending an empathetic ear in times of
            distress, and even making sure the parents were taking care of themselves - Hugo has shown an exemplary
            level of care. His positive attitude and willingness to go above and beyond for his patients and their
            families have made him a vital asset to our team and an inspiration to us all.</p>

        </div>
        <div class="column is-6 has-padding-top-none">
          <img src="../assets/png/slide-hearts.png" />

        </div>
      </div>
    </div>
  </section>
</div>

<section class="section has-padding-bottom-xxl">
  <div class="container">
    <div class="columns is-vcentered">
      <div class="column is-6">
        <img src="../assets/nurses/nurse-of-month-picture1.webp" style="border-radius: 10px;" />
      </div>
      <div class="column is-6">


        <ul>Here's a glimpse of some of the exceptional feedback we've received for Hugo: </ul>

        <br>
        <li>"[Hugo] was great with encouraging me to help where I could with the care of my daughter… His positive
          attitude was really needed and appreciated...”</li>

        <li>"Hugo especially went above and beyond to care for our daughter and even sat with us when we were told
          terrifying news by the neurologist...”</li>

        <li>"Hugo goes beyond the job role every moment he's been here… He made sure we had been looking after ourselves
          too… This man should be aware of what an exceptional person he is."</li>

        <li>"Hugo was very good, taking authority on things when required and just a very positive guy."</li>

        <li>"Hugo was my angel… I couldn't fault his dedication and incredible knowledge."</li>
        <br>
        <p>We are incredibly proud to have Hugo on our team. His outstanding commitment to providing the highest
          standard of care truly embodies the values of Health Professionals.</p>

        <p>Hugo, thank you for being such an amazing asset to our team. Your compassion, dedication, and positive
          attitude are truly inspiring. We are very fortunate to have you with us.</p>

        <p>Stay tuned to our Nurse of the Month Campaign as we continue to highlight the extraordinary efforts of our
          nursing team, and join us in celebrating their unwavering dedication to improving lives every day.</p>

        <div class="level-left">
          <a routerLink="/join-us" class="lime__green--btn has-text-centered-mobile is-block-mobile">Sign-up</a>
        </div>

      </div>
    </div>
  </div>
</section>