<div class="bubble">
    <div class="blob__overlay">
        <h4 class="has-text-centered">How can we help?</h4>
        <div class="accordion v1">
            <a routerLink="/doctors-join-us" class="lime__green--btn" style="margin-bottom: 3%; width: 50%;">Sign up</a>
            <a routerLink="/doctors-contact-us" class="dark__blue--btn" style="width: 50%;">Contact Us</a>
        </div>
    </div>
</div>
<div class="bubble__qr is-hidden-mobile">
    <div class="blob__overlay__qr">
        <h4 class="has-text-centered" style="color: white;">Whatsapp <br>Our Team!</h4>
        <div class="accordion__qr v1">
            <img style="border-radius: 10px;" src="../assets/png/qr-code.png" alt="footer Image">

        </div>
    </div>
</div>
<div class="darkblue__curved--top">
    <footer class="darkblue__bg ">
        <div class="container" style="z-index:1;">
            <div class="columns">
                <div class="column is-7">
                    <div class="columns is-multiline is-mobile is-gapless">
                        <div class="column is-4-desktop is-6-mobile">
                            <ul class="footer__menu">
                                <li><a routerLink="/doctors-about">About</a></li>
                                <!-- <li><a routerLink="/faq">FAQs</a></li> -->
                                <!-- <li><a routerLink="/covid-19-update">Covid-19</a></li> -->
                                <li><a href="https://www.healthprofessionals.co.uk/blog/category/locum-doctors">Blog</a></li>
                                <!-- <li><a routerLink="/points-system">Our Points System</a></li> -->
                                <li><a routerLink="/doctors-for-hospitals-trusts">For our Hospitals and Trusts</a></li>
                                <!-- <li><a routerLink="/nurse-of-month">Nurse of the Month</a></li>
                                <li><a routerLink="/health-job-finder">Job Finder</a></li>
                                <li><a routerLink="/careers">Careers</a></li>
                                <li><a routerLink="/Q&A">Q&A</a></li> -->
                                <li><a routerLink="/contact-us">Contact Us</a></li>
                                <li><a routerLink="/doctors-join-us">Sign Up</a></li>
                                <li><a routerLink="/doctors-job-listing">Jobs</a></li>
                                <!-- <li><a routerLink="/refer-a-friend">Refer a Friend</a></li> -->
                                <!-- <li><a routerLink="/live-shifts">Live Shifts</a></li> -->
                                <!-- <li><a routerLink="/testimonials">Testimonials</a></li> -->
                                <!-- <li><a routerLink="/doctors-mymedapp">MyNurseApp</a></li> -->
                            </ul>
                        </div>
                        <div class="column is-4-desktop is-6-mobile">
                            <ul class="footer__menu">
                                <li><a routerLink="/agency-nurse-join-us">For Nurses</a></li>
                                <li><a routerLink="/doctors-join-us">For Doctors</a></li>
                                <li><a routerLink="/allied-health-join-us">For AHP/HSS</a></li>
                                <li><a routerLink="/refer-a-friend">Refer a Friend</a></li>
                                <!--<li><a routerLink="/pay">Pay</a></li>-->
                                <!--<li><a routerLink="/history">History</a></li>-->
                                <li class="list__heading">Other Information</li>
                                <li><a routerLink="/privacy">Privacy Statement</a></li>
                                <li><a routerLink="/terms">Terms of Use</a></li>
                                <li><a routerLink="/modern-slavery">Modern Slavery Act Policy</a></li>
                            </ul>
                        </div>
                        <div class="column is-4-desktop is-12-mobile has-text-centered-mobile">
                            <ul class="footer__address">
                                <li>London Office</li>
                                <li>45 Circus Road</li>
                                <li>St John's Wood</li>
                                <li>London</li>
                                <li>NW8 9JH</li>
                            </ul>
                            <ul class="footer__address">
                                <li class="space">Health Professionals</li>
                                <li class="space">2 Kensington</li>
                                <li class="space">Bishop Auckland</li>
                                <li>County Durham</li>
                                <li>DL14 6HX</li>
                            </ul>
                            <ul class="footer__address">
                                <li>Health Professionals</li>
                                <li>9-11 New Road</li>
                                <li>Bromsgrove</li>
                                <li>Worcestershire</li>
                                <li>B60 2JF</li>
                            </ul>
                        </div>
                        <div class="column is-12 has-text-centered-mobile">
                            <a routerLink="/award-winning-approach">
                                <img class="has-padding-top-md" style="max-width: 50%; height: auto;"
                                    src="../assets/svg/ft-photo.svg" alt="footer Image" />
                            </a>
                            <img class="has-padding-top-md has-padding-left-sm" style="max-width: 50%; height: auto;"
                                src="../assets/png/rec-member.webp" alt="footer Image" />
                            <hr class="is-lime">
                            <p>Copyright 1997 - {{currentYear}} by Health Professionals - Healthcare Jobs London</p>
                            <a href="https://www.harvington.tech" class="footer-link">
                                <div class="footer-text-logo-wrapper">
                                    <p>Powered by <br>Harvington Tech</p>
                                    <img style="max-width: 15%; height: auto; margin-left: 10px" src="../assets/png/harv-tech-logo.webp"
                                        alt="footer Image" />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </footer>
</div>