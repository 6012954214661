<!-- <section class="section has-padding-top-none has-padding-bottom-sm">
  <div class="container">
    <div class="columns is-gapless">
      <div class="column is-10">
        <div class="field">
          <br><br>
          <label class="label">Search below to find your job:</label>
          <div class="control">
            <input class="input" type="text" placeholder="Search Town or City">
          </div>
        </div>
      </div>
      <div class="column is-flex is-align-items-flex-end">
        <div class="field" style="width:100%;">
          <div class="control">
            <button class="submit__btn" type="submit">Find Jobs</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->





<section class="has-padding-top-sm">
  <div class="curved">
    <section class="section has-padding-top-none light__green--bg">
      <div *ngIf="loading" class="has-text-centered spinner-container">
        <div class="spinner"></div> <!-- This is a generic class for spinner, style it as you wish in CSS -->
      </div>
      <div *ngIf="!loading">

        <!-- <div class="has-text-centered has-padding-bottom-xl">
          <button class="button is-primary button-container" routerLink="/doctors-job-listing">All Jobs</button>
          <button class="button is-info" routerLink="/doctors-job-listing-last-minute">Live Jobs</button>
        </div> -->

        <div class="container">
          <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li>
                <a href="/doctors">
                  <span class="icon is-small">
                    <i class="fas fa-home" aria-hidden="true"></i>
                  </span>
                  <span>Home</span>
                </a>
              </li>
              <li>
                <a>
                  <span class="icon is-small">
                    <i class="fas fa-book" aria-hidden="true"></i>
                  </span>
                  <span>Job List</span>
                </a>
              </li>
            </ul>
          </nav>
          <h2 class="has-text-centered has-padding-bottom-sm">Locums Jobs</h2><br>
          <nav class="pagination is-centered" role="navigation" aria-label="pagination">
            <a class="pagination-previous" (click)="goToPage(currentPage - 1)"
              [class.is-disabled]="currentPage === 1">Previous</a>
            <a class="pagination-next" (click)="goToPage(currentPage + 1)"
              [class.is-disabled]="currentPage === totalPages || hpsVacancies.length == 0">Next page</a>
            <ul class="pagination-list">
              <li *ngFor="let page of pages">
                <a class="pagination-link" [class.is-current]="currentPage === page" (click)="goToPage(page)"
                  title="Goto page {{page}}">{{page}}</a>
              </li>
            </ul>
          </nav>

          <div *ngFor="let vacancy of hpsVacancies" class="job__listing--item has-padding-top-lg">
            <h3 class="has-padding-bottom-lg">{{vacancy.cr5b8_title}}</h3>
            <ul>
              <li class="flex-container">
                <div class="icon-container"><i class="fas fa-heart heart-icon"></i></div>
                <span class="item-text">{{vacancy.hps_SpecialistAreaid.hps_name}}</span>
              </li>
              <li class="flex-container">
                <div class="icon-container"><i class="fas fa-map-marker-alt location-icon"></i></div>
                <span class="item-text">{{vacancy.cr5b8_location}}</span>
              </li>
              <li class="flex-container">
                <div class="icon-container"><i class="fas fa-clock clock-icon"></i></div>
                <span class="item-text">Immediate Start</span>
              </li>
              <li class="flex-container">
                <div class="icon-container"><i class="fas fa-money-bill money-icon"></i></div>
                <span class="item-text">{{ vacancy.cr5b8_payelements }}</span>
              </li>
              <li class="flex-container">
                <div class="icon-container"><i class="fas fa-info-circle info-icon"></i></div>
                <span class="item-text">{{ truncateDescription(vacancy.cr5b8_shortjobdescription, 200) }}</span>
              </li>
            </ul>

            <br>

            <div class="has-text-left">
              <span>Created on: {{vacancy.createdon | date:'medium'}}</span>
            </div>
            <div class="has-text-right-desktop has-text-centered-mobile has-padding-top-sm">
              <a href="doctors-job-view/{{vacancy.hps_vacancyid}}" class="dark__blue--btn">View Job</a>
            </div>
          </div>

          <br>
          <nav class="pagination is-centered" role="navigation" aria-label="pagination">
            <a class="pagination-previous" (click)="goToPage(currentPage - 1)"
              [class.is-disabled]="currentPage === 1">Previous</a>
            <a class="pagination-next" (click)="goToPage(currentPage + 1)"
              [class.is-disabled]="currentPage === totalPages || hpsVacancies.length == 0">Next page</a>
            <ul class="pagination-list">
              <li *ngFor="let page of pages">
                <a class="pagination-link" [class.is-current]="currentPage === page" (click)="goToPage(page)"
                  title="Goto page {{page}}">{{page}}</a>
              </li>
            </ul>
          </nav>


        </div>
      </div>
    </section>
  </div>
</section>