import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CarouselModule } from "ngx-owl-carousel-o";
import { RecaptchaModule } from "ng-recaptcha";
import { BreadcrumbModule } from "xng-breadcrumb";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NavMenuComponent } from "./nav-menus/nav-menu-main/nav-menu.component";
import { FooterComponent } from "./partials/footer/footer.component";
import { HomeComponent } from "./pages-main/home/home.component";
import { AboutComponent } from "./pages-main/about/about.component";
import { PrivacyComponent } from "./pages-main/policies/privacy/privacy.component";
import { TermsComponent } from "./pages-main/policies/terms/terms.component";
import { FaqComponent } from "./pages-main/faq/faq.component";
import { JobListingComponent } from "./pages-main/job-listing/job-listing.component";
import { JobItemComponent } from "./pages-main/job-item/job-item.component";
import { LiveShiftsComponent } from "./pages-main/live-shifts/live-shifts.component";
import { LiveShiftItemComponent } from "./pages-main/live-shift-item/live-shift-item.component";
import { NurseOfTheMonthComponent } from "./pages-main/nurse-of-the-month/nurse-of-the-month.component";
import { JoinUsOldComponent } from "./pages-main/join-us-old/join-us-old.component";
import { ContactUsComponent } from "./pages-main/contact-us/contact-us.component";
import { JoinUsComponent } from "./pages-main/join-us/join-us.component";
import { TestimonialsComponent } from "./components-main/testimonials/testimonials.component";
import { PointsComponent } from "./pages-main/points/points.component";
import { MynurseAppComponent } from "./pages-main/mynurseapp/mynurseapp.component";
import { ForHospitalsTrustsComponent } from "./pages-main/for-hospitals-trusts/for-hospitals-trusts.component";
import { Covid19UpdateComponent } from "./pages-main/covid19-update/covid19-update.component";
import { PayComponent } from "./pages-main/pay/pay.component";
import { HistoryComponent } from "./pages-main/history/history.component";
import { ContactFormComponent } from "./components-main/contact-form/contact-form.component";
import { CtaBannerComponent } from "./components-main/cta-banner/cta-banner.component";
import { CtaBlockComponent } from "./components-main/cta-block/cta-block.component";
import { HearFromOthersComponent } from "./components-main/hear-from-others/hear-from-others.component";
import { ImageandtextgridComponent } from "./components-main/imageandtextgrid/imageandtextgrid.component";
import { JobItemsComponent } from "./components-main/job-items/job-items.component";
import { JobListingsComponent } from "./components-main/job-listings/job-listings.component";
import { JobViewComponent } from "./pages-main/job-view/job-view.component";
import { JobViewsComponent } from "./components-main/job-views/job-views.component";
import { LiveShiftsGridComponent } from "./components-main/live-shifts-grid/live-shifts-grid.component";
import { MeetTheTeamComponent } from "./components-main/meet-the-team/meet-the-team.component";
import { ShiftItemComponent } from "./components-main/shift-item/shift-item.component";
import { TabsComponent } from "./components-main/tabs/tabs.component";
import { WhyChooseUsComponent } from "./components-main/why-choose-us/why-choose-us.component";
import { NgxHotjarModule } from "ngx-hotjar";
import { environment } from "../environments/environment";
import { ModernSlaveryComponent } from './pages-main/policies/modern-slavery/modern-slavery.component';
import { HealthJobFinderComponent } from './pages-main/health-job-finder/health-job-finder.component';
import { CareersComponent } from './pages-main/careers/careers.component';
import { QAComponent } from './pages-main/qa/qa.component';
import { ReferAFriendComponent } from './pages-main/refer-a-friend/refer-a-friend.component';
import { TestimonialsSliderComponent } from './components-main/testimonials-slider/testimonials-slider.component';
import { JobRegionComponent } from './pages-main/job-region/job-region.component';
import { SkillsInformationComponent } from './pages-main/skills-information/skills-information.component';
import { NurseJourneyComponent } from './pages-main/nurse-journey/nurse-journey.component';
import { JobListingLastMinuteComponent } from './pages-main/job-listing-last-minute/job-listing-last-minute.component';
import { JobListingsLastMinuteComponent } from './components-main/job-listings-last-minute/job-listings-last-minute.component';
import { NavMenuAgencyComponent } from "./nav-menus/nav-menu-agency/nav-menu-agency.component";

//Doctor
import { DoctorHomeComponent } from './pages-doctor/doctor-home/doctor-home.component';
import { DoctorAboutComponent } from "./pages-doctor/doctor-about/doctor-about.component";
import { NavMenuDoctorComponent } from "./nav-menus/nav-menu-doctor/nav-menu-doctor.component";
import { HearFromOthersDoctorComponent } from "./components-doctor/hear-from-others-doctor/hear-from-others-doctor.component";
import { DoctorMymedappComponent } from "./pages-doctor/doctor-mymedapp/doctor-mymedapp.component";
import { DoctorJourneyComponent } from './pages-doctor/doctor-journey/doctor-journey.component';
import { DoctorJoinUsComponent } from './pages-doctor/doctor-join-us/doctor-join-us.component';
import { DoctorsJobListingComponent } from './pages-doctor/doctors-job-listing/doctors-job-listing.component';
import { DoctorJobListingLastMinuteComponent } from './pages-doctor/doctor-job-listing-last-minute/doctor-job-listing-last-minute.component';
import { DoctorsJobListingsComponent } from './components-doctor/doctors-job-listings/doctors-job-listings.component';
import { DoctorJobListingsLastMinuteComponent } from './components-doctor/doctor-job-listings-last-minute/doctor-job-listings-last-minute.component';
import { DoctorFooterComponent } from './partials/doctor-footer/doctor-footer.component';
import { DoctorContactUsComponent } from './pages-doctor/doctor-contact-us/doctor-contact-us.component';
import { DoctorWhyChooseUsComponent } from './components-doctor/doctor-why-choose-us/doctor-why-choose-us.component';
import { OpenDayComponent } from './pages-main/open-day/open-day.component';
import { CarbonReductionPlanComponent } from './pages-main/carbon-reduction-plan/carbon-reduction-plan.component';
import { HospitalsTrustsOnboardingComponent } from './pages-main/hospitals-trusts-onboarding/hospitals-trusts-onboarding.component';
import { TrainingEventComponent } from './pages-main/training-event/training-event.component';
import { CaseStudiesComponent } from './pages-main/for-hospitals-trusts/case-studies/case-studies.component';
import { FrimleyHealthComponent } from "./pages-main/for-hospitals-trusts/case-studies/frimley-health/frimley-health.component";
import { ClientPortalComponent } from './pages-main/mynurseapp/client-portal/client-portal.component';
import { OxfordUniversityHospitalComponent } from './pages-main/for-hospitals-trusts/case-studies/oxford-university-hospital/oxford-university-hospital.component';
import { BuckinghamshireHealthComponent } from './pages-main/for-hospitals-trusts/case-studies/buckinghamshire-health/buckinghamshire-health.component';
import { RoyalBerkshireComponent } from './pages-main/for-hospitals-trusts/case-studies/royal-berkshire/royal-berkshire.component';
import { AshfordStpetersComponent } from './pages-main/for-hospitals-trusts/case-studies/ashford-stpeters/ashford-stpeters.component';
import { FtAwardComponent } from './pages-main/ft-award/ft-award.component';
import { AgencyHomeComponent } from './pages-main/agency-home/agency-home.component';
import { AgencyFooterComponent } from './partials/agency-footer/agency-footer.component';
import { AlliedHealthJobListingsComponent } from './components-allied-health/allied-health-job-listings/allied-health-job-listings.component';
import { AlliedHealthJobListingComponent } from './pages-allied-health/allied-health-job-listing/allied-health-job-listing.component';
import { AlliedHealthJoinUsComponent } from './pages-allied-health/allied-health-join-us/allied-health-join-us.component';
import { AlliedHealthFooterComponent } from "./partials/allied-health-footer/allied-health-footer.component";
import { NavMenuAlliedHealthComponent } from "./nav-menus/nav-menu-allied-health/nav-menu-allied-health.component";
import { AlliedHealthOrganisationsComponent } from './pages-allied-health/allied-health-organisations/allied-health-organisations.component';
import { TechnologyComponent } from './pages-main/technology/technology.component';
import { AlliedHealthHomeComponent } from './pages-allied-health/allied-health-home/allied-health-home.component';
import { DoctorsForHospitalsAndTrustsComponent } from './pages-doctor/doctors-for-hospitals-and-trusts/doctors-for-hospitals-and-trusts.component';
import { GeneralJoinUsComponent } from './pages-main/general-join-us/general-join-us.component';
import { AlliedHealthJobViewsComponent } from './components-allied-health/allied-health-job-views/allied-health-job-views.component';
import { DoctorsJobViewsComponent } from './components-doctor/doctors-job-views/doctors-job-views.component';
import { AgencyNurseJoinUsComponent } from './pages-agency-nurse/agency-nurse-join-us/agency-nurse-join-us.component';
import { AgencyNurseJobListingComponent } from './pages-agency-nurse/agency-nurse-job-listing/agency-nurse-job-listing.component';
import { AgencyNurseJobListingsComponent } from './components-agency-nurse/agency-nurse-job-listings/agency-nurse-job-listings.component';
import { AgencyNurseAssignmentsComponent } from './components-agency-nurse/agency-nurse-assignments/agency-nurse-assignments.component';
import { AgencyNurseAssignmentComponent } from './pages-agency-nurse/agency-nurse-assignment/agency-nurse-assignment.component';
import { AgencyNurseJobViewsComponent } from './components-agency-nurse/agency-nurse-job-views/agency-nurse-job-views.component';
import { AgencyNurseJobViewComponent } from './pages-agency-nurse/agency-nurse-job-view/agency-nurse-job-view.component';
import { MindCareComponent } from './pages-main/mind-care/mind-care.component';
import { ComplexCareComponent } from './pages-main/complex-care/complex-care.component';
import { ServicesComponent } from './pages-main/services/services.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    PrivacyComponent,
    TermsComponent,
    FaqComponent,
    JobListingComponent,
    JobItemComponent,
    LiveShiftsComponent,
    LiveShiftItemComponent,
    NurseOfTheMonthComponent,
    JoinUsComponent,
    ContactUsComponent,
    JoinUsComponent,
    JoinUsOldComponent,
    TestimonialsComponent,
    PointsComponent,
    MynurseAppComponent,
    ForHospitalsTrustsComponent,
    Covid19UpdateComponent,
    PayComponent,
    HistoryComponent,
    ContactFormComponent,
    CtaBannerComponent,
    CtaBlockComponent,
    HearFromOthersComponent,
    ImageandtextgridComponent,
    JobItemsComponent,
    JobListingsComponent,
    JobViewComponent,
    JobViewsComponent,
    LiveShiftsGridComponent,
    MeetTheTeamComponent,
    ShiftItemComponent,
    TabsComponent,
    WhyChooseUsComponent,
    ModernSlaveryComponent,
    HealthJobFinderComponent,
    CareersComponent,
    QAComponent,
    TestimonialsSliderComponent,
    ReferAFriendComponent,
    JobRegionComponent,
    SkillsInformationComponent,
    NurseJourneyComponent,
    JobListingLastMinuteComponent,
    JobListingsLastMinuteComponent,


    //Doctor
    DoctorAboutComponent,
    DoctorHomeComponent,
    NavMenuDoctorComponent,
    HearFromOthersDoctorComponent,
    DoctorMymedappComponent,
    DoctorJourneyComponent,
    DoctorJoinUsComponent,
    DoctorsJobListingComponent,
    DoctorJobListingLastMinuteComponent,
    DoctorsJobListingsComponent,
    DoctorJobListingsLastMinuteComponent,
    DoctorFooterComponent,
    DoctorContactUsComponent,
    DoctorWhyChooseUsComponent,
    OpenDayComponent,
    CarbonReductionPlanComponent,
    HospitalsTrustsOnboardingComponent,
    TrainingEventComponent,
    FrimleyHealthComponent,
    CaseStudiesComponent,
    ClientPortalComponent,
    OxfordUniversityHospitalComponent,
    BuckinghamshireHealthComponent,
    RoyalBerkshireComponent,
    AshfordStpetersComponent,
    FtAwardComponent,
    AgencyHomeComponent,
    NavMenuAgencyComponent,
    AgencyFooterComponent,
    AlliedHealthJobListingsComponent,
    AlliedHealthJobListingComponent,
    AlliedHealthJoinUsComponent,
    AlliedHealthFooterComponent,
    NavMenuAlliedHealthComponent,
    AlliedHealthOrganisationsComponent,
    TechnologyComponent,
    AlliedHealthHomeComponent,
    DoctorsForHospitalsAndTrustsComponent,
    GeneralJoinUsComponent,
    AlliedHealthJobViewsComponent,
    DoctorsJobViewsComponent,
    AgencyNurseJoinUsComponent,
    AgencyNurseJobListingComponent,
    AgencyNurseJobListingsComponent,
    AgencyNurseAssignmentsComponent,
    AgencyNurseAssignmentComponent,
    AgencyNurseJobViewsComponent,
    AgencyNurseJobViewComponent,
    MindCareComponent,
    ComplexCareComponent,
    ServicesComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CarouselModule,
    RecaptchaModule,
    BreadcrumbModule,
    AppRoutingModule,
    NgxHotjarModule.forRoot(environment.hotjarId),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
