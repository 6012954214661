import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-doctors-job-listing',
  templateUrl: './doctors-job-listing.component.html',
  styleUrls: ['./doctors-job-listing.component.scss']
})
export class DoctorsJobListingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
