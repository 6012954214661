import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-imageandtextgrid',
  templateUrl: './imageandtextgrid.component.html',
  styleUrls: ['./imageandtextgrid.component.css']
})
export class ImageandtextgridComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
