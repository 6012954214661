<section class="section has-padding-bottom-xl">
    <div class="container">
        <!--<h2 class="has-text-centered">Hear from our nurses</h2>
    <div class="columns has-margin-top-md is-multiline tiles">
      <div class="column is-4">
        <div class="nurse__card has-text-centered">
          <h4 class="has-margin-bottom-none">Joe Bloggs</h4>
          <h4 class="color__lime">Cardiac Nurse</h4>
          <figure class="image">
            <img src="../assets/svg/team_placeholder_female.png" alt="" class="is-egg" />
          </figure>
          <div class="nurse__info">
            <p>Some text here.Some text. ome text here.Some text here.Some text here.Some text here.Some text here</p>
            <a routerLink="/" class="dark__blue--btn has-margin-top-md watch__video--btn">Watch Video</a>
          </div>
        </div>
      </div>
      <div class="column is-4">
        <div class="nurse__card has-text-centered">
          <h4 class="has-margin-bottom-none">Joe Bloggs</h4>
          <h4 class="color__lime">Cardiac Nurse</h4>
          <figure class="image">
            <img src="../assets/svg/team_placeholder_female.png" alt="" class="is-egg" />
          </figure>
          <div class="nurse__info">
            <p>Some text here.Some text. ome text here.Some text here.Some text here.Some text here.Some text here</p>
            <a routerLink="/" class="dark__blue--btn has-margin-top-md watch__video--btn">Watch Video</a>
          </div>
        </div>
      </div>
      <div class="column is-4">
        <div class="nurse__card has-text-centered">
          <h4 class="has-margin-bottom-none">Joe Bloggs</h4>
          <h4 class="color__lime">Cardiac Nurse</h4>
          <figure class="image">
            <img src="../assets/svg/team_placeholder_female.png" alt="" class="is-egg" />
          </figure>
          <div class="nurse__info">
            <p>Some text here.Some text. ome text here.Some text here.Some text here.Some text here.Some text here</p>
            <a routerLink="/" class="dark__blue--btn has-margin-top-md watch__video--btn">Watch Video</a>
          </div>
        </div>
      </div>
    </div>-->
        <a routerLink="/testimonials" class="lime__green--btn centered">Testimonials</a>
    </div>
</section>