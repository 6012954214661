import { Component } from '@angular/core';

@Component({
  selector: 'app-skills-information',
  templateUrl: './skills-information.component.html',
  styleUrls: ['./skills-information.component.scss']
})
export class SkillsInformationComponent {
  wards = [
    { name: 'Accidents & Emergencies', type: 'A&E', url: '/accidents-emergencies', externalUrl: 'https://www.healthprofessionals.co.uk/blog/post/hp-jobs-in-ae'  },
    { name: 'Resuscitation Room', type: 'A&E', url: '/resuscitation-room', externalUrl: 'https://www.healthprofessionals.co.uk/blog/post/hp-jobs-in-ae' },
    { name: 'Minor Injuries Unit', type: 'A&E', url: '/minor-injuries', externalUrl: 'https://www.healthprofessionals.co.uk/blog/post/hp-jobs-in-ae' },
    { name: 'Major Injuries Unit', type: 'A&E', url: '/major-injuries', externalUrl: 'https://www.healthprofessionals.co.uk/blog/post/hp-jobs-in-ae' },
    { name: 'Observation Unit', type: 'A&E', url: '/observation', externalUrl: 'https://www.healthprofessionals.co.uk/blog/post/hp-jobs-in-ae' },
    { name: 'Respiratory Wards', type: 'Medical Wards', url: '/respiratory-wards', externalUrl: 'https://www.healthprofessionals.co.uk/blog/post/medical' },
    { name: 'Cardiology Wards', type: 'Medical Wards', url: '/cardiology-wards', externalUrl: 'https://www.healthprofessionals.co.uk/blog/post/medical' },
    { name: 'Renal Wards', type: 'Medical Wards', url: '/renal-wards', externalUrl: 'https://www.healthprofessionals.co.uk/blog/post/medical' },
    { name: 'Gastroenterology Wards', type: 'Medical Wards', url: '/gastroenterology-wards', externalUrl: 'https://www.healthprofessionals.co.uk/blog/post/medical' },
    { name: 'Midwifery', type: 'Midwifery', url: '/midwifery' },
    { name: 'Endocrinology Wards', type: 'Medical Wards', url: '/endocrinology-wards', externalUrl: 'https://www.healthprofessionals.co.uk/blog/post/medical' },
    { name: 'Infectious Diseases Wards', type: 'Medical Wards', url: '/infectious-diseases-wards' },
    { name: 'Orthopedic Wards', type: 'Surgical Wards', url: '/orthopedic-wards', externalUrl: 'https://www.healthprofessionals.co.uk/blog/post/surgical' },
    { name: 'Cardiothoracic Wards', type: 'Surgical Wards', url: '/cardiothoracic-wards', externalUrl: 'https://www.healthprofessionals.co.uk/blog/post/surgical' },
    { name: 'Neurosurgery Wards', type: 'Surgical Wards', url: '/neurosurgery-wards', externalUrl: 'https://www.healthprofessionals.co.uk/blog/post/surgical' },
    { name: 'Plastic Surgery Wards', type: 'Surgical Wards', url: '/plastic-surgery-wards', externalUrl: 'https://www.healthprofessionals.co.uk/blog/post/surgical' },
    { name: 'General Surgery Wards', type: 'Surgical Wards', url: '/general-surgery-wards', externalUrl: 'https://www.healthprofessionals.co.uk/blog/post/surgical' },
    { name: 'Gynecology Wards', type: 'Obstetric & Gynecology Wards', url: '/gynecology-wards', externalUrl: 'https://www.healthprofessionals.co.uk/blog/post/maternity' },
    { name: 'Maternity Wards', type: 'Obstetric & Gynecology Wards', url: '/maternity-wards', externalUrl: 'https://www.healthprofessionals.co.uk/blog/post/maternity' },
    { name: 'Antenatal Wards', type: 'Obstetric & Gynecology Wards', url: '/antenatal-wards', externalUrl: 'https://www.healthprofessionals.co.uk/blog/post/maternity' },
    { name: 'Postnatal Wards', type: 'Obstetric & Gynecology Wards', url: '/postnatal-wards', externalUrl: 'https://www.healthprofessionals.co.uk/blog/post/maternity' },
    { name: 'Neonatal Intensive Care Unit', type: 'Pediatric Wards', url: '/neonatal-icu', externalUrl: 'https://www.healthprofessionals.co.uk/blog/post/paediatric' },
    { name: 'Pediatric Intensive Care Unit', type: 'Pediatric Wards', url: '/pediatric-icu', externalUrl: 'https://www.healthprofessionals.co.uk/blog/post/paediatric' },
    { name: 'Child and Adolescent Mental Health Services', type: 'Pediatric Wards', url: '/child-mental-health', externalUrl: 'https://www.healthprofessionals.co.uk/blog/post/paediatric' },
    { name: 'Psychiatric Wards', type: 'Psychiatric Wards', url: '/psychiatric-wards', externalUrl: 'https://www.healthprofessionals.co.uk/join-us' },
    { name: 'Mental Health Crisis Wards', type: 'Psychiatric Wards', url: '/mental-health-crisis-wards', externalUrl: 'https://www.healthprofessionals.co.uk/join-us' },
    { name: 'Addiction Services Wards', type: 'Psychiatric Wards', url: '/addiction-services-wards', externalUrl: 'https://www.healthprofessionals.co.uk/join-us' },
    { name: 'Geriatric Wards', type: 'Elderly Care Wards', url: '/geriatric-wards', externalUrl: 'https://www.healthprofessionals.co.uk/join-us' },
    { name: 'Dementia Wards', type: 'Elderly Care Wards', url: '/dementia-wards', externalUrl: 'https://www.healthprofessionals.co.uk/join-us' },
    { name: 'Stroke Rehabilitation Wards', type: 'Elderly Care Wards', url: '/stroke-rehabilitation-wards', externalUrl: 'https://www.healthprofessionals.co.uk/join-us' },
    { name: 'Cardiac Intensive Care Unit', type: 'Intensive Care Units', url: '/cardiac-icu', externalUrl: 'https://www.healthprofessionals.co.uk/join-us' },
    { name: 'Neurosurgical Intensive Care Unit', type: 'Intensive Care Units', url: '/neurosurgical-icu' },
    { name: 'Trauma Intensive Care Unit', type: 'Intensive Care Units', url: '/trauma-icu' },
    { name: 'Burn Unit', type: 'Specialized Wards', url: '/burn-unit', externalUrl: 'https://www.healthprofessionals.co.uk/join-us' },
    { name: 'Oncology Ward', type: 'Specialized Wards', url: '/oncology-ward', externalUrl: 'https://www.healthprofessionals.co.uk/join-us' },
    { name: 'Hematology Ward', type: 'Specialized Wards', url: '/hematology-ward' },
    { name: 'Rheumatology Ward', type: 'Specialized Wards', url: '/rheumatology-ward', externalUrl: 'https://www.healthprofessionals.co.uk/join-us' },
    { name: 'Palliative Care Ward', type: 'Specialized Wards', url: '/palliative-care-ward', externalUrl: 'https://www.healthprofessionals.co.uk/join-us' },
    { name: 'Rehabilitation Ward', type: 'Rehabilitation Wards', url: '/rehabilitation-ward', externalUrl: 'https://www.healthprofessionals.co.uk/join-us' },
    { name: 'Physical Therapy Ward', type: 'Rehabilitation Wards', url: '/physical-therapy-ward', externalUrl: 'https://www.healthprofessionals.co.uk/join-us' },
    { name: 'Occupational Therapy Ward', type: 'Rehabilitation Wards', url: '/occupational-therapy-ward', externalUrl: 'https://www.healthprofessionals.co.uk/join-us' },
    { name: 'Speech and Language Therapy Ward', type: 'Rehabilitation Wards', url: '/speech-language-therapy-ward', externalUrl: 'https://www.healthprofessionals.co.uk/join-us' },
    { name: 'Outpatient Department', type: 'Outpatient Wards', url: '/outpatient-department', externalUrl: 'https://www.healthprofessionals.co.uk/join-us' },
    { name: 'Day Surgery Unit', type: 'Outpatient Wards', url: '/day-surgery-unit', externalUrl: 'https://www.healthprofessionals.co.uk/join-us' },
    { name: 'Diagnostic Imaging Department', type: 'Outpatient Wards', url: '/diagnostic-imaging', externalUrl: 'https://www.healthprofessionals.co.uk/join-us' },
    { name: 'Chemotherapy Day Unit', type: 'Outpatient Wards', url: '/chemotherapy-day-unit', externalUrl: 'https://www.healthprofessionals.co.uk/join-us' },
    { name: 'Dialysis Unit', type: 'Outpatient Wards', url: '/dialysis-unit', externalUrl: 'https://www.healthprofessionals.co.uk/join-us' }
  ];

  filter = '';
  type = 'All';
  

  filteredWards() {
    return this.wards
      .filter(ward => 
        this.type === 'All' || 
        this.type === ward.type)
      .filter(ward => ward.name.toLowerCase().includes(this.filter.toLowerCase()));
  }

  resetFilters() {
    this.filter = '';
    this.type = 'All';
  }

  setActiveType(type: string) {
    this.type = type;
  }
}
