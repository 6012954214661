import { Component, OnInit } from '@angular/core';
import { CrmService } from 'src/app/services/crm.service';

@Component({
  selector: 'app-job-listing',
  templateUrl: './job-listing.component.html',
  styleUrls: ['./job-listing.component.css']
})
export class JobListingComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
