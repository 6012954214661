<div class="curved">
    <section class="section light__green--bg has-padding-top-none has-padding-bottom-none">
        <div class="container">
            <h2 class="has-text-centered">Discover Exciting Healthcare Opportunities with Health Professionals </h2>
            <hr class="short__hr" />
            <h4 class="has-text-centered has-margin-top-md">The UK's Leading Specialist Recruitment Agency for Travel
                Nurses, Full-Time Positions, and Occupational Health Nurses</h4>
            <h6></h6>
        </div>
    </section>
</div>
<br><br>
<div class="">
    <section class="section light__green--bg has-padding-top-none has-padding-bottom-xl">
        <div class="container">
            <div class="columns is-vcentered is-multiline">
                <div class="column is-6 has-padding-top-none">
                    <br><br>
                    <h2>Opportunity</h2>


                    <p>Welcome to Health Professionals, the premier specialist recruitment company in the UK dedicated
                        to connecting passionate and talented healthcare professionals with exciting opportunities
                        across the nation. Whether you're seeking travel nurse assignments, full-time positions with
                        hospitals and clinics, or new assignments as an occupational health nurse, we're here to help
                        you explore the possibilities and find the perfect fit for your career goals. </p>

                    <h4>Travel Nurse Assignments: </h4>
                    <p>At Health Professionals, we understand the unique needs and desires of travel nurses. Our team of
                        experienced recruiters works closely with clients across the UK to source and secure the best
                        travel nurse opportunities in a variety of clinical settings. By partnering with us, you'll have
                        access to a wide range of assignments that cater to your preferences, skills, and aspirations.
                    </p>

                    <h4>Full-Time Positions:</h4>
                    <p>Looking for a new full-time position in the healthcare sector? We collaborate with reputable
                        hospitals, clinics, and healthcare providers across the UK to find rewarding and fulfilling
                        roles for our candidates. Our dedicated team of recruitment specialists will work tirelessly to
                        match your skills, experience, and goals with the perfect full-time opportunity.</p>

                    <h4>Occupational Health Nurses:</h4>
                    <p>For occupational health nurses seeking new assignments, Health Professionals offers a specialist
                        service tailored to your unique needs. We recognise the importance of occupational health within
                        the healthcare industry and strive to provide you with the most relevant and interesting
                        positions available.</p>

                    <h4>International Candidates:</h4>
                    <p>We extend our services to healthcare professionals overseas who are interested in pursuing
                        opportunities in the UK. To be eligible, you must have already obtained your PIN number from the
                        Nursing and Midwifery Council (NMC). Our team is well-versed in guiding international candidates
                        through the process and helping them secure rewarding positions within the UK healthcare system.
                    </p>

                </div>
                <div class="column is-6 has-padding-top-none">
                    <img src="../assets/png/ipad-app.webp" />

                </div>
            </div>
        </div>
    </section>
</div>

<section class="section has-padding-bottom-xxl">
    <div class="container">
        <div class="columns is-vcentered">
            <div class="column is-6">
                <img src="../assets/png/nurse-stock-2.webp" />
            </div>
            <div class="column is-6">

                <h4>Why Choose Health Professionals?</h4>
                <p>With Health Professionals, you'll benefit from our extensive network of healthcare clients,
                    personalised service, and commitment to your career growth. We pride ourselves on understanding the
                    unique needs and aspirations of healthcare professionals and go above and beyond to ensure a
                    seamless recruitment experience. </p>

                <h4>Take the Next Step:</h4>
                <p>Ready to explore new and exciting opportunities in the UK healthcare sector? We invite you to get in
                    touch with our team of dedicated recruiters to discuss your career goals and aspirations.
                    Alternatively, click the link below to submit your CV, and we will evaluate your qualifications to
                    determine how we can best help you. </p>

                <p>At Health Professionals, your success is our success. Let us help you unlock your potential and find
                    the perfect opportunity for your healthcare career in the UK.</p>
                <div class="level-left">
                    <a routerLink="/join-us"
                        class="lime__green--btn has-text-centered-mobile is-block-mobile">Sign-up</a>
                </div>

            </div>
        </div>
    </div>
</section>