<section class="has-padding-top-sm">
    <div class="curved">
        <section class="section light__green--bg has-padding-top-none has-padding-bottom-none">
            <div class="container">
                <h2 class="has-text-centered">Our Commitment to Excellence at Health Professionals</h2>
            </div>
        </section>
    </div>
</section>
<div class="">
    <section class="section light__green--bg has-padding-top-none has-padding-bottom-xl">
        <div class="container">
            <h2 class="has-text-centered has-padding-top-lg">Gold Standard</h2>
            <p class="">We at Health Professionals are honoured to be distinguished as the exclusive Gold Award winner
                in the Health & Social Care division of the Financial Times 2024 rankings. This prestigious accolade is
                a testament to our dedication to delivering a tailored service experience, upholding the highest
                standards of ethical recruitment, and embracing cutting-edge technology to enhance our offerings, as
                exemplified by our proprietary MyNurseApp.</p>

            <p>Our unique approach has not only set us apart but has also placed us at the pinnacle of healthcare
                recruitment. It reflects our commitment to quality and innovation in a competitive field of over 7,000
                UK recruiters, with Health Professionals emerging at the forefront in the esteemed "Final Cut" of 24
                companies.</p>
            <h4 class="has-padding-bottom-sm">Ranking</h4>
            <p>We invite you to delve into the comprehensive report and explore the methodology behind the rankings
                here: <a href="https://www.ft.com/content/ff77ecd7-0dea-4624-b780-fd302df3cefd">Financial Times 2024
                    Health & Social Care Rankings.</a></p>
            <img class="ft-table" src="../assets/png/ft-table.webp" />
            <p>Peer-Endorsed Excellence: Achieving the Gold Award is a reflection of our commitment to excellence, as
                acknowledged by clients, candidates, and peers.</p>
            <p>Personalised Recruitment Journeys: We tailor our services to individual needs, ensuring a bespoke fit for
                both healthcare professionals and organisations.</p>
            <p>Quality-Driven Matches: Our focus on the quality of placements leads to higher satisfaction and better
                outcomes for all parties involved.</p>
            <p>Unwavering Ethical Standards: Integrity is at the heart of our operations, ensuring transparency and
                fairness in every interaction.</p>
            <p>Innovation and Improvement: We continually evolve our services, staying ahead of industry trends and
                leveraging feedback for constant betterment.</p>
            <p>Supportive Community: Our network provides unwavering support, from professional development
                opportunities to welfare support, ensuring a holistic approach to healthcare staffing.</p>
            <!-- <img class="float-right" src="../assets/png/ipad-app.webp" /> -->
            <h4 class="has-padding-bottom-sm">The MyNurseApp Advantage</h4>
            <img class="float-left" src="../assets/png/phone-bookings.webp" />
            <p>Our innovative <a routerLink="/mynurseapp">MyNurseApp</a> isn’t just a tool; it’s a revolution in
                healthcare staffing. It offers healthcare professionals the flexibility to manage their work schedule
                with just a few taps, and it gives healthcare facilities access to a vast pool of verified talent ready
                to fill in gaps at a moment's notice. The app facilitates:</p>
            <p>Instant Job Notifications: Get real-time alerts for job opportunities that match your skills and
                preferences.</p>
            <p>Easy Shift Management: View and apply for shifts, update availability, and track your work schedule in
                one convenient place.</p>
            <!-- <img class="float-left" src="../assets/png/app-at-glance.webp" /> -->
            <p>Simplified Administration: Say goodbye to paperwork. The app handles all timesheets and compliance
                details, making the process hassle-free.</p>
            <p>Direct Communication: Instantly connect with the Health Professionals team for support and assistance.
            </p>
            <br>
            <h4 class="has-padding-bottom-sm has-text-centered">Our Approach</h4>
            <img class="float-right is-hidden-mobile" src="../assets/png/slide-hearts.webp" />
            <p>Our approach combines the human touch with technological innovation. We listen to and understand the
                needs of both healthcare providers and professionals, ensuring a perfect alignment of aspirations and
                requirements. With our MyNurseApp, we empower our staff with control over their work-life balance while
                providing our clients with reliable, quality service.</p>
            <p>To explore how Health Professionals and MyNurseApp can transform your career or healthcare staffing
                strategy, and to gain deeper insights into our award-winning methods, please get in touch.</p>
        </div>
    </section>
</div>