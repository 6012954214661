<section class="section light__green--bg curved has-padding-top-none has-padding-bottom-none">
    <div class="container">
        <h2 class="has-text-centered">Meet our Team</h2>
        <div class="columns has-margin-top-lg is-multiline tiles">
            <div class="column is-4">
                <div class="team__card has-text-centered">
                    <h4>Hugo Lyons</h4>
                    <figure class="is-rounded image">
                        <img src="../assets/team/team_hugo.webp" alt="" class="is-rounded" />
                    </figure>
                    <p>Managing Director</p>
                </div>
            </div>
            <div class="column is-4">
                <div class="team__card has-text-centered">
                    <h4>Deborah Preston</h4>
                    <figure class="is-rounded image">
                        <img src="../assets/team/team_deborah.webp" alt="" class="is-rounded" />
                    </figure>
                    <p>Bookings Manager</p>
                </div>
            </div>

            <div class="column is-4">
                <div class="team__card has-text-centered">
                    <h4>Sara Oktemgil</h4>
                    <figure class="is-rounded image">
                        <img src="../assets/team/team_sara.webp" alt="" class="is-rounded" />
                    </figure>
                    <p>Business Manager</p>
                </div>
            </div>

            <div class="column is-4">
                <div class="team__card has-text-centered">
                    <h4>Michele Jogee</h4>
                    <figure class="is-rounded image">
                        <img src="../assets/team/team_michele.webp" alt="" class="is-rounded" />
                    </figure>
                    <p>Clinical Nurse Director</p>
                </div>
            </div>
            <div class="column is-4">
                <div class="team__card has-text-centered">
                    <h4>Jane McCall</h4>
                    <figure class="is-rounded image">
                        <img src="../assets/team/team_jane.webp" alt="" class="is-rounded" />
                    </figure>
                    <p>Accounts & Payroll Manager</p>
                </div>
            </div>
            <div class="column is-4">
                <div class="team__card has-text-centered">
                    <h4>Sam McCall</h4>
                    <figure class="is-rounded image">
                        <img src="../assets/team/team_sam.webp" alt="" class="is-rounded" />
                    </figure>
                    <p>Payroll Officer</p>
                </div>
            </div>
            <div class="column is-4">
                <div class="team__card has-text-centered">
                    <h4>Miriam Peters</h4>
                    <figure class="is-rounded image">
                        <img src="../assets/team/team_miriam.webp" alt="" class="is-rounded" />
                    </figure>
                    <p>Payroll Officer</p>
                </div>
            </div>
            <div class="column is-4">
                <div class="team__card has-text-centered">
                    <h4>Charlotte Preston</h4>
                    <figure class="is-rounded image">
                        <img src="../assets/team/team_charlotte_passport.webp" alt="" class="is-rounded" />
                    </figure>
                    <p>Bookings Coordinator</p>
                </div>
            </div>
            <!--<div class="column is-4">
    <div class="team__card has-text-centered">
      <h4>lesley payton</h4>
      <figure class="is-rounded image">
        <img src="../assets/svg/team_placeholder_female.png" alt="" class="is-rounded" />
      </figure>
      <p>nurse interviewer</p>
    </div>
  </div>-->
            <!--<div class="column is-4">
        <div class="team__card has-text-centered">
          <h4>Maureen Mirungu</h4>
          <figure class="is-rounded image">
            <img src="../assets/team/team_maureen.jpg" alt="" class="is-rounded" />
          </figure>
          <p>Bookings Coordinator</p>
        </div>
      </div>-->
            <div class="column is-4">
                <div class="team__card has-text-centered">
                    <h4>Louise Batley</h4>
                    <figure class="is-rounded image">
                        <img src="../assets/team/team_louise.webp" alt="" class="is-rounded" />
                    </figure>
                    <p>Bookings Coordinator & Recruitment Officer</p>
                </div>
            </div>
            <div class="column is-4">
                <div class="team__card has-text-centered">
                    <h4>Waseem Qureshi</h4>
                    <figure class="is-rounded image">
                        <img src="../assets/team/team_waseem.webp" alt="" class="is-rounded" />
                    </figure>
                    <p>Systems Support Officer</p>
                </div>
            </div>
            <div class="column is-4">
                <div class="team__card has-text-centered">
                    <h4>Emily Smith</h4>
                    <figure class="is-rounded image">
                        <img src="../assets/team/team_emily.jpeg" alt="" class="is-rounded" />
                    </figure>
                    <p>Bookings Coordinator</p>
                </div>
            </div>
            <!--<div class="column is-4">
    <div class="team__card has-text-centered">
      <h4>Teresa Charman</h4>
      <figure class="is-rounded image">
        <img src="../assets/svg/team_placeholder_female.png" alt="" class="is-rounded" />
      </figure>
      <p>Bookkeeper</p>
    </div>
  </div>-->
        </div>
        <!--<a routerLink="/join-us" class="dark__blue--btn centered">Register as a nurse with us</a>-->
    </div>
</section>