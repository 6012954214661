import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-job-items',
  templateUrl: './job-items.component.html',
  styleUrls: ['./job-items.component.css']
})
export class JobItemsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
