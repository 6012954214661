import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hear-from-others-doctor',
  templateUrl: './hear-from-others-doctor.component.html',
  styleUrls: ['./hear-from-others-doctor.component.css']
})
export class HearFromOthersDoctorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
