import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-allied-health-home',
  templateUrl: './allied-health-home.component.html',
  styleUrls: ['./allied-health-home.component.scss']
})
export class AlliedHealthHomeComponent {

  customOptions: any = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    autoplay: true, // This property makes the carousel change slides automatically.
    autoplayTimeout: 10000, // Autoplay interval timeout (default is 5000ms).
    autoplayHoverPause: false, // Pause on mouse hover.
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false
  }

}
