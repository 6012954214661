<section class="section">
    <div class="container">
        <div class="columns is-vcentered">
            <div class="column is-7">
                <h1 class="has-text-centered-mobile">We're not just any agency and you're not just any doctor. Join us
                    today.
                </h1>
            </div>
            <div class="column is-5">
                <img src="../assets/png/slider-hearts.webp" alt="Slider Image" />
            </div>
        </div>
    </div>
</section>

<div class="curved__top">
    <section class="section light__green--bg has-padding-top-none">
        <div class="container" *ngIf="formSubmitted && formFailure">
            <h2 class="has-text-centered">We were unable to process your form submission</h2>
        </div>
        <div class="container" [hidden]="formSubmitted && formSuccess">
            <h2 class="has-text-centered">Speed up your application...</h2>
            <div class="white__wrapper has-margin-top-lg">
                <form [formGroup]="formGroup" (submit)="formSubmission()" class="has-margin-top-md">
                    <div class="field is-horizontal has-padding-bottom-md">
                        <div class="field-label is-normal is-flex is-align-items-center">
                            <label class="label">Name</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <input formControlName="firstName" class="input" type="text" placeholder="First name"
                                    [ngClass]="f.firstName.errors && (f.firstName.dirty || f.firstName.touched || formSubmitted) ? 'is-danger' : ''">
                                <p *ngIf="f.firstName.errors?.required && (f.firstName.dirty || f.firstName.touched || formSubmitted)"
                                    class="help is-danger">
                                    Please enter your First Name
                                </p>
                            </div>
                            <div class="field">
                                <input formControlName="lastName" class="input" type="text" placeholder="Last name"
                                    [ngClass]="f.lastName.errors && (f.lastName.dirty || f.lastName.touched || formSubmitted) ? 'is-danger' : ''">
                                <p *ngIf="f.lastName.errors?.required && (f.lastName.dirty || f.lastName.touched || formSubmitted)"
                                    class="help is-danger">
                                    Please enter your Last Name
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="field is-horizontal has-padding-bottom-md">
                        <div class="field-label is-normal is-flex is-align-items-start">
                            <label class="label">Phone</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <input formControlName="phone" class="input" type="tel"
                                    [ngClass]="f.phone.errors && (f.phone.dirty || f.phone.touched || formSubmitted) ? 'is-danger' : ''">
                                <p *ngIf="f.phone.errors?.minlength && (f.phone.dirty || f.phone.touched || formSubmitted)"
                                    class="help is-danger">
                                    This number is not long enough
                                </p>
                                <p *ngIf="f.phone.errors?.maxlength && (f.phone.dirty || f.phone.touched)"
                                    class="help is-danger">
                                    This number is too long
                                </p>
                                <p *ngIf="f.phone.errors?.required && (f.phone.dirty || f.phone.touched || formSubmitted)"
                                    class="help is-danger">
                                    Please enter your phone number
                                </p>
                                <p *ngIf="(!f.phone.errors?.maxlength && !f.phone.errors?.minlength && !f.phone.errors?.required) && f.phone.errors && (f.email.dirty || f.email.touched)"
                                    class="help is-danger">
                                    This phone number is invalid
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="field is-horizontal has-padding-bottom-md">
                        <div class="field-label is-normal is-flex is-align-items-start">
                            <label class="label">Email</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <input formControlName="email" class="input" type="email"
                                    [ngClass]="(f.email.invalid || f.email.errors) && (f.email.dirty || f.email.touched || formSubmitted) ? 'is-danger' : ''">
                                <p *ngIf="f.email.errors && !f.email.errors?.required && (f.email.dirty || f.email.touched) "
                                    class="help is-danger">
                                    This email is invalid
                                </p>
                                <p *ngIf="f.email.errors?.required && (f.email.dirty || f.email.touched || formSubmitted)"
                                    class="help is-danger">
                                    Please enter your email address
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="field is-horizontal has-padding-bottom-md">
                        <div class="field-label is-normal is-flex is-align-items-start">
                            <label class="label">Date Of Birth</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <input formControlName="dob" class="input" type="date"
                                    [ngClass]="(f.dob.invalid || f.dob.errors) && (f.dob.dirty || f.dob.touched || formSubmitted) ? 'is-danger' : ''">
                                <p *ngIf="f.dob.errors && !f.dob.errors?.required && (f.dob.dirty || f.dob.touched) "
                                    class="help is-danger">
                                    This dob is invalid
                                </p>
                                <p *ngIf="f.dob.errors?.required && (f.dob.dirty || f.dob.touched || formSubmitted)"
                                    class="help is-danger">
                                    Please enter your Date Of Birth
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="field is-horizontal">
                        <div class="field-label is-normal is-flex is-align-items-start is-flex-direction-column">
                            <label class="label">GMC Pin</label>
                            <span class="tag is-primary" data-tooltip="Tooltip Text">FAST TRACK</span>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <div class="control">
                                    <input formControlName="gmcPin" class="input" type="gmcPin"
                                        [ngClass]="f.gmcPin.errors && (f.gmcPin.dirty || f.gmcPin.touched || formSubmitted) ? 'is-danger' : ''"
                                        oninput="this.value = this.value.toUpperCase()">
                                </div>
                                <!-- <p class="help">Format: X1A2345B</p> -->
                                <p *ngIf="f.gmcPin.errors && !f.gmcPin.errors?.required && (f.gmcPin.dirty || f.gmcPin.touched)"
                                    class="help is-danger">
                                    This GMC Pin is invalid</p>
                                <!-- <p *ngIf="f.nmcPin.errors?.required && (f.nmcPin.dirty || f.nmcPin.touched || formSubmitted)" class="help is-danger">
                                    Please enter yourn NMC PIN
                                </p> -->
                                <div *ngIf="f.gmcPin.errors" class="notification is-primary">
                                    <strong>Fast track</strong> your application by entering your GMC Pin here.
                                    <!-- and access the <a routerLink="/doctors-mymedapp">MedApp</a> immediately -->
                                </div>
                            </div>
                        </div>

                    </div>


                    <hr />

                    <div class="field">
                        <label class="label">Tell us briefly what you're looking for</label>
                        <div class="control">
                            <textarea rows="2" class="textarea" placeholder="Explain how we can help you"
                                name="lookingFor" placeholder="What are you looking for?" formControlName="lookingFor"
                                [ngClass]="formSubmitted && f.lookingFor.errors ? 'is-invalid' : ''"></textarea>
                        </div>
                    </div>

                    <div class="field is-horizontal has-padding-bottom-md is-justify-content-space-between">
                        <div class="is-normal is-flex has-padding-bottom-md is-align-items-center">
                            <label class="label">Do you have at least 6 months NHS experience?</label>
                        </div>
                        <div class="field">
                            <div class="control is-flex">
                                <div class="containered custom-radio">
                                    <label class="radio">
                                        <input class="has-margin-right-sm" type="radio" value="Yes"
                                            formControlName="nhsExperience6Months"
                                            [ngClass]="formSubmitted && f.nhsExperience6Months.errors ? 'is-invalid' : ''">
                                        <a class="my-button-one">Yes</a>
                                    </label>
                                    <label class="radio">
                                        <input class="has-margin-right-sm" type="radio" value="No"
                                            formControlName="nhsExperience6Months"
                                            [ngClass]="formSubmitted && f.nhsExperience6Months.errors ? 'is-invalid' : ''">
                                        <a class="my-button">No</a>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="field is-horizontal has-padding-bottom-md is-justify-content-space-between">
                        <div class="is-normal is-flex has-padding-bottom-md is-align-items-center">
                            <label class="label">Do you have the right to work in the UK?</label>
                        </div>
                        <div class="field">
                            <div class="control is-flex">
                                <div class="containered custom-radio">
                                    <label class="radio">
                                        <input class="has-margin-right-sm" type="radio" value="Yes"
                                            formControlName="rightToWork"
                                            [ngClass]="formSubmitted && f.rightToWork.errors ? 'is-invalid' : ''">
                                        <a class="my-button-one">Yes</a>
                                    </label>
                                    <label class="radio">
                                        <input class="has-margin-right-sm" type="radio" value="No"
                                            formControlName="rightToWork"
                                            [ngClass]="formSubmitted && f.rightToWork.errors ? 'is-invalid' : ''">
                                        <a class="my-button">No</a>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="field is-horizontal has-padding-bottom-md is-justify-content-space-between">
                        <div class="is-normal is-flex has-padding-bottom-md is-align-items-center">
                            <label class="label">Are you only looking for enhanced rates?</label>
                        </div>
                        <div class="field">
                            <div class="control is-flex">
                                <div class="containered custom-radio">

                                    <label class="radio">
                                        <input class="has-margin-right-sm" type="radio" value="Yes"
                                            formControlName="enhancedRates"
                                            [ngClass]="formSubmitted && f.enhancedRates.errors ? 'is-invalid' : ''">
                                        <a class="my-button-one">Yes</a>
                                    </label>
                                    <label class="radio">
                                        <input class="has-margin-right-sm" type="radio" value="No"
                                            formControlName="enhancedRates"
                                            [ngClass]="formSubmitted && f.enhancedRates.errors ? 'is-invalid' : ''">
                                        <a class="my-button">No</a>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="field is-horizontal has-padding-bottom-md is-justify-content-space-between">
                        <div class="is-normal is-flex has-padding-bottom-md is-align-items-center">
                            <label class="label">What is your Specialist Area?</label>
                        </div>
                        <div class="field is-horizontal">
                            <input type="text" class="input" name="specialistArea" placeholder="Specialist Area"
                                formControlName="specialistArea"
                                [ngClass]="formSubmitted && f.specialistArea.errors ? 'is-invalid' : ''">
                        </div>
                    </div>

                    <div class="field is-horizontal has-padding-bottom-md is-justify-content-space-between">
                        <div class="is-normal is-flex has-padding-bottom-md is-align-items-center">
                            <label class="label">How far do you travel for work?</label>
                        </div>
                        <div class="field is-horizontal">
                            <input type="text" class="input" placeholder="Enter number of miles"
                                formControlName="workTravelDistance"
                                [ngClass]="formSubmitted && f.workTravelDistance.errors ? 'is-invalid' : ''">
                        </div>
                    </div>
                    <div class="field is-horizontal has-padding-bottom-md is-justify-content-space-between">
                        <div class="is-normal is-flex has-padding-bottom-md is-align-items-center">
                            <label class="label">What is your postcode?</label>
                        </div>
                        <div class="field is-horizontal">
                            <div class="control">
                                <input type="text" class="input" name="postCode" placeholder="Enter postcode"
                                    formControlName="postCode"
                                    [ngClass]="formSubmitted && f.postCode.errors ? 'is-invalid' : ''"
                                    oninput="this.value = this.value.toUpperCase()">
                                <p *ngIf="f.postCode.errors?.minlength && (f.postCode.dirty || f.postCode.touched) "
                                    class="help is-danger">
                                    This post code is not long enough
                                </p>
                                <p *ngIf="f.postCode.errors?.maxlength && (f.postCode.dirty || f.postCode.touched) "
                                    class="help is-danger">
                                    This post code is too long
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="field is-horizontal has-padding-bottom-md is-justify-content-space-between">
                        <div class="is-normal is-flex has-padding-bottom-md is-align-items-center">
                            <label class="label">Do you consent to us calling you?</label>
                        </div>
                        <div class="field">
                            <div class="control">
                                <div class="containered custom-radio">
                                    <label class="radio">
                                        <input class="has-margin-right-sm" type="radio" value="Yes"
                                            formControlName="callingConsent"
                                            [ngClass]="formSubmitted && f.callingConsent.errors ? 'is-invalid' : ''">
                                        <a class="my-button-one">Yes</a>
                                    </label>
                                    <label class="radio">
                                        <input class="has-margin-right-sm" type="radio" value="No"
                                            formControlName="callingConsent"
                                            [ngClass]="formSubmitted && f.callingConsent.errors ? 'is-invalid' : ''">
                                        <a class="my-button">No</a>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- CAPTCHA -->
                    <!--<div class="g-recaptcha" style="transform: scale(0.77); -webkit-transform: scale(0.77); transform-origin: 0 0; -webkit-transform-origin: 0 0;" data-theme="light">
            <re-captcha (resolved)="resolved($event)" siteKey="6LfaYXcUAAAAAOBGI_d6YSUs2Fb2rVVf7ajE2MiJ"></re-captcha>
          </div>-->
                    <div class="submit-or-loading">
                        <button *ngIf="!isLoading" class="dark__blue--btn has-margin-top-lg centered" type="submit">
                            Submit Form
                        </button>

                        <div *ngIf="isLoading" class="spinner-container">
                            <div class="spinner"></div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="container is-flex is-justify-content-center is-flex-direction-column"
            *ngIf="formSubmitted && formSuccess && !isLoading">
            <h2 class="has-text-centered m-auto">Thank You for submitting our form. Our recruitment team will reach out
                to you as soon possible.</h2>

        </div>
        <div class="container is-flex is-justify-content-center is-flex-direction-column"
            *ngIf="formSubmitted && formFailure && !isLoading">
            <h2 class="has-text-centered">We were unable to process your form submission</h2>
        </div>
    </section>
</div>