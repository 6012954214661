<section class="section">
    <div class="container">
        <div class="columns is-vcentered ">
            <div class="column is-6">
                <img src="../assets/png/nurse-stock.webp" alt="Slider Image" />
            </div>
            <div class="column is-6">
                <h3 class="centered has-text-centered">Contact our team on <a href="tel:020 7201 1140">020 7201 1140</a> any time, whatever your query...
                    we'd
                    love to help</h3>
            </div>
        </div>
    </div>
</section>


<section class="section is-hidden-mobile is-hidden-tablet">
    <div class="container">
        <div class="columns is-vcentered has-margin-top-md tiles">
            <div class="column is-6">
                <h3>
                    Looking to work as a full time agency? Join an agency that will put you 1st
                </h3>
            </div>
            <div class="column is-6">
                <h3>
                    Have a permanent full-time job but are looking to pick up extra agency shifts? Our app enables this
                    in seconds
                </h3>
            </div>
        </div>
    </div>
</section>

<app-cta-banner></app-cta-banner>
