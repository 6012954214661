<section class="section">
    <div class="container">
        <h2>Health Professionals Modern Slavery Policy</h2>
        <p>This Policy Statement is made in the spirit of the Modern Slavery Act 2015. Although Health Professionals' turnover is less than £36 million and we are not a relevant commercial organisation subject to Section 54 of the Act, we voluntarily provide this statement to demonstrate our commitment to ethical practices and transparency.</p>

        <h4>1. Organisation Structure and Business</h4>
        <p>Health Professionals, established in 1997, is a specialist nursing agency providing skilled candidates across the UK. Our office bases are in London, The Midlands, and County Durham, serving both NHS and private sectors. This statement applies to all individuals engaged with or employed by us.
        </p>

        <h4>2. Policies on Slavery and Human Trafficking
        </h4>
        <p>We firmly oppose Slavery and Human Trafficking. Our policies reflect our commitment to act ethically and with integrity in all business relationships. We enforce systems and controls to prevent Slavery and Human Trafficking in our business and supply chain.</p>

        <h4>3. Due Diligence Processes
        </h4>
        <p>We conduct regular audits and risk assessments of our business and supply chain. Our due diligence process is designed to identify, assess, and mitigate risks of Slavery and Human Trafficking.</p>

        <h4>4. Risk Management in Business and Supply Chains</h4>
        <p>We acknowledge potential risks in our business and supply chains and have taken steps to manage these risks. Regular reviews and updates to our practices ensure our continuous commitment to preventing Slavery and Human Trafficking.</p>

        <h4>5. Effectiveness and Performance Indicators
        </h4>
        <p>Our effectiveness in preventing Slavery and Human Trafficking is monitored through performance indicators such as audit results, compliance rates, and supply chain reviews.</p>

        <h4>6. Training and Capacity Building</h4>
        <p>We provide comprehensive training to our staff, focusing on identifying, reporting, and preventing Slavery and Human Trafficking. This training is integral to our commitment and is conducted annually.</p>

        <h4>7. Reporting Mechanism</h4>
        <p>Concerns regarding Slavery and Human Trafficking should be reported to Hugo Lyons, Compliance & Protocol
            Director, or Sara Oktemgil, HR Director. Our reporting mechanism is designed to ensure confidentiality and
            protection for whistleblowers.</p>
        <p>This policy statement, approved on 5th December 2023, underscores our dedication to ethical business conduct
            and our proactive approach to combating modern slavery and human trafficking.
        </p>
    </div>
</section>