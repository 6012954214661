<section class="has-padding-top-xl has-padding-bottom-xl">
    <div class="container">
        <h2 class="has-text-centered">HP Mindcare</h2>
    </div>
</section>
<div class="">
    <section class="section light__green--bg has-padding-top-none has-padding-bottom-xl">
        <div class="container">
            <br><br>
            <h2>Welcome to HP Mindcare</h2>

            <p> HP Mindcare combines the expertise of HP Homecare and Health Professionals to deliver comprehensive
                mental health services tailored for organisations. We enhance workplace well-being and productivity
                through high-quality, accessible mental health care solutions. </p>

            <h4>Our Services:</h4>
            <p><strong>Employee Assistance Programmes (EAP):</strong> We provide robust mental health services through
                our EAP offerings, supporting employees to prevent burnout, improve productivity, and enhance
                well-being. Only the best professionals support your organisation.</p>

            <p><strong>NHS and Healthcare Authority Support:</strong> We alleviate pressures on NHS and healthcare
                authorities by providing timely, high-quality mental health care, reducing wait times, and ensuring
                continuous support.</p>

            <p><strong>Virtual Therapy Sessions:</strong> Our secure platform offers virtual therapy via video calls,
                phone sessions, or messaging, ensuring employees can access support anytime, anywhere.</p>

            <p><strong>Personalised Matching:</strong> We match employees with therapists fitting their unique needs
                through a simple questionnaire. Employees can switch therapists to find the best match.</p>

            <p><strong>24/7 Support:</strong> Our services are available round-the-clock, providing immediate help or
                scheduled sessions as needed.</p>

            <h4>Why Choose HP Mindcare?</h4>
            <p><strong>Convenience:</strong> Employees receive care from home or office, eliminating the need for
                commuting.</p>
            <p><strong>Affordability:</strong> Competitive pricing plans with options for insurance coverage and
                flexible payment plans.</p>
            <p><strong>Confidentiality:</strong> Strict data protection regulations ensure privacy and security.</p>
            <p><strong>Quality Assurance:</strong> Experienced therapists specialising in various fields, supported by
                state-of-the-art technology.</p>

            <h4>Request Information:</h4>
            <p>Contact us to discuss how HP Mindcare can assist your organisation.</p>

            <h4>Testimonials:</h4>
            <p>"Partnering with HP Mindcare has significantly improved our employees' well-being. The accessible and
                high-quality mental health services have led to noticeable improvements in productivity and overall
                morale." - HR Manager, Global Tech Company</p>
            <p>"Using HP Mindcare's EAP services has made a positive impact on our workforce. The ability to access
                mental health support anytime has been incredibly valuable for our team." - Director of Human Resources,
                Homecare Organisation</p>

            <p>For more information, contact our support team. We are here to help your organisation thrive by
                prioritising mental health.</p>
        </div>
    </section>
</div>