import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-live-shifts',
  templateUrl: './live-shifts.component.html',
  styleUrls: ['./live-shifts.component.css']
})
export class LiveShiftsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
