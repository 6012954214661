import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.css']
})
export class TestimonialsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  
  truncateDescription(description: string, limit: number): string {
    if (description.length <= limit) {
      return description;
    }
  
    const lastSpaceIndex = description.lastIndexOf(' ', limit);
    const truncatedText = description.slice(0, lastSpaceIndex);
  
    return truncatedText + '...';
  }

  customOptionsOne: any = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,  // Set this to false
    pullDrag: false,
    dots: false,
    navSpeed: 400,
    navText: ['', ''],
    autoplay: true, 
    autoplayTimeout: 10000, 
    animateOut: 'fadeOut',
    autoplayHoverPause: false,
    responsive: {
      0: {
        items: 1
      },
      740: {
        items: 3  // This line makes the carousel show three slides at a time when the screen width is at least 740 pixels.
      },
      940: {
        items: 3  // This line makes the carousel show three slides at a time when the screen width is at least 940 pixels.
      }
    },
    nav: false
  }

}
