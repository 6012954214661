<!-- <app-nav-menu></app-nav-menu> -->
<app-nav-menu *ngIf="!currentRoute.startsWith('/doctors') && !currentRoute.startsWith('/agency') && !currentRoute.startsWith('/allied-health')"></app-nav-menu>
<app-nav-menu-doctor *ngIf="currentRoute.startsWith('/doctors')"></app-nav-menu-doctor>
<app-nav-menu-agency *ngIf="currentRoute.startsWith('/agency')"></app-nav-menu-agency>
<app-nav-menu-allied-health *ngIf="currentRoute.startsWith('/allied-health')"></app-nav-menu-allied-health>
<router-outlet></router-outlet>
<!-- <app-footer></app-footer> -->
<app-footer *ngIf="!currentRoute.startsWith('/doctors') && !currentRoute.startsWith('/agency') && !currentRoute.startsWith('/allied-health')"></app-footer>
<app-doctor-footer *ngIf="currentRoute.startsWith('/doctors')"></app-doctor-footer>
<app-agency-footer *ngIf="currentRoute.startsWith('/agency')"></app-agency-footer>
<app-allied-health-footer *ngIf="currentRoute.startsWith('/allied-health')"></app-allied-health-footer>
