import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CrmService {
  constructor(private http: HttpClient) {}

  public CreateLead(model: any) {
    return this.http.post(`${environment.apiUrl}/crm/lead`, model);
  }

  public GetVacancies(page: number, sectors: string[] = [], lastMinuteJobsOrNot: number = 0, doctorJobsOrNot: number = 0, alliedHealthJobsOrNot: number = 0, agencyJobsOrNot: number = 0, profession: string = "", specialistArea: string = "", location: string = "", searchQuery: string = "") {

    const sectorsQuery = sectors.length > 0 ? `&sector=${sectors.join("&sector=")}` : "";
    const professionQuery = profession ? `&profession=${profession}` : "";
    const specialistAreaQuery = specialistArea ? `&specialistArea=${specialistArea}` : "";
    const locationQuery = location ? `&location=${location}` : "";
    const searchQueryQuery = searchQuery ? `&searchQuery=${searchQuery}` : ""; 

    return this.http.get(`${environment.apiUrl}/crm/hpsvacancies?page=${page}`
      + sectorsQuery + `&lastMinuteJobsOrNot=${lastMinuteJobsOrNot}`
      + `&doctorJobsOrNot=${doctorJobsOrNot}` + `&alliedHealthJobsOrNot=${alliedHealthJobsOrNot}`
      + `&agencyJobsOrNot=${agencyJobsOrNot}` + professionQuery + specialistAreaQuery + locationQuery + searchQueryQuery);
}

  

  public GetVacancy(hps_vacancyid: string) {
    return this.http.get(
      `${environment.apiUrl}/crm/hpsvacancies/${hps_vacancyid}`
    );
  }
}
