<section class="container has-padding-top-lg">
    <nav class="panel has-padding-bottom-lg">
        <div class="panel-heading">
            <h4>Search by Skills</h4>
        </div>
        <div class="panel-block">
            <p class="control has-icons-left">
                <input [(ngModel)]="filter" class="input" type="text" placeholder="Search">
                <span class="icon is-left">
                    <i class="fa fa-search"></i>
                </span>
            </p>
        </div>
        <div class="panel-tabs-scrollable has-text-centered">
            <div class="panel-tabs">
                <h6 class="has-text-weight-bold">
                    <a (click)="setActiveType('A&E')" [ngClass]="{'is-active': type === 'A&E'}"><i class="fa fa-medkit"></i> A&E</a>
                    <a (click)="setActiveType('Medical Wards')" [ngClass]="{'is-active': type === 'Medical Wards'}"><i class="fa fa-hospital"></i> Medical</a>
                    <a (click)="setActiveType('Surgical Wards')" [ngClass]="{'is-active': type === 'Surgical Wards'}"><i class="fa fa-user-md"></i> Surgical</a>
                    <a (click)="setActiveType('Pediatric Wards')" [ngClass]="{'is-active': type === 'Pediatric Wards'}"><i class="fa fa-child"></i> Paediatric</a>
                    <a (click)="setActiveType('Midwifery')" [ngClass]="{'is-active': type === 'Midwifery'}"><i class="fa fa-female"></i> Midwifery</a>
                    <a (click)="setActiveType('Obstetric & Gynecology Wards')" [ngClass]="{'is-active': type === 'Obstetric & Gynecology Wards'}"><i class="fa fa-heartbeat"></i> Obstetric & Gynecology</a>
                    <a (click)="setActiveType('Psychiatric Wards')" [ngClass]="{'is-active': type === 'Psychiatric Wards'}"><i class="fa fa-brain"></i> Psychiatric</a>
                    <a (click)="setActiveType('Intensive Care Units')" [ngClass]="{'is-active': type === 'Intensive Care Units'}"><i class="fa fa-procedures"></i> ITU</a>
                    <a (click)="setActiveType('Specialized Wards')" [ngClass]="{'is-active': type === 'Specialized Wards'}"><i class="fa fa-clipboard-list"></i> Specialized</a>
                    <a (click)="setActiveType('Rehabilitation Wards')" [ngClass]="{'is-active': type === 'Rehabilitation Wards'}"><i class="fa fa-wheelchair"></i> Rehabilitation</a>
                    <a (click)="setActiveType('Outpatient Wards')" [ngClass]="{'is-active': type === 'Outpatient Wards'}"><i class="fa fa-hospital"></i> Outpatient</a>
                </h6>
            </div>
        </div>

        <a *ngFor="let ward of filteredWards()" 
        class="panel-block" 
        [href]="ward.externalUrl" 
        target="_blank">
         <span class="panel-icon">
             <i class="fa fa-book" aria-hidden="true"></i>
         </span>
         <h6>
             {{ ward.name }}
         </h6>
     </a>
     

        <div class="panel-block">
            <button (click)="resetFilters()" class="button is-link is-outlined is-fullwidth">
                Reset all filters
            </button>
        </div>
    </nav>
</section>
