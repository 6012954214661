import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { CrmService } from "src/app/services/crm.service";

@Component({
  selector: 'app-allied-health-organisations',
  templateUrl: './allied-health-organisations.component.html',
  styleUrls: ['./allied-health-organisations.component.scss']
})
export class AlliedHealthOrganisationsComponent implements OnInit {

  formGroup: FormGroup;
  captchaSolved: boolean = false;
  formSubmitted: boolean = false;
  formSuccess: boolean = false;
  formFailure: boolean = false;
  isLoading: boolean = false;

  constructor(private crmService: CrmService, private router: Router) {
    this.formGroup = new FormGroup({
      firstName: new FormControl("", [Validators.required]),
      lastName: new FormControl("", [Validators.required]),
      phone: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required, Validators.email]),
      questions: new FormControl("", [
        Validators.required,
        Validators.required,
      ]),
      callingConsent: new FormControl("", [Validators.required]),
    });
  }

  ngOnInit() {}

  get f(): any {
    return this.formGroup.controls;
  }

  formSubmission() {
    this.formSubmitted = true;
    this.isLoading = true; // Start loading

    if (this.formGroup.invalid) {
      this.isLoading = false; // Stop loading if form is invalid
      return;
    }

    // Solve captcha
    if (true) {
      var leadDTO: any = {
        firstName: this.formGroup.value.firstName,
        lastName: this.formGroup.value.lastName,
        mobile: this.formGroup.value.phone,
        email: this.formGroup.value.email,
        subject: "Doctors Hospital Client Enquiry",
        description: JSON.stringify({
          callingConsent: this.formGroup.value.callingConsent,
          questions: this.formGroup.value.email,
        }),
      };

      this.crmService.CreateLead(leadDTO).subscribe({
        next: (res: any) => {
          this.formSuccess = true;
          this.formFailure = false;
          this.isLoading = false; // Stop loading on success
        },
        error: (err: any) => {
          this.formFailure = true;
          this.isLoading = false; // Stop loading on error
          console.error("Error while submitting form:", err);
        },
      });
    } else {
      alert("Please solve captcha");
    }
  }

  processForm(): void {
    this.formSubmission();
  }

  resolved(captchaResponse: string) {
    this.captchaSolved = true;
  }
}
