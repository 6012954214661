import {
  Component,
  OnDestroy,
  OnInit,
  Inject,
  Renderer2,
  PLATFORM_ID,
} from "@angular/core";
import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { fromEvent, Observable, Subscription, Subject } from "rxjs";
import { filter, map, mergeMap, startWith } from "rxjs/operators";
import { takeUntil, debounceTime } from "rxjs/operators";
import { BreadcrumbService } from "xng-breadcrumb";
import { SeoService } from "./services/seo.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [SeoService],
})
export class AppComponent {
  scrollObservable$?: Observable<Event>;
  scrollSubscription$?: Subscription;
  isScrolled: boolean;
  defaultMetatags: any = {
    title: `Health Professionals`,
    description: `Agency for nursing jobs and nursing assignments. Health Professionals is a specialist nursing agency with 25 years of placing specialist nursing staff in nursing jobs across the UK.`,
    keywords: `Software, 1st, supports, career, health, professionals, nursing, agency, specialist, team, trust`,
    canonical_url: environment.siteUrl,
    robots: `index, follow`,
    schema: {
      "@context": "https://schema.org",
      "@graph": [
        {
          "@type": "Organization",
          "@id": environment.siteUrl,
          name: "Health Professionals",
          url: environment.siteUrl,
          sameAs: [
            "https://www.facebook.com/HealthProNurses",
            "https://www.linkedin.com/company/health-professionals-recruitment-services",
            "https://twitter.com/HealthProNurse?lang=en",
          ],
          logo: {
            "@type": "ImageObject",
            "@id": `${environment.siteUrl}#logo`,
            inLanguage: "en-GB",
            url: `${environment.siteUrl}/assets/png/logo.png`,
            contentUrl: `${environment.siteUrl}/assets/png/logo.png`,
            width: 851,
            height: 300,
            caption: "Health Professionals",
          },
          image: {
            "@id": `${environment.siteUrl}#logo`,
          },
        },
        {
          "@type": "WebSite",
          "@id": environment.siteUrl,
          url: environment.siteUrl,
          name: "Health Professionals",
          description: "Agency for nursing jobs and nursing assignments",
          publisher: {
            "@id": environment.siteUrl,
          },
          inLanguage: "en-GB",
        },
      ],
    },
  };
  currentRoute!: string;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private doc: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private seo: SeoService,
    private breadcrumbsService: BreadcrumbService,
  ) {
    this.isScrolled = false;
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(this.router),
        map(() => this.route),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === "primary"),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        // Update currentRoute with the router's url
        this.currentRoute = this.router.url;

        if (event.hasOwnProperty("job")) {
          this.seo.replaceTitle("[JobTitle]", event["job"].title);
        }
      });

    this.breadcrumbsService.breadcrumbs$.subscribe((res: any) => {
      const tags = { ...this.defaultMetatags };

      if (res.length > 0) {
        tags.title =
          [...res]
            .reverse()
            .map((item) => item.label)
            .join(" | ") +
          " | " +
          tags.title;

        if (res[res.length - 1].description) {
          tags.description = res[res.length - 1].description;
        }

        if (res[res.length - 1].image) {
          tags.image = res[res.length - 1].image;
        }

        if (res[res.length - 1].canonical_url) {
          tags.canonical_url = `${tags.canonical_url}/${res[res.length - 1].canonical_url
            }`;
        } else {
          if (res[res.length - 1].routeLink) {
            tags.canonical_url = `${tags.canonical_url}/${res[res.length - 1].routeLink
              }`;
          }
        }

        if (res[res.length - 1].keywords) {
          tags.keywords = res[res.length - 1].keywords;
        }
      }

      this.seo.addTags(tags);
    });

    if (isPlatformBrowser(this.platformId)) {
      // Subscribe to scroll events.
      let scrollTop =
        this.doc.documentElement.scrollTop || this.doc.body.scrollTop;
      this.isScrolled = scrollTop >= 200;
      this.scrollObservable$ = fromEvent(window, "scroll", { passive: true });

      this.scrollSubscription$ = this.scrollObservable$
        .pipe(debounceTime(100), takeUntil(this.onDestroy$))
        .subscribe((evt) => {
          let scrollTop =
            this.doc.documentElement.scrollTop || this.doc.body.scrollTop;
          this.isScrolled = scrollTop >= 200;

          const navElem = this.renderer.selectRootElement(
            "section.navigation",
            true
          );
          const contactElem = this.renderer.selectRootElement(
            ".header__cta",
            true
          );

          // Add scrolled class if scrollTop > 200.
          if (this.isScrolled) {
            this.renderer.addClass(navElem, "is-sticky");
            this.renderer.addClass(contactElem, "is__sticky");
          } else {
            this.renderer.removeClass(navElem, "is-sticky");
            this.renderer.removeClass(contactElem, "is__sticky");
          }
        });
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
