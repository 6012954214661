import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { emailDTO } from "../../models/emailDTO.model";
import { EmailService } from "../../services/email.service";

@Component({
  selector: "app-contact-form",
  templateUrl: "./contact-form.component.html",
  styleUrls: ["./contact-form.component.css"],
})
export class ContactFormComponent implements OnInit {
  // Data
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  message: string;

  // Auxillary variables
  captchaSolved: boolean;
  formSubmitted: boolean;

  constructor(private emailService: EmailService, private router: Router) {
    this.firstName = "";
    this.lastName = "";
    this.phone = "";
    this.email = "";
    this.message = "";
    this.captchaSolved = false;
    this.formSubmitted = false;
  }

  ngOnInit() {
    this.formSubmitted = false;
  }

  formSubmission() {
    let data: emailDTO = {
      // Set File Information
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phone: this.phone,
      message: this.message,
    };

    // Solve captcha
    if (true) {
      // POST to Server
      this.emailService.SendEmail(data).subscribe();
      this.formSubmitted = true;
      // Route to Thank you page
      this.router.navigate(["contact/thank-you"]);
    } else {
      alert("Please solve captcha");
    }
  }

  processForm(): void {
    this.formSubmission();
  }

  resolved(captchaResponse: string) {
    this.captchaSolved = true;
  }
}
