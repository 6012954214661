import { Component} from '@angular/core';

@Component({
  selector: 'app-job-region',
  templateUrl: './job-region.component.html',
  styleUrls: ['./job-region.component.scss']
})
export class JobRegionComponent  {

  constructor() { }



}
