import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agency-footer',
  templateUrl: './agency-footer.component.html',
  styleUrls: ['./agency-footer.component.scss']
})
export class AgencyFooterComponent implements OnInit {

  currentYear = new Date().getFullYear();

  constructor() { }

  ngOnInit() {
  }
}
