<section class="section">
    <div class="container">
        <div class="columns is-vcentered ">
            <div class="column is-6">
                <img src="../assets/png/slide-heart.webp" alt="Slider Image" />
            </div>
            <div class="column is-6">
                <h3>Looking for a nursing agency that puts you 1st? <a routerLink="/join-us">Sign up here</a></h3>

                <app-contact-form></app-contact-form>

            </div>
        </div>
    </div>
</section>

<section class="section ">
    <div class="container">
        <div class="columns is-vcentered has-margin-top-md tiles">
            <h1>This Section needs to be better designed!</h1>
            <div class="column is-6">
                <h3>
                    Looking to work as a full time agency nurse? Join an agency that will put you 1st
                </h3>
            </div>
            <div class="column is-6">
                <h3>
                    Have a permanent full-time job but are looking to pick up extra agency shifts? Our app enables this in seconds
                </h3>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="container">
        <h1>Our 5 Point promises Section has NOT been designed - <a href="https://balsamiq.cloud/s5070pc/pnrbqzw/rC323">see
                Balsamiq here</a>!</h1>
    </div>
</section>

<section>
    <div class="container">
        <h1>Grid of Images from our Nurses has NOT been designed - <a href="https://balsamiq.cloud/s5070pc/pnrbqzw/rC323">see Balsamiq here</a>!</h1>
    </div>
</section>

<app-cta-banner></app-cta-banner>