<section class="section">
    <div class="container">
        <div class="columns" style="align-items: center !important;">
            <div class="column is-6-desktop ">
                <h1 class="has-text-centered-mobile">Industry leading software supports your career</h1>
                <nav class="level is-mobile is-flex-wrap-mobile has-margin-top-lg">
                    <div class="level-item is-justify-content-end is-justify-content-center-mobile has-text-centered">
                        <img src="../assets/svg/why-choose-1.svg" alt="" />
                        <span class="has-text-weight-bold has-text-left ">Live Shift<br /> Booking</span>
                    </div>
                    <div class="level-item is-justify-content-end is-justify-content-center-mobile has-text-centered">
                        <img src="../assets/svg/why-choose-3.svg" alt="" />
                        <span class="has-text-weight-bold has-text-left ">Compliance <br /> Checked</span>
                    </div>
                    <div class="level-item is-justify-content-end is-justify-content-center-mobile has-text-centered">
                        <img src="../assets/svg/why-choose-4.svg" alt="" />
                        <span class="has-text-weight-bold has-text-left ">Save <br /> Time</span>
                    </div>
                </nav>
                <!-- <hr />
                <div class="is-flex is-justify-content-space-between">
                    <a routerLink="/join-us" class="lime__green--btn">Apply now</a>
                    <a href="https://nurseapp.healthprofessionals.co.uk/" class="dark__blue--btn">Login to
                        MyNurseApp</a>
                </div> -->
            </div>
            <div class="column is-6-desktop">
                <img src="../assets/png/slider-hearts.webp" />
            </div>
        </div>
    </div>
</section>

<div class="curved">
    <section class="section light__green--bg has-padding-top-none has-padding-bottom-none">
        <div class="container">
            <h2 class="has-text-centered">Booking software built with the latest technology</h2>
            <hr class="short__hr" />
            <h4 class="has-text-centered has-margin-top-md">We've developed MyNurseApp especially for you, our Health
                Professionals' Registered Nurses.</h4>
            <div class="columns is-vcentered is-multiline has-margin-top-md">
                <div class="column is-6">
                    <div class="white__wrapper">
                        <h4>View your current bookings in real-time</h4>
                        <h5>Features of MyNurseApp at-a-glance</h5>
                        <ul class="bullet__list">
                            <li>Update your availability.</li>
                            <li>Review your booking history.</li>
                            <li>Search & book shifts in specific wards & units you like.</li>
                        </ul>
                    </div>
                </div>
                <div class="column is-6">
                    <div class="white__wrapper">
                        <h4>Our booking system: all in one place</h4>
                        <h5>Features of MyNurseApp at-a-glance</h5>
                        <ul class="bullet__list">
                            <li>Matches your availability and workspace/shift preferences to the jobs available within
                                second.
                            </li>
                            <li>Assign you to the shift that you've chosen.</li>
                            <li>Verifies your compliance and helps keep you up-to-date.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <a routerLink="/join-us" class="dark__blue--btn centered">Sign up for free</a>
        </div>
    </section>
</div>

<section class="section has-padding-bottom-xxl">
    <div class="container">
        <div class="columns is-vcentered">
            <div class="column is-6">
                <img src="../assets/png/phone-bookings.webp" />
            </div>
            <div class="column is-6">
                <h4>View available shifts on MyNurseApp - in real-time</h4>
                <p>When it comes to securing an assignment, our bespoke technology will give you an edge. Most shifts
                    are automatically booked within milliseconds of the details being released by our clients, primarily
                    NHS Hospitals. </p>
                <h4 class="color__lime">The benefits to you...</h4>
                <p>
                    Not only are we specialists in bookings' automation, but our system can also manage your compliance.
                    This enables you to concentrate on delivering excellent patient care, and enjoy your free time
                    outside of work.
                </p>
                <a routerLink="/" class="lime__green--btn">Sign up for free</a>
            </div>
        </div>
    </div>
</section>
<section class="has-padding-bottom-xl">
    <div class="curved">
        <div class="section light__green--bg has-padding-top-none">
            <div class="container">
                <div class="columns is-vcentered is-multiline">
                    <div class="column is-6 has-padding-top-none">
                        <h4>Your Availability - MyNurseApp</h4>
                        <p>Our booking platform will: recognise shift requirement details as they are released, match it
                            with your set availability and, crucially, automatically confirm the booking directly with
                            the
                            hospital. You can also....
                        </p>
                        <ul class="bullet__list">
                            <li>Change your availability from anywhere in the world</li>
                            <li>See a summary of shifts booked</li>
                            <li>
                                Secure your choice of shifts in advance, and stay informed before and after you go on
                                duty.
                                You are in control of your shifts... with just a few taps from your phone
                            </li>
                        </ul>
                        <a routerLink="/join-us" class="dark__blue--btn has-margin-top-md">Sign up for free</a>

                    </div>
                    <div class="column is-6 has-padding-top-none">
                        <img src="../assets/png/your-availability.webp" />

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>