import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-open-day',
  templateUrl: './open-day.component.html',
  styleUrls: ['./open-day.component.scss']
})
export class OpenDayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
