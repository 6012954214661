<section class="has-padding-top-sm">
  <div class="curved">
    <!-- job-listings.component.html -->
    <!-- <div class="search-bar">
      <input type="text" [(ngModel)]="searchQuery" (input)="applySearch()" placeholder="Search jobs...">
    </div> -->

    <section class="section has-padding-top-none light__green--bg">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li>
            <a href="/">
              <span class="icon is-small">
                <i class="fas fa-home" aria-hidden="true"></i>
              </span>
              <span>Home</span>
            </a>
          </li>
          <li>
            <a>
              <span class="icon is-small">
                <i class="fas fa-book" aria-hidden="true"></i>
              </span>
              <span>Job List</span>
            </a>
          </li>
        </ul>
      </nav>
      <div *ngIf="loading" class="has-text-centered spinner-container">
        <div class="spinner"></div>
      </div>
      <div *ngIf="!loading">
        <h2 class="has-text-centered has-padding-bottom-sm">All Jobs [{{ totalFilteredJobs }}]</h2><br>
        <nav class="pagination is-small is-centered" role="navigation" aria-label="pagination">
          <a class="pagination-previous" (click)="goToPage(currentPage - 1)"
            [class.is-disabled]="currentPage === 1">Previous</a>
          <a class="pagination-next" (click)="goToPage(currentPage + 1)"
            [class.is-disabled]="currentPage === totalPages || hpsVacancies.length == 0">Next page</a>
          <ul class="pagination-list">
            <li *ngFor="let page of pages">
              <a class="pagination-link" [class.is-current]="currentPage === page" (click)="goToPage(page)"
                title="Goto page {{page}}">{{page}}</a>
            </li>
          </ul>
        </nav>
        <div class="container">
          <div class="columns">
            <!-- Left Column for Filters -->
            <div class="column is-4">
              <div class="">

                <div class="filter-sidebar">

                  <!-- Job Type Filter Dropdown -->
                  <div class="filter-group" [class.is-active]="dropdownStates.jobType">
                    <button class="filter-title" (click)="toggleDropdown('jobType')">
                      <span>Filter by Job Type</span>
                      <i class="fas"
                        [ngClass]="{'fa-angle-up': dropdownStates.jobType, 'fa-angle-down': !dropdownStates.jobType}"></i>
                    </button>
                    <div class="filter-options" *ngIf="dropdownStates.jobType">
                      <div *ngFor="let jobType of jobTypes" class="filter-option"
                        (click)="setFilter('jobType', jobType.value)">
                        <label>{{ jobType.name }}</label>
                        <input type="checkbox" [checked]="isFilterActive('jobType', jobType.value)">
                      </div>
                    </div>
                  </div>
                  <!-- Profession Filter Dropdown -->
                  <div class="filter-group" [class.is-active]="dropdownStates.profession">
                    <button class="filter-title" (click)="toggleDropdown('profession')">
                      <span>Filter by Profession</span>
                      <i class="fas"
                        [ngClass]="{'fa-angle-up': dropdownStates.profession, 'fa-angle-down': !dropdownStates.profession}"></i>
                    </button>
                    <div class="filter-options" *ngIf="dropdownStates.profession">
                      <div *ngFor="let profession of professions" class="filter-option"
                        (click)="setFilter('profession', profession.value)">
                        <label>{{ profession.name }}</label>
                        <input type="checkbox" [checked]="isFilterActive('profession', profession.value)">
                      </div>
                    </div>
                  </div>

                  <div class="filter-group" [class.is-active]="dropdownStates.specialistArea">
                    <button class="filter-title" (click)="toggleDropdown('specialistArea')">
                      <span>Filter by Specialist Area</span>
                      <i class="fas"
                        [ngClass]="{'fa-angle-up': dropdownStates.specialistArea, 'fa-angle-down': !dropdownStates.specialistArea}"></i>
                    </button>
                    <div class="filter-options" *ngIf="dropdownStates.specialistArea">
                      <div *ngFor="let specialistArea of specialistAreas" class="filter-option"
                        (click)="setFilter('specialistArea', specialistArea.value)">
                        <label>{{ specialistArea.name }}</label>
                        <input type="checkbox" [checked]="isFilterActive('specialistArea', specialistArea.value)">
                      </div>
                    </div>
                  </div>

                  <div class="filter-group" [class.is-active]="dropdownStates.location">
                    <button class="filter-title" (click)="toggleDropdown('location')">
                      <span>Filter by Location</span>
                      <i class="fas"
                        [ngClass]="{'fa-angle-up': dropdownStates.location, 'fa-angle-down': !dropdownStates.location}"></i>
                    </button>
                    <div class="filter-options" *ngIf="dropdownStates.location">
                      <div *ngFor="let location of locations" class="filter-option"
                        (click)="setFilter('location', location.value)">
                        <label>{{ location.name }}</label>
                        <input type="checkbox" [checked]="isFilterActive('location', location.value)">
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="reset-button">
                <button class="button is-small" (click)="resetFilters()">Reset Filters</button>
              </div>
            </div>
            <div class="column is-8">
              <div class="container">
                <div *ngFor="let vacancy of hpsVacancies" class="job__listing--item has-padding-bottom-xl">
                  <h3 class="has-padding-bottom-lg">{{vacancy.cr5b8_title}}</h3>
                  <ul>
                    <li class="flex-container">
                      <div class="icon-container"><i class="fa-regular fa-user"></i></div>
                      <span class="item-text">{{vacancy.hps_SpecialistAreaid.hps_name}}</span>
                    </li>
                    <li class="flex-container">
                      <div class="icon-container"><i class="fas fa-map-marker-alt location-icon"></i></div>
                      <span class="item-text">{{vacancy.cr5b8_location}}</span>
                    </li>
                    <li class="flex-container">
                      <div class="icon-container"><i class="fas fa-clock clock-icon"></i></div>
                      <span class="item-text">Immediate Start</span>
                    </li>
                    <li class="flex-container">
                      <div class="icon-container"><i class="fas fa-money-bill money-icon"></i></div>
                      <span class="item-text">{{ vacancy.cr5b8_payelements }}</span>
                    </li>
                    <li class="flex-container">
                      <div class="icon-container"><i class="fas fa-info-circle info-icon"></i></div>
                      <span class="item-text">{{ truncateDescription(vacancy.cr5b8_shortjobdescription, 200) }}</span>
                    </li>
                  </ul>

                  <br>

                  <div class="has-text-left">
                    <span>Created on: {{vacancy.createdon | date:'medium'}}</span>
                  </div>
                  <div class="has-text-right-desktop has-text-centered-mobile has-padding-top-sm">
                    <a href="job-view/{{vacancy.hps_vacancyid}}" class="dark__blue--btn">View Job</a>
                  </div>
                </div>

                <br>

              </div>
            </div>
          </div>
        </div>
        <nav class="pagination is-small is-centered" role="navigation" aria-label="pagination">
          <a class="pagination-previous" (click)="goToPage(currentPage - 1)"
            [class.is-disabled]="currentPage === 1">Previous</a>
          <a class="pagination-next" (click)="goToPage(currentPage + 1)"
            [class.is-disabled]="currentPage === totalPages || hpsVacancies.length == 0">Next page</a>
          <ul class="pagination-list">
            <li *ngFor="let page of pages">
              <a class="pagination-link" [class.is-current]="currentPage === page" (click)="goToPage(page)"
                title="Goto page {{page}}">{{page}}</a>
            </li>
          </ul>
        </nav>
      </div>
    </section>
  </div>
</section>