import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';
import { trigger, state, style, animate, transition } from '@angular/animations';

import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-refer-a-friend',
  templateUrl: './refer-a-friend.component.html',
  styleUrls: ['./refer-a-friend.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        maxHeight: '1000px', // Change this value to something that will always be greater than your content height
        opacity: 1,
        padding: '15px 15px',
      })),
      state('closed', style({
        height: '0',
        opacity: 0,
        padding: '0 15px',
      })),
      transition('open <=> closed', [
        animate('0.5s ease-in-out')
      ]),
    ]),
  ]
})
export class ReferAFriendComponent {

  items = [
    { 
      question: "Terms and Conditions:", 
      answer: "Please read carefully the following terms and conditions before making a referral: You should provide all necessary information about your referral at the time of recommendation. Failure to do so may render you ineligible to receive a bonus. You can refer as many nurses as you wish. However, a candidate can only have one referrer, and bonus payments cannot be split. Referrals made before the commencement of this scheme, or of candidates who have previously registered with or worked for Health Professionals, will not be eligible for the scheme. The bonus will be paid out only when you claim it on a Health Professionals timesheet within 3 months after the date of the 250 hours completion.  All bonus payments are subject to tax deduction and NI and are non-transferable. You must comply with all Health Professionals' policies to be eligible to receive your referral payment. We reserve the right to withhold any claim if all the terms and conditions are not met. Health Professionals reserves the right to vary or withdraw this scheme at its discretion. Make the most out of your network by referring a friend to Health Professionals today, and let's work together to provide exceptional health care services. Your recommendation is the highest compliment we can receive, and we can't wait to meet the remarkable professionals in your network!", 
      show: false 
    },
  ];
  formGroup: FormGroup;
  formSubmitted: boolean;
  formSuccess: boolean;
  formFailure: boolean;

  constructor(private route: ActivatedRoute) {
    emailjs.init('M27SyEKv2Q5WzJNla'); // Replace 'your-user-id' with your EmailJS user id

    this.formGroup = new FormGroup({
      refereeName: new FormControl("", [Validators.required]),
      refereeEmail: new FormControl("", [Validators.required, Validators.email]),
      friendName: new FormControl("", [Validators.required]),
      friendEmail: new FormControl("", [Validators.required, Validators.email]),
      friendPhone: new FormControl("", [
        Validators.required,
        Validators.minLength(9),
        Validators.maxLength(15),
      ]),
      nhsExperience6Months: new FormControl("", [Validators.required]),
      lookingFor: new FormControl(""),
      rightToWork: new FormControl("", [Validators.required]),
      specialistArea: new FormControl("", [Validators.required]),
    });

    this.formSubmitted = false;
    this.formSuccess = false;
    this.formFailure = false;
  }

  get f(): any {
    return this.formGroup.controls;
  }

  formSubmission() {
    this.formSubmitted = true;
    if (this.formGroup.invalid) {
      return;
    }

    let templateParams = {
        from_name: this.formGroup.controls['refereeName'].value,
        from_email: this.formGroup.controls['refereeEmail'].value,
        friend_name: this.formGroup.controls['friendName'].value,
        friend_email: this.formGroup.controls['friendEmail'].value,
        friend_phone: this.formGroup.controls['friendPhone'].value,
        nhs_experience: this.formGroup.controls['nhsExperience6Months'].value,
        right_to_work: this.formGroup.controls['rightToWork'].value,
        specialist_area: this.formGroup.controls['specialistArea'].value,
    };

    emailjs.send('service_ranhkjr', 'template_mlx49dr', templateParams)
        .then((response) => {
           console.log('SUCCESS!', response.status, response.text);
           this.formSuccess = true;
        }, (error) => {
           console.log('FAILED...', error);
           this.formFailure = true;
        });

    // Solve captcha
    // if (true) {
    //   var leadDTO: any = {
    //     refereeName: this.formGroup.value.refereeName,
    //     refereeEmail: this.formGroup.value.refereeEmail,
    //     friendName: this.formGroup.value.firstName,
    //     lastName: this.formGroup.value.lastName,
    //     mobile: this.formGroup.value.friendPhone,
    //     email: this.formGroup.value.email,
    //     postCode: this.formGroup.value.postCode,
    //     subject: "Agency Nurse Application",
    //     nmcPin: this.formGroup.value.nmcPin,
    //     dob: this.formGroup.value.dob,
    //     description: JSON.stringify({
    //       nhsExperience6Months: this.formGroup.value.nhsExperience6Months,
    //       rightToWork: this.formGroup.value.rightToWork,
    //       enhancedRates: this.formGroup.value.enhancedRates,
    //       workTravelDistance: this.formGroup.value.workTravelDistance,
    //       specialistArea: this.formGroup.value.specialistArea,
    //       postcode: this.formGroup.value.postCode,
    //       lookingFor: this.formGroup.value.lookingFor,
    //       callingConsent: this.formGroup.value.callingConsent,
    //       hps_vacancyid: this.formGroup.value.hps_vacancyid,
    //       cr5b8_title: this.formGroup.value.cr5b8_title,
    //     }),
    //   };

      // this.crmService.CreateLead(leadDTO).subscribe({
      //   next: (res: any) => {
      //     this.formSuccess = true;
      //     this.formFailure = false;
      //   },
      //   error: (err: any) => {
      //     this.formFailure = true;
      //   },
      // });
    // } else {
    //   alert("Please solve captcha");
    // }
  }

  // resolved(captchaResponse: string) {
  //   this.captchaSolved = true;
  // }

}
