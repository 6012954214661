<div class="curved">
    <section class="section light__green--bg has-padding-top-none has-padding-bottom-none">
        <div class="container">
            <h2 class="has-text-centered">Careers at Health Professionals </h2>
            <!-- <hr class="short__hr" />
            <h4 class="has-text-centered has-margin-top-md">The UK's Leading Specialist Recruitment Agency for Travel
                Nurses, Full-Time Positions, and Occupational Health Nurses</h4> -->
        </div>
    </section>
</div>
<br><br>
<div class="">
    <section class="section light__green--bg has-padding-top-none has-padding-bottom-xl">
        <div class="container">
            <div class="columns is-vcentered is-multiline">
                <div class="column is-8 has-padding-top-none">
                    <br><br>
                    <h2>Company</h2>


                    <p> Welcome to Health Professionals, where your career in nursing and healthcare truly matters. With
                        25 years of rich history in placing specialist nursing staff across the UK, we take immense
                        pride in our service and commitment. Our team may be small, but our dreams are big and so are
                        our resources. </p>


                    <p>Our candidates are our priority. We focus on delivering exceptional support and training to them,
                        enabling them to provide the highest quality of service across the NHS.
                    </p>

                    <h4>Technology:</h4>
                    <p>We proudly support our nurses with the advanced MyNurseApp. This technology gives our nurses the
                        flexibility to choose their own shifts, select their preferred locations, and agree to rates
                        that work for them, anytime, anywhere. With MyNurseApp, our nursing staff enjoys unparalleled
                        control over their careers and earnings, reaffirming our commitment to their professional growth
                        and satisfaction. </p>

                    <h4>Join us:</h4>
                    <p> Join us and become part of our ongoing success story. With our commitment to being there for you
                        24/7, 365 days a year, you're never alone in your journey. You'll have the chance to work
                        closely with our core team, grow with us, and be part of our continuous innovation in serving
                        the healthcare sector. </p>



                </div>
                <div class="column is-4 has-padding-top-none">
                    <img src="../assets/png/ipad-app.webp" />
                    <br><br><br><br>
                    <img class="is is-hidden-mobile" src="../assets/png/25-years.webp" />
                </div>
            </div>
        </div>
    </section>
</div>

<section class="section has-padding-bottom-xxl">
    <div class="container">
        <div class="columns is-vcentered">

            <div class="column is-8">

                <h2>Current Openings</h2>
                <br>
                <u>
                    <h4>Recruitment Consultant</h4>
                </u>
                <p>We're on the lookout for a dynamic, disciplined, and experienced Healthcare Recruiter to expand our
                    remote team. This role is a key part of our ambitious growth plans, where you will help uphold our
                    strong reputation and nurture our excellent relationships with clients and nurses. </p>


                <ul>In this role, you will:</ul>
                <br>
                <li>Engage with our state-of-the-art CRM and Bookings System</li>
                <li>Deliver superior customer service at all times</li>
                <li>Manage candidate engagement</li>
                <li>Promote job vacancies on social media and job boards</li>
                <li>Achieve weekly targets</li>
                <li>Support the management team with ad-hoc tasks</li>


                <ul>What we look for in our ideal candidate:</ul>
                <br>
                <li>Direct relevant experience coupled with excellent customer service skills</li>
                <li>Professionalism and assertiveness</li>
                <li>Strong communication skills</li>
                <li>IT-savviness and a proactive mindset</li>
                <li>Experience with Microsoft Teams</li>
                <li>Discretion and confidence in liaising with NHS clients and managers</li>


                <p>What we offer in return is a supportive work environment that fosters your growth, values your
                    commitment to "going the extra mile", and provides appropriate rewards. We're seeking a motivated
                    self-starter, who can offer first-line support to the team, show initiative, and make a tangible
                    difference in the recruitment of nurses. </p>
                <p>Job Types: <u>Full-time, Permanent</u></p>
                <p>Take the next step in your career with Health Professionals. Apply today! </p><a
                    class="lime__green--btn has-text-centered-mobile is-block-mobile"
                    href="mailto:deborahpreston@healthprofessionals.co.uk">Email</a>

            </div>
            <div class="column is-4 is-hidden-mobile">
                <img src="../assets/png/nurse-stock-2.webp" />
                <br><br><br><br>
                <img class="is is-hidden-mobile" src="../assets/png/nurse-stock.webp" />
            </div>
        </div>
    </div>
</section>