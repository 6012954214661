import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';


@Component({
  selector: 'app-qa',
  templateUrl: './qa.component.html',
  styleUrls: ['./qa.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        maxHeight: '1000px', // Change this value to something that will always be greater than your content height
        opacity: 1,
        padding: '15px 15px',
      })),
      state('closed', style({
        height: '0',
        opacity: 0,
        padding: '0 15px',
      })),
      transition('open <=> closed', [
        animate('0.5s ease-in-out')
      ]),
    ]),
  ]
})
export class QAComponent implements OnInit {
  items = [
    { 
      question: "Q. How would you describe Health Professionals' expertise, knowledge and service levels?", 
      answer: "A. I’ve always found Health Professionals to be very knowledgeable. For instance, whenever I’ve sent a query/question over to them they have readily and comprehensively been able to respond and assist. Their service levels are extremely good, with a large percentage fill in A&E. I know that if I am struggling to find a certain type of nurse and/or difficult area to fill, the team will always do their best to find me someone.", 
      show: false 
    },
    { 
      question: "Q. Their efficiency in systems and documentation?", 
      answer: "A. Impressively, Health Professionals has developed its own technology that intuitively links in with our NHS booking systems. This means that the team can effectively and efficiently – in real-time – use our online booking system. In terms of documentation, Health Professionals is always able to provide us with any information we require. For example, we recently asked for Intention to Practise forms for midwives; I received these within a couple of minutes.", 
      show: false 
    },
    { 
      question: "Q. Their quality and effectiveness in communication with you and other senior management?", 
      answer: "A. Health Professionals has always been one of our best agencies in terms of communication. We’re always made aware of cancellations and/or any potential bookings. Emails are always clear and easy to understand, which greatly speeds things up for my team. Whenever I have spoken to members of the team on the phone, I’ve always found them polite and helpful, and have built a rapport with them all. In our line of work I feel it is so important to have this type of relationship and I am happy to say that I have this with the Health Professionals’ team.", 
      show: false 
    },
    { 
      question: "Q. In particular, what is your opinion on the quality of nursing staff provided?", 
      answer: "A. Health Professionals always provides very good quality nurses and we rarely have any clinical issues or complaints regarding one of their nurses. Health Professionals’ nurses are well liked, especially in A&E. This was recently verified in a number of feedback forms we received from our A&E department, giving high praise to Health Professionals’ nurses. That speaks volumes...", 
      show: false 
    },
    { 
      question: "Q. How, in your opinion, does Health Professionals compare with other nursing agencies?", 
      answer: "A.With other agencies there is a tendency to be all about filling the shifts with little regard for the Trust’s processes and/or procedures, and sometimes communication with them can be very cold. Health Professionals is different in that they are warm and approachable, and if there are any issues and/or problems, these are broached with me calmly and professionally. It’s very much a two-way working relationship which focuses on adhering to, and appreciating, our Trust policies.", 
      show: false 
    },
    { 
      question: "Q. Are you satisfied that Health Professionals responds to all requests swiftly and in line with the Trust fill rate methodology?", 
      answer: "A. Yes, the team is aware of when we issue the shifts, and from my understanding, is ready to fill the shifts when they are released. We often send out urgent emails regarding unfilled shifts and Health Professionals is one of the only agencies to let us know whether or not they are able to fill; most agencies do not bother to respond.", 
      show: false 
    },
    { 
      question: "Q. What do you believe are the company's greatest strengths?", 
      answer: "A. The people who work for them. I always get the impression that they are trying 100% for us, as well as being responsive, warm and approachable, as outlined above.", 
      show: false 
    },
    { 
      question: "Q. What, predominantly, are the types of nursing staff you engage via Health Professionals?", 
      answer: "A. We engage General and Critical Care, Band 5 nurses, and Health Professionals is also capable of sourcing Clinical Nurse Specialists and Emergency Nurse Practitioners as our demand dictates. Equally, we have recently started using Health Professionals for Midwifery staff and are hoping to increase our fill with them in this area.", 
      show: false 
    },
    { 
      question: "Q. Overall, how has working with Health Professionals made a difference to your operation, and why?", 
      answer: "A. Without the continual help of Health Professionals we would have significant staff shortages in A&E, and also the wards that require Clinical Nurse Specialists and night shift cover would be under pressure.", 
      show: false 
    },
  ];
  
  constructor() { }

  ngOnInit(): void {
  }

}
