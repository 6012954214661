<section class="section light__green--bg curved has-padding-top-none has-padding-bottom-none helllo">
    <div class="container">
        <h2 class="has-text-centered">Title to go here</h2>
        <div class="dotted__line"></div>
        <h5 class="color__darkblue has-text-centered has-margin-bottom-none has-margin-top-lg">Subtitle to go here</h5>
        <h5 class="has-text-centered has-margin-bottom-xl">Description to go here</h5>


        <div class="tab__wrapper">
            <div class="tabs">
                <ul>
                    <li class="tab__button active" data-id="tab-1" (click)="toggleTab('0')" [ngClass]="{ active: tabs[0] }">Tab Title 01</li>
                    <li class="tab__button" data-id="tab-2" (click)="toggleTab('1')" [ngClass]="{ active: tabs[1] }">Tab Title 01
                    </li>
                    <li class="tab__button" data-id="tab-3" (click)="toggleTab('2')" [ngClass]="{ active: tabs[2] }">Tab Title 01
                    </li>
                    <li class="tab__button" data-id="tab-4" (click)="toggleTab('3')" [ngClass]="{ active: tabs[3] }">Tab Title 01
                    </li>
                </ul>
            </div>
            <div class="tab__contents">
                <p class="tab__content active" id="tab-1" [ngClass]="{ active: tabs[0] }">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam iste doloribus unde ullam doloremque eos autem ad nulla accusamus? Modi at minima numquam accusamus repudiandae perspiciatis nostrum architecto voluptatem quasi? Lorem ipsum dolor sit amet
                    consectetur adipisicing elit. Quia ratione corporis earum laudantium sapiente autem praesentium error neque illum adipisci modi ea, consectetur rerum pariatur laborum aperiam at commodi! Impedit. Lorem ipsum dolor sit amet consectetur
                    adipisicing elit. Tenetur, quasi porro dolorum deleniti velit fuga perspiciatis veniam minus perferendis facere sit doloribus aliquid illum adipisci. Quam quasi maxime perferendis dolorem.
                </p>
                <p class="tab__content" id="tab-2" [ngClass]="{ active: tabs[1] }">
                    About ipsum dolor sit amet consectetur adipisicing elit. Corporis maxime itaque veritatis iste soluta placeat obcaecati laudantium repellat corrupti! Eius sunt rerum inventore magnam? Perspiciatis facere error suscipit quisquam quibusdam. Lorem ipsum,
                    dolor sit amet consectetur adipisicing elit. Totam, corporis voluptatem quo dignissimos eius quis perferendis vero culpa reiciendis nulla quisquam fugit minima sed molestiae excepturi beatae repudiandae ea? Aliquid! Lorem ipsum dolor
                    sit amet, consectetur adipisicing elit. Enim sapiente officia vel consequuntur, hic at quis? Illo repellendus dolores totam facilis sunt sequi qui hic, nulla ratione harum porro perspiciatis.
                </p>
                <p class="tab__content" id="tab-3" [ngClass]="{ active: tabs[2] }">
                    Contact ipsum dolor sit amet consectetur adipisicing elit. Dignissimos sit incidunt nostrum? Magni, quam vero, magnam odio similique ipsam minima et repellat rerum cupiditate totam in repudiandae. Sed, dicta corrupti? Lorem ipsum dolor sit amet consectetur
                    adipisicing elit. Asperiores, dolore quas quis earum incidunt voluptas! Ducimus quod libero aliquid consequatur et modi porro officia, quibusdam quas commodi placeat maxime qui? Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Ab ea debitis eligendi accusamus deleniti maxime pariatur. Assumenda, facere placeat eius quam magni accusantium aut quae minima iure atque incidunt illum.
                </p>
            </div>
        </div>

        <a routerLink="/" class="dark__blue--btn mt-6 mb-5 centered">Sign up</a>

    </div>
</section>