<section class="section">
  <div class="container">
    <div class="columns is-vcentered">
      <div class="column is-6">
        <h3 class="has-text-weight-semibold">Title to go here</h3>
        <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available."</p>
      </div>
      <div class="column is-6">
        <figure class="image">
          <img src="../assets/svg/ipad-egg.webp" alt="" class="is-egg" />
        </figure>
      </div>
    </div>
    <div class="columns is-vcentered">
      <div class="column is-6">
        <figure class="image">
          <img src="../assets/svg/ipad-egg.webp" alt="" class="is-egg" />
        </figure>
      </div>
      <div class="column is-6">
        <h3 class="has-text-weight-semibold">Title to go here</h3>
        <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available."</p>
      </div>
    </div>
  </div>
</section>
