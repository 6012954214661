<div class="has-padding-top-xl is-hidden-mobile">
</div>
<div class="has-padding-top-lg is-hidden-desktop is-hidden-tablet">
</div>

<section class="banner__slider__one">
    <div class="container">
        <div class="slider__grid__one">
            <div class="item__half--one">
                <div class="ft-container">

                </div>
                <h3 class="is-hidden-mobile has-padding-top-lg">Health Professionals</h3>
                <h1 class=" has-margin-bottom-lg has-margin-top-md has-text-centered-mobile">
                    Empowering allied health: diverse roles, dynamic careers.
                </h1>
                <ul class="button-links is-hidden-mobile">
                    <li class="has-padding-top-lg"><a routerLink="/allied-health-join-us"
                            class="dark__blue--btn has-padding-top-lg">Join Today</a></li>
                    <li class="has-padding-top-lg"><a routerLink="/allied-health-jobs"
                            class="lime__green--btn has-padding-top-lg">AHP/HSS Jobs</a></li>
                    <!-- <li class="has-padding-top-lg"><a routerLink="/join-us"
                            class="dark__blue--btn has-padding-top-lg light-blue">AHP/HSS</a></li> -->
                </ul>
            </div>
            <div class="item__half--two">
        
                <img src="../assets/png/slider-hearts.webp" alt="Slider Image" />
            </div>
            <div class="centered has-padding-top-md stretched-button">
                <a routerLink="/allied-health-join-us" class="dark__blue--btn has-margin-right-sm">Join Today</a>
                <a routerLink="/allied-health-jobs" class="lime__green--btn has-margin-right-sm">AHP/HSS Jobs</a>
                <!-- <a routerLink="/join-us" class="dark__blue--btn has-margin-right-sm light-blue">AHP/HSS</a> -->

            </div>
        </div>
    </div>
</section>

<div class="has-padding-bottom-xl is-hidden-mobile">
</div>
<!-- <section class="section light__green--bg has-padding-top-lg has-padding-bottom-lg">
    <div class="container">
        <nav class="level">
            <div class="level-left">
                <div class="level-item" style="display: flex; align-items: center;">
                    <img src="../assets/png/drop-counter.png" alt="Description" style="margin-right: 1rem;">
                    <h4 class="has-text-centered-mobile">
                        IV Training 24 Oct 2023 Fully Booked
                    </h4>
                </div>
            </div>
            <div class="level-right">
                <a routerLink="/join-us" class="lime__green--btn has-text-centered-mobile is-block-mobile">Register for next one</a>
            </div>
        </nav>
    </div>
</section> -->

<!-- <section class="section has-padding-top-sm has-padding-bottom-xl">
    <div class="container">
        <img src="../assets/svg/mynurse-logo.svg" alt="My Nurse App Logo" style="width:275px;" class="centered" />
        <div class="columns is-multiline is-vcentered">
            <div class="column is-6">
                <img src="../assets/png/app-at-glance.webp" alt="App at a glance" />
            </div>
            <div class="column is-6">
                <h4>Great control, certainty, compliance & income, all at the touch of a button</h4>
                <h5>Features of MyNurseApp At-a-glance</h5>
                <ul class="line__bullet--list">
                    <li>Live Shifts</li>
                    <li>Booking</li>
                    <li>Compliance</li>
                    <li>Calendar Management</li>
                </ul>
                <a routerLink="/mynurseapp" class="dark__blue--btn has-margin-top-lg">Learn more</a>

            </div>
        </div>
    </div>
</section> -->
<section class="light__green--bg has-padding-top-none has-padding-bottom-none curved">
    <h2 class="has-text-centered has-padding-bottom-lg">Testimonials</h2>
    <app-testimonials-slider></app-testimonials-slider>
    <a routerLink="/testimonials" class="lime__green--btn centered" style="margin-top: 0;">Find out more...</a>
</section>

<!-- <app-hear-from-others></app-hear-from-others> -->

<app-cta-banner></app-cta-banner>