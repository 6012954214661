import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-nav-menu-doctor',
  templateUrl: './nav-menu-doctor.component.html',
  styleUrls: ['./nav-menu-doctor.component.scss']
})

export class NavMenuDoctorComponent implements OnInit {

  menuActive: boolean;
  activeDropdown: string;
  isHoverable: boolean = window.innerWidth > 1024;

  constructor(@Inject(DOCUMENT) private doc: Document, private renderer: Renderer2) {
    this.menuActive = false;
    this.activeDropdown = '';
  }

  ngOnInit() {
    this.renderer.listen('window', 'resize', (event) => {
      this.isHoverable = window.innerWidth > 1024;
    });
  }

  toggleMenu() {
    this.menuActive = !this.menuActive;
  }

  toggleDropdown(event: Event, dropdown: string) {
    event.stopPropagation();
    const isSmallScreen = window.innerWidth <= 1024;

    if (isSmallScreen && event.type === 'click') {
      this.activeDropdown = this.activeDropdown === dropdown ? '' : dropdown;
    }

    if (this.isHoverable && event.type === 'mouseover') {
      this.activeDropdown = dropdown;
    }

    if (this.isHoverable && event.type === 'mouseleave') {
      this.activeDropdown = '';
    }
  }

  isDropdownActive(dropdown: string) {
    return this.activeDropdown === dropdown;
  }

}
