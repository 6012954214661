<div class="curved">
    <section class="section light__green--bg has-padding-top-none has-padding-bottom-none">
        <div class="container">
            <h2 class="has-text-centered has-padding-bottom-lg">Why Choose Us?</h2>
            <div class="tile__card has-text-centered">
                <div class="content-wrapper">
                    <div class="item-row">
                        <div class="left-side">
                            <img src="../assets/svg/why-choose-1.svg" alt="" class="is-egg" />
                        </div>
                        <div class="right-side">
                            <p>
                                <b>Our Points system boost your pay.</b> The more you work, the more you gain. Earn points for every shift you complete and watch your earnings grow!
                            </p>
                        </div>
                    </div>
                    <div class="item-row">
                        <div class="left-side">
                            <img src="../assets/svg/why-choose-2.svg" alt="" class="is-egg" />
                        </div>
                        <div class="right-side">
                            <p>
                                <b>Weekly Pay:</b> Our dedicated professional team ensures timely and reliable payment every week. You focus on your work, we'll handle the rest.
                            </p>
                        </div>
                    </div>
                    <div class="item-row">
                        <div class="left-side">
                            <img src="../assets/svg/why-choose-3.svg" alt="" class="is-egg" />
                        </div>
                        <div class="right-side">
                            <p>
                                <b>Training Support:</b> We contribute to your training courses, helping you gain new skills and advance your career. Invest in your future with MyNurseApp.
                            </p>
                        </div>
                    </div>
                    <div class="item-row">
                        <div class="left-side">
                            <img src="../assets/svg/why-choose-4.svg" alt="" class="is-egg" />
                        </div>
                        <div class="right-side">
                            <p>
                                <b>Always available:</b> Our support team is here for you 24/7, 365 days a year. Whatever you need, whenever you need it, we're just a call away. Your peace of mind is our priority.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <a routerLink="/about" class="lime__green--btn mt-6 mb-5 centered">Find out more</a>
        </div>
    </section>
</div>