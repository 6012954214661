<section class="section light__green--bg curved has-padding-top-none has-padding-bottom-none">
    <div class="container">
        <h2 class="has-text-centered">What our Nurses say about us</h2>
        <div class="columns has-margin-top-lg is-multiline tiles">
            <div class="column is-4">
                <div class="team__card has-text-centered">
                    <h4>Taj F RN</h4>
                    <figure class="is-rounded image">
                        <img src="../assets/nurses/nurses_taj.webp" alt="person" class="is-rounded" />
                    </figure>
                    <p>"I have worked for Health Professionals for the last 5 years. This has been the best decision I have made. Professional and patient team. Always willing to listen and take requests on board. Thank you for all your hard work. "</p>
                </div>
            </div>
            <div class="column is-4">
                <div class="team__card has-text-centered">
                    <h4>Margaret W RN</h4>
                    <figure class="is-rounded image">
                        <img src="../assets/nurses/nurses_margaret.webp" alt="person" class="is-rounded" />
                    </figure>
                    <p>“I have been working since 2016 with Health Professionals. This team is very professional, as the name suggests, in handling all enquiries from joining the company to payments which are always on time. Most importantly HP values the
                        nurses that work within the team. This is why I work with HP”</p>
                </div>
            </div>
            <div class="column is-4">
                <div class="team__card has-text-centered">
                    <h4>Jane K RN</h4>
                    <figure class="is-rounded image">
                        <img src="../assets/nurses/nurses_jane.webp" alt="person" class="is-rounded" />
                    </figure>
                    <p>"I would like to say there is no room for improvement. You are exceptionally good. You communicate professionally. Keep up the good work" </p>
                </div>
            </div>
        </div>
    </div>
</section>
<br><br><br>
<section class="has-padding-top-none has-padding-bottom-none "> 
    <app-testimonials-slider></app-testimonials-slider>
    <a routerLink="/join-us" class="lime__green--btn centered">Find out more...</a>
</section>

<section class="curved light__green--bg has-padding-top-none has-padding-bottom-none">
    <div class="container">
        <div class="columns has-margin-top-lg is-multiline tiles">
            <div class="column is-4">
                <div class="team__card has-text-centered">
                    <h4>Pauline U RN </h4>
                    <figure class="is-rounded image">
                        <img src="../assets/nurses/nurses_pauline.webp" alt="person" class="is-rounded" />
                    </figure>
                    <p>
                        “I joined Health Professionals just over a year ago and in all that time my pay has been correct and on time, my work is regular and respects the hours available I have. The staff are always polite, helpful and professional. So, I feel as an agency this
                        works well for me.”
                    </p>
                </div>
            </div>
            <div class="column is-4">
                <div class="team__card has-text-centered">
                    <h4>Victoria S RN </h4>
                    <figure class="is-rounded image">
                        <img src="../assets/nurses/nurses_victoria.webp" alt="person" class="is-rounded" />
                    </figure>
                    <p>"This app is top notch and user friendly. It connects you with your employer. Its easy to use!"
                    </p>
                </div>
            </div>
            <div class="column is-4">
                <div class="team__card has-text-centered">
                    <h4>Zam Zam M RN </h4>
                    <figure class="is-rounded image">
                        <img src="../assets/nurses/nurses_zamzam.webp" alt="person" class="is-rounded" />
                    </figure>
                    <p> “I highly recommend Health Professionals agency as one of the best reliable agencies with dedicated, approachable professional team. With Health Professionals, you are assured of any work anytime you need with good pay rate. With Health
                        Professionals, you are assured of any support required at anytime” </p>
                </div>
            </div>
        </div>
        <div class="section  has-padding-top-none has-padding-bottom">
            <div class="container">
               
                <a routerLink="/join-us" class="dark__blue--btn centered">Sign up today</a>
            </div>
        </div>
    </div>
</section>
<!--<section class="section light__green--bg curved has-padding-top-none has-padding-bottom-none">
  <div class="container">
    <h2 class="has-text-centered">Alternative format</h2>
    <div class="columns has-margin-top-md tiles">
      <div class="column is-6">

        <div class="quote__card">
          <p>"I have worked for Health Professionals for the last 5 years. This has been the best decision I have made. Professional and patient team. Always willing to listen and take requests on board. Thank you for all your hard work. Nokuthula N RN"</p>
        </div>
      </div>
      <div class="column is-6">
        <div class="quote__card">
          <p>"I have worked with other Nursing Agencies before but they haven't been as good as HP. Well done HP for keeping standards high..  Lilian K RN"</p>
        </div>
      </div>

    </div>
  </div>
</section>-->
