import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CrmService } from 'src/app/services/crm.service';

@Component({
  selector: 'app-agency-nurse-job-views',
  templateUrl: './agency-nurse-job-views.component.html',
  styleUrls: ['./agency-nurse-job-views.component.scss']
})
export class AgencyNurseJobViewsComponent implements OnInit {
  
  constructor(private route: ActivatedRoute, private crm: CrmService) { }

  vacancy: any = null;
  isReadMore = false;

  getDisplayDescription(description: string, limit: number): string {
    if (this.isReadMore || description?.length <= limit) {
      return description;
    }

    const truncatedText = description.slice(0, limit);

    return truncatedText + '...';
  }

  toggleReadMore4() {
    this.isReadMore = !this.isReadMore;
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      var x = params.get('id') ?? '';
      this.crm.GetVacancy(x).subscribe((res: any) => {
        console.log(res);
        this.vacancy = res;
      });
    });
  }

  openTab = (job: string) => {
    var i;
    var jobs = document.getElementsByClassName("job");
    var tabs = document.getElementsByClassName("tab");
    for (i = 0; i < jobs.length; i++) {
      (<HTMLElement>jobs[i]).style.display = "none";  
      (<HTMLElement>tabs[i]).classList.remove("active");
    }
    const jobTemp = window.document.getElementById(job)!
    const tabTemp = window.document.getElementById("tab" + job)!
    jobTemp.style.display = "block";
    tabTemp.classList.add("active");
  }

}
