import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agency-nurse-assignment',
  templateUrl: './agency-nurse-assignment.component.html',
  styleUrls: ['./agency-nurse-assignment.component.scss']
})
export class AgencyNurseAssignmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
