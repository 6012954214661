
<div class="banner__slider">
  <div>
      <div class="container">
          <div class="slider__grid">
              <div class="item__half--one">
                  <h2 class="">Health Professionals' Nurse Loyalty Reward Scheme</h2>
                  <p>At Health Professionals, we have built our reputation by constantly improving how we look after everyone, especially our workers (whether employed or self-employed) and our clients. In this way, we are committed to ensure that everyone is happy and feels truly valued.</p>
                  <p>So, with effect from the 1st of April 2019, we have started an annual loyalty points scheme to reward all of our nurses (contracted) for every hour they work.</p>
                  <p>Put simply, you get 1 point for every 1 hour that you work with us, without any maximum limit imposed.</p>
              </div>
              <div class="item__half--two">
                <img style="margin-bottom: 0;" src="../assets/png/ipad-app.webp" alt="ipad"/>
              </div>
          </div>
      </div>
  </div>
</div>
<section class="section has-padding-bottom-xxl has-padding-top-none has-margin-top-none">
  <div class="container">
    <div class="columns is-vcentered">
      <div class="column is-6 is-hidden-mobile">
        <img src="../assets/png/nurse-stock-2.webp"/>
      </div>
      <div class="column is-6">
        <h4>Qualifying for and collecting points</h4>
        <p>It's all really easy... If you are a nurse, midwife, ODP or HCA, to qualify for points, you just have to book your shifts through Health Professionals.</p>
        <p>If you are already employed, or registered with us for work, you don't even have to do anything to start earning points except to register for shifts. You are automatically enrolled into the scheme and the points will start accruing for all hours worked by you.</p>
        <p>If you are not yet employed by us, then as soon as you start, you will automatically be part of the scheme once your application has been approved.</p>
        <p>MyNurseApp shows you all the details with the dates showing your total points earned to date. You can check your app at any point in time to see when you have hit certain thresholds (points updated on Sunday every week).</p>
        <p>You can claim your rewards whenever you reach the required threshold. Just go to your app and submit a request for payment.</p>

        <h4>Start earning points now!</h4>
        <p>If you've already registered with us, just book a shift now and the rewards will start running up as soon as you have worked.</p>

        <h4>New Applicants</h4>
        <p>Even as a new applicant, you can start collecting points that are redeemable once you start working with us, signing up for assignments and for completing the application process with a set time frame.</p>
        <a routerLink="/join-us" class="dark__blue--btn">Sign up</a>
      </div>
    </div>
  </div>
</section>



