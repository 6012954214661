import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-allied-health-footer',
  templateUrl: './allied-health-footer.component.html',
  styleUrls: ['./allied-health-footer.component.scss']
})
export class AlliedHealthFooterComponent implements OnInit {

  currentYear = new Date().getFullYear();

  constructor() { }

  ngOnInit() {
  }


}
