import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class EmailService {
  constructor(private http: HttpClient) {}

  public SendEmail(model: any) {
    return this.http.post(`${environment.apiUrl}/email/send`, model);
  }
}
