<section class="has-padding-top-xl has-padding-bottom-xl">
    <div class="container">
        <h2 class="has-text-centered">Our Services</h2>
    </div>
</section>

<div class="">
    <section class="section light__green--bg has-padding-top-none has-padding-bottom-xl">
        <div class="container">
            <br><br>
            <h3>At Health Professionals, we are passionate about delivering exceptional healthcare services.</h3>
            <p>Our dedicated team works tirelessly to provide the highest standard of care, ensuring the well-being of
                patients and supporting healthcare organisations across the UK. Below are the key services we offer:</p>

            <div class="container">
                <div class="columns is-vcentered is-multiline has-margin-top-md">
                    <div class="column is-6">
                        <a href="/complex-care">
                            <div class="white__wrapper">
                                <h4>Complex Care Services</h4>
                                <h6 class="has-padding-bottom-sm"> Our collaboration with HP Homecare allows us to
                                    deliver specialised complex care
                                    services. We ensure that
                                    patients receive high-quality, compassionate care tailored to meet their unique
                                    needs.
                                </h6>
                                <ul class="bullet__list">
                                    <li><strong>Acquired Brain Injury:</strong> Comprehensive care for brain injury
                                        recovery.</li>
                                    <li><strong>End of Life Care:</strong> Compassionate support during end-of-life
                                        stages.</li>
                                    <li><strong>Neurological Conditions:</strong> Specialized care for neurological
                                        disorders.</li>
                                    <li><strong>Palliative Care:</strong> Relief and support for serious illnesses.</li>
                                    <li><strong>Physical Disabilities:</strong> Tailored care for those with physical
                                        impairments.</li>
                                    <li><strong>Spinal Conditions:</strong> Expert care for spinal injuries and
                                        conditions.</li>
                                </ul>
                            </div>
                        </a>
                    </div>
                    <div class="column is-6">
                        <a href="/mind-care">
                            <div class="white__wrapper">
                                <h4>HP Mindcare</h4>
                                <h6 class="has-padding-bottom-sm"> HP Mindcare combines our expertise to deliver
                                    comprehensive mental health services tailored for
                                    organisations. We enhance workplace well-being and productivity through
                                    high-quality, accessible mental
                                    health care solutions.
                                </h6>
                                <ul class="bullet__list">
                                    <li><strong>Employee Assistance Programmes (EAP):</strong> Support for employees to
                                        prevent burnout, improve
                                        productivity, and enhance well-being.</li>
                                    <li><strong>NHS and Healthcare Authority Support:</strong> Alleviating pressures by
                                        providing timely,
                                        high-quality mental health care.</li>
                                    <li><strong>Virtual Therapy Sessions:</strong> Secure, accessible support anytime,
                                        anywhere.</li>
                                    <li><strong>Personalised Matching:</strong> Ensuring effective and personalised
                                        care.</li>
                                </ul>
                            </div>
                        </a>
                    </div>
                    <div class="column is-6">
                        <a href="/contact">
                            <div class="white__wrapper">
                                <h4>Waiting List Initiatives</h4>
                                <h6 class="has-padding-bottom-sm">We understand the challenges healthcare organisations
                                    face with long waiting lists. Our team provides support to reduce waiting times and
                                    ensure patients receive timely care.</h6>
                                <ul class="bullet__list">
                                    <li><strong>Additional Staffing:</strong> Providing qualified nurses and healthcare
                                        professionals to support high-demand periods.</li>
                                    <li><strong>Efficient Processes:</strong> Implementing strategies to streamline
                                        patient flow and reduce backlog.</li>
                                    <li><strong>Quality Care:</strong> Maintaining high standards of care while
                                        addressing waiting list challenges.</li>
                                </ul>
                            </div>
                        </a>
                    </div>

                    <div class="column is-6">
                        <a href="/contact">
                            <div class="white__wrapper">
                                <h4>Nursing Staff Provision</h4>
                                <h6 class="has-padding-bottom-sm">At Health Professionals, we pride ourselves on our
                                    ability to supply top-tier nursing staff to various healthcare settings. Our nurses
                                    are not just any nurses—they are handpicked for their skills, compassion, and
                                    dedication.</h6>
                                <ul class="bullet__list">
                                    <li><strong>General Nursing:</strong> Skilled nurses for hospitals, clinics, and
                                        home care.</li>
                                    <li><strong>Specialist Nursing:</strong> Nurses with expertise in fields such as
                                        ICU, A&E, and paediatrics.</li>
                                    <li><strong>Community Nursing:</strong> Experienced nurses for community and
                                        home-based care.</li>
                                </ul>
                            </div>
                        </a>
                    </div>

                    <div class="column is-6">
                        <a href="/contact">
                            <div class="white__wrapper">
                                <h4>Temporary and Permanent Staffing Solutions</h4>
                                <h6 class="has-padding-bottom-sm">We offer flexible staffing solutions to meet the
                                    diverse needs of healthcare organisations. Whether you need temporary cover or
                                    permanent staff, we have the resources to support you.</h6>
                                <ul class="bullet__list">
                                    <li><strong>Temporary Staffing:</strong> Quick and reliable cover for short-term
                                        needs.</li>
                                    <li><strong>Permanant Recruitment:</strong> Finding the right candidates for
                                        long-term roles.</li>
                                    <li><strong>Bespoke Solutions:</strong> Tailored staffing solutions to match your
                                        specific requirements.</li>
                                </ul>
                            </div>
                        </a>
                    </div>

                    <div class="column is-6">
                        <a href="/contact">
                            <div class="white__wrapper">
                                <h4>Training and Development</h4>
                                <h6 class="has-padding-bottom-sm">We are committed to the ongoing professional
                                    development of our staff. Our training programmes ensure that our nurses and
                                    healthcare professionals are up-to-date with the latest practices and standards.
                                </h6>
                                <ul class="bullet__list">
                                    <li><strong>Continual Professional Development (CPD):</strong> Regular training
                                        sessions to enhance skills and knowledge.</li>
                                    <li><strong>Specialist Training:</strong> Focused training for specialised areas of
                                        care.</li>
                                    <li><strong>Support and Mentorship:</strong> Providing guidance and support to
                                        foster professional growth.</li>
                                </ul>
                            </div>
                        </a>
                    </div>

                </div>

            </div>

            <h3 class="has-padding-top-lg has-padding-bottom-lg">Why Choose Health Professionals?</h3>
            <ul class="bullet__list has-padding-bottom-lg">
                <li><strong>Passion for Care:</strong> Our dedication to providing the best possible care drives
                    everything we do.</li>
                <li><strong>Experienced Team:</strong> Leveraging decades of experience in healthcare staffing.</li>
                <li><strong>Comprehensive Services:</strong> A wide range of services to meet all your healthcare needs.
                </li>
                <li><strong>Flexible Solutions:</strong> Tailored staffing and care solutions to suit your requirements.
                </li>
            </ul>

            <h3>Contact Us</h3>
            <p>For more information on how Health Professionals can assist your organisation, please contact our support
                team. We are here to help you provide the highest standard of care and support.</p>
            <p>At Health Professionals, our passion for nursing and healthcare excellence is at the heart of everything
                we do. We look forward to partnering with you to deliver outstanding care and support.</p>
            <a routerLink="/contact" class="dark__blue--btn centered">Contact</a>

        </div>
    </section>
</div>