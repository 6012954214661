<section class="section">
  <div class="container">
    <h2>To Our Nurses</h2>
    <ul>
      <li><strong>COVID-19 awareness:</strong> Via your Health Professionals MyNurseApp, please review daily the latest NHS advice and interventions, to ensure that your are completely up-to-date at all times.</li>
      <li><strong>Support:</strong> Behind-the-scenes, our Bookings & Accounts Team is working continously to ensure that you receive the highest levels of support to enable you to deliver the best possible service to your patients, and to keep yourselves safe.</li>
      <li><strong>Uniform:</strong> Mindful of Covid-19 infection prevention, all of our nurses can request and additional new Health Professionals uniform, free-of-charge, over the next 3 months.</li>
      <li><strong>Availability:</strong> Please keep your availability up-to-date. This will allow us to ensure that you are booked into the assignments you best like to work within.</li>
      <li><strong>HP Points:</strong> All our nurses benefit from our Points System: you earn points that convert into pay for every hour that you work.</li>
      <li><strong>Contact us:</strong> You can contact us as usual and within your MyNurseApp, can continue to book, cancel and amend your availability. Our sophisticated, automated systems are designed to supply a high number of nurses and ensure accuracy of bookings to availability, and prompt payment to our nurses.</li>
    </ul>

    <h2>To Our NHS Clients</h2>
    <ul>
      <li><strong>Service continuity in crisis:</strong> As a trusted NHS partner, Health Professionals has been providing highly-skilled, fully-screened and compliant nurses to prominent hospitals and clinics in the M40 corridor for nearly 25 years. Significantly, our nurses are supplied in accordance with NHS England and Monitor Framework cap and agreed pricing.</li>
      <li><strong>Systems synergy:</strong> Our specially developed technology can "speak" to your systems such as NHS Professionals Platform and Health Rosters/Allocate, in real-time, in seconds. This synergy enables us to reliably match the right nurse to your assignment need, and our clients benefit from "live" system responsiveness, always backed up by our quick-thinking and accessible Bookings & Accounts Team.</li>
    </ul>
    <p><strong>So, whether you are a currently registered nurse with the NMC, a recently retired nurse, or a hospital or clinic, Health Professionals can rapidly play a part in helping the NHS to fight the Covid-19 pandemic and save lives... telephone our office now on 0207 201 1143</strong></p>
  </div>
</section>
