<section class="section light__green--bg curved has-padding-top-none has-padding-bottom-none">
    <div class="container">
        <h2 class="has-text-centered">HP Training Day</h2>
        <div class="columns has-margin-top-lg is-multiline">
            <div class="column is-6">
                <h4 class="has-text-centered">Specialist Training at Health Professionals</h4>
                <p>At Health Professionals, we're dedicated to the continual professional development of our nursing
                    staff. We
                    understand that the healthcare landscape is constantly evolving, and staying abreast of the latest
                    procedures and techniques is crucial for delivering outstanding patient care. Our commitment to
                    excellence
                    is reflected in our ongoing specialist training programs, designed to keep our nurses at the
                    forefront of
                    their fields.</p>

                <h4 class="has-text-centered">Enhancing Skills with Advanced Training</h4>
                <p>Our specialist training sessions are meticulously crafted to ensure our nurses not only refresh their
                    foundational knowledge but also gain expertise in cutting-edge practices. Take, for instance, our
                    recent
                    complimentary training day on Intravenous (IV) Therapy – a vital skill set for nurses across various
                    specialties.</p>
                <h4 class="has-text-centered">Investing in Our Nurses' Excellence</h4>
                <p>We believe that by investing in the advanced training of our nurses, we're also investing in the
                    quality of
                    care our patients receive. This is why we offer these sessions at no cost to our nursing staff –
                    because
                    excellence in healthcare should be accessible and uncompromised.</p>

            </div>
            <div class="column is-6">
                <div class="team__card has-text-centered">
                    <!-- <h4>Jane K RN</h4> -->
                    <figure class="is-rounded image">
                        <img src="../assets/png/HP_Training_composition_overview_practice.webp" alt="person"
                            class="rounded" />
                    </figure>
                </div>
            </div>
            <div class="column is-6">
                <div class="team__card has-text-centered">
                    <!-- <h4>Jane K RN</h4> -->
                    <figure class="is-rounded image">
                        <img src="../assets/png/HP_Training_composition_practice.webp" alt="person" class="rounded" />
                    </figure>
                    <p>Intense learning!</p>
                </div>
            </div>
            <div class="column is-6">
                <h4 class="has-text-centered">A Glimpse into Our Training: IV Therapy for Nurses</h4>
                <p>Our IV Therapy training day is just one example of how we equip our nurses with the skills necessary
                    to
                    excel. This hands-on session was led by experienced professionals and covered:</p>
                <ul>
                    <li>The latest IV therapy techniques and best practices</li>
                    <li>Understanding and managing complications associated with IV therapy</li>
                    <li>Adapting to the varying needs of patients across different care settings</li>
                </ul>
                <p>The day was structured to encourage interactive learning, with nurses participating in practical
                    workshops
                    and real-life scenario simulations to ensure they can apply their knowledge with confidence.</p>

                <h4 class="has-text-centered">Continuous Learning for Continuous Care</h4>
                <p>At Health Professionals, we don't just stop at one-off training sessions. Our approach to education
                    is
                    continuous, ensuring that our staff is always prepared to meet the ever-changing demands of
                    healthcare
                    delivery with skill and compassion.</p>


            </div>
        </div>
        <h2 class="has-text-centered has-padding-bottom-lg has-padding-top-lg">Join us in our pursuit of nursing excellence.</h2>
        <h4 class="has-text-centered"> At Health Professionals, we're not just keeping pace
            with
            healthcare advancements – we're setting the standards.</h4>
        <a routerLink="/join-us" class="lime__green--btn centered">Register for the upcoming training event...</a>

        <!-- <div class="columns has-margin-top-lg is-multiline">
            <div class="column is-6">
                <div class=" has-text-centered">

                    <img src="../assets/png/training-eating.webp" alt="person" class="rounded" />
                    <p>Eating cake for our break!</p>
                </div>
            </div>
            <div class="column is-6">
                <div class=" has-text-centered">
                    <img src="../assets/png/training-group-photo.webp" alt="person" class="rounded" />
                    <p>Class Photo time!</p>
                </div>
            </div>
            <div class="column is-6">
                <div class="team__card has-text-centered">
                    <figure class="is-rounded image">
                        <img src="../assets/png/training-watching.webp" alt="person" class="rounded" />
                    </figure>
                    <p>Intense learning!</p>
                </div>
            </div>
            <div class="column is-6">

            </div>
        </div> -->
    </div>
</section>
<br><br><br>
<!-- <section class="has-padding-top-none has-padding-bottom-none ">
    <div class="has-padding-top-none has-padding-bottom-xl">
        <div class="container">
            <h2 class="has-text-centered">Join our team so you can register for the next free training event. </h2>
        </div>
    </div>
</section> -->