import { Component, ViewChildren, QueryList, ElementRef } from '@angular/core';

@Component({
  selector: 'app-nurse-journey',
  templateUrl: './nurse-journey.component.html',
  styleUrls: ['./nurse-journey.component.scss']
})
export class NurseJourneyComponent {
  @ViewChildren('detailElements')
  detailElements!: QueryList<ElementRef>;

  isCollapsed: boolean = true; // Track the collapsed state

  expandAll() {
    this.detailElements.forEach((element) => {
      element.nativeElement.open = true;
    });
    this.isCollapsed = false; // Update the collapsed state
  }

  collapseAll() {
    this.detailElements.forEach((element) => {
      element.nativeElement.open = false;
    });
    this.isCollapsed = true; // Update the collapsed state
  }

  constructor() { }
}
