<section class="section has-padding-bottom-none has-padding-top-xl">
    <div class="container">
        <div *ngIf="vacancy != null">
            <h1 class="has-text-centered has-padding-bottom-lg">{{vacancy.cr5b8_title}}</h1>
        </div>
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li>
                    <a href="/">
                        <span class="icon is-small">
                            <i class="fas fa-home" aria-hidden="true"></i>
                        </span>
                        <span>Home</span>
                    </a>
                </li>
                <li>
                    <a href="/jobs">
                        <span class="icon is-small">
                            <i class="fas fa-book" aria-hidden="true"></i>
                        </span>
                        <span>Job List</span>
                    </a>
                </li>
                <li>
                    <a>
                        <span class="icon is-small">
                            <i class="fas fa-puzzle-piece" aria-hidden="true"></i>
                        </span>
                        <span>Job Details</span>
                    </a>
                </li>
            </ul>
        </nav>

        <div *ngIf="vacancy != null">
            <div class="columns has has-padding-top-lg">
                <div class="column is-one-quarter light__green--bg rounded-bg left-section">
                    <h3>{{vacancy.cr5b8_title}}</h3>
                    <br>
                    <ul>
                        <li class="flex-container">
                            <div class="icon-container"><i class="fas fa-heart heart-icon"></i></div>
                            <span class="item-text">{{vacancy.hps_SpecialistAreaid.hps_name}}</span>
                        </li>
                        <li class="flex-container">
                            <div class="icon-container"><i class="fas fa-map-marker-alt location-icon"></i></div>
                            <span class="item-text">{{vacancy.cr5b8_location}}</span>
                        </li>
                        <li class="flex-container">
                            <div class="icon-container"><i class="fas fa-clock clock-icon"></i></div>
                            <span class="item-text">Immediate Start</span>
                        </li>
                        <li class="flex-container">
                            <div class="icon-container"><i class="fas fa-money-bill money-icon"></i></div>
                            <span class="item-text">{{ vacancy.cr5b8_payelements }}</span>
                        </li>
                        <li class="flex-container" *ngIf="vacancy != null">
                            <div class="icon-container"><i class="fas fa-info-circle info-icon"></i></div>
                            <span class="item-text" [ngClass]="isReadMore ? 'expanded' : 'collapsed'">
                                {{ getDisplayDescription(vacancy.cr5b8_shortjobdescription, 250) }}
                            </span>
                        </li>
                        <li *ngIf="vacancy?.cr5b8_shortjobdescription?.length > 250">
                            <button class="button is-outlined" (click)="toggleReadMore4()">
                                {{isReadMore ? 'Read Less' : 'Read More'}}
                            </button>
                        </li>

                    </ul>
                </div>
                <div class="column is-three-quarters full-width-column">
                    <div innerHTML="{{vacancy.hps_hpwebpage}}">
                    </div>
                </div>
            </div>

            <section class="section">
                <div class="container">
                    <nav class="level has-text-centered-mobile">
                        <a [routerLink]="['/join-us']"
                            [queryParams]="{hps_vacancyid: vacancy.hps_vacancyid, cr5b8_title: vacancy.cr5b8_title}"
                            class="dark__blue--btn centered">Apply now</a>
                    </nav>
                </div>
            </section>
            <div class="is-hidden-tablet is-hidden-desktop">
                <div class="contain">
                    <div class="columns has-padding-top-lg">
                        <div class="column is-one-quarter">
                            <img src="../assets/png/nurse-stock.webp" alt="Slider Image" class="img-small" />
                        </div>
                        <div class="column is-three-quarters has-text-centered-mobile">
                            <h3>Contact {{vacancy.cr5b8_consultant}}, {{vacancy.cr5b8_consultantposition}}
                                {{vacancy.cr5b8_consultantsteam}} on <a href="tel:020 7201 1140">020 7201 1140</a>.
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>