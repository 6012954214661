<section class="section">
    <div class="container">
        <div class="columns is-vcentered">
            <div class="column is-5">
                <h1 class="has-text-centered-mobile">Live Shifts available now - Book via <a routerLink="/mynurseapp">MyNurseApp</a></h1>
                <!--<form class="is-block has-margin-top-lg has-margin-bottom-lg" action="" method="get">
          <div class="field">
            <div class="control">
              <input class="input" type="text" placeholder="Search for...">
            </div>
          </div>
          <div class="field ">
            <div class="control">
              <button class="submit__btn" type="submit">Search</button>
            </div>
          </div>
        </form>
        <p class="color__darkblue"><strong class="color__darkblue">294 pages of assignments found!</strong> This just a small sample of our current assignments for nurses. When you work with us, you have full access to all our assignments enabling you to pick and choose where you want to work – and when. </p>-->
            </div>
            <div class="column is-7">
                <img src="../assets/png/ipad-app.webp" alt="Slider Image" />
            </div>
        </div>
    </div>
</section>

<!--<app-live-shifts-grid></app-live-shifts-grid>-->