import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-join-us-old",
  templateUrl: "./join-us-old.component.html",
  styleUrls: ["./join-us-old.component.css"],
})
export class JoinUsOldComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
