<div class="curved">
    <section class="section light__green--bg has-padding-top-none has-padding-bottom-none">
        <div class="container">
            <h2 class="has-text-centered">Open Day: 20th February 2024 in Slough!</h2>
        </div>
    </section>
</div>
<br><br>
<div class="curved">
    <section class="section light__green--bg has-padding-top-none ">
        <div class="container">
            <!-- <div class="columns is-vcentered is-multiline">
                <div class="column is-8 has-padding-top-none">
                    <br><br>
                    <h2>HP Open Day</h2>
                    <p> At Health Professionals, we highly value our dedicated team of nurses. We are always on the
                        lookout for outstanding health professionals who can contribute positively to our mission. To
                        help us with this, we are excited to introduce our "Refer a Friend" scheme. </p>

                    <p>If you know of exceptional individuals in the nursing field who would be a great fit for our
                        team, we'd love to meet them! And to show our appreciation, we are offering up to £500 for each
                        successful referral. </p>

                    <h4>Here's How it Works:</h4>
                    <ul></ul>
                    <li>Refer a Registered Nurse or Specialist Nurse (A&E, ITU, Medical, Surgical, Paediatric, Mental
                        health) to Health Professionals.</li>
                    <li>Once your referral registers with us and works 250 hours, you'll receive a £250 bonus.</li>
                    <li>If your referral works 500 hours with us, you'll receive an additional £250, totalling a £500
                        bonus.</li>


                    <h4>Claiming Your Bonus:</h4>
                    <p>Once your referral has completed 250 hours, please contact our recruitment team. Make sure to do
                        this within three months of your referred friend's registration completion.</p>

                </div>
                <div class="column is-4 has-padding-top-none">
                    <img src="../assets/png/nurses-put-first.webp" />
                </div>
                
            </div> -->
            <iframe class="center-iframe" src="https://docs.google.com/forms/d/e/1FAIpQLSfJy9TrkS4A8-z0jvARo12iNWe_K9OMOkELHHsx7ZzZr4FUXQ/viewform?embedded=true" height="1530" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

        </div>
    </section>
</div>