import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ft-award',
  templateUrl: './ft-award.component.html',
  styleUrls: ['./ft-award.component.scss']
})
export class FtAwardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
