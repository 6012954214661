<!--<div *ngIf="!formSubmitted;then form;"></div>-->
<!--<ng-template #form>
  
</ng-template>-->

<form (ngSubmit)="processForm()" class="has-margin-top-md">
    <!-- FIRST & LAST NAMES -->
    <div class="field is-horizontal">
        <div class="field-body">
            <div class="field">
                <input [(ngModel)]="firstName" class="input" type="text" placeholder="First Name" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="field">
                <input [(ngModel)]="lastName" class="input" type="text" placeholder="Last Name" [ngModelOptions]="{standalone: true}">
            </div>
        </div>
    </div>
    <!-- PHONE & EMAIL -->
    <div class="field is-horizontal">
        <div class="field-body">
            <div class="field">
                <input [(ngModel)]="phone" class="input" type="tel" placeholder="Phone Number" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="field">
                <input [(ngModel)]="email" class="input" type="email" placeholder="Email Address" [ngModelOptions]="{standalone: true}">
            </div>
        </div>
    </div>
    <!-- MESSAGE CONTENT -->
    <div class="field">
        <div class="field-body">
            <div class="field">
                <div class="control">
                    <textarea [(ngModel)]="message" class="textarea" placeholder="Your Message" [ngModelOptions]="{standalone: true}"></textarea>
                </div>
            </div>
        </div>
    </div>
    <!-- CAPTCHA -->
    <!--<div class="g-recaptcha" style="transform: scale(0.77); -webkit-transform: scale(0.77); transform-origin: 0 0; -webkit-transform-origin: 0 0;" data-theme="light">
    <re-captcha (resolved)="resolved($event)" siteKey="6LfaYXcUAAAAAOBGI_d6YSUs2Fb2rVVf7ajE2MiJ"></re-captcha>-->
    <!--</div>-->
    <div class="field">
        <div class="field-body">
            <div class="field">
                <div class="control">
                    <button class="dark__blue--btn">Submit</button>
                </div>
            </div>
        </div>
    </div>
</form>