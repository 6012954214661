import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CrmService } from "src/app/services/crm.service";

@Component({
  selector: "app-job-listings",
  templateUrl: "./job-listings.component.html",
  styleUrls: ["./job-listings.component.css"],
})
export class JobListingsComponent implements OnInit {
  hpsVacancies: any[] = [];
  currentPage = 1;
  totalPages = 0;
  itemsPerPage = 10;
  sectors: string[] = ["Nurse", "HCA"];
  pages: number[] = [];
  createdon: any;
  loading: boolean = true;
  maxPages!: number;
  activeProfession: string | null = null;
  activeJobType: string | null = null; 
  activeSpecialistArea: string | null = null; 
  activeLocation: string | null = null;
  locationOptions: string | null = null; 
  showDropdown = false;
  showSpecialistAreaDropdown = false;
  showLocationDropdown = false;
  showJobTypeDropdown = false;
  activeFilter: { type: string | null, value: string | null } = { type: null, value: null };
  totalFilteredJobs: number = 0; // Add this property to store the total count of filtered jobs
  searchQuery: string = '';

  
  filters = {
    profession: null as string | null,
    jobType: null as string | null,
    specialistArea: null as string | null,
    location: null as string | null
  };
  
  dropdownStates = {
    profession: false,
    jobType: false,
    specialistArea: false,
    location: false
  };
  professions = [
    { name: 'Nurse', value: 'Nurse' },
    { name: 'HCA', value: 'HCA' },
    { name: 'Occupational Therapist', value: 'Occupational Therapist' },
    { name: 'Pharmacist', value: 'Pharmacist' },
    { name: 'Pharmacy Technician', value: 'Pharmacy Technician' },
    
  ]
  jobTypes = [
    { name: 'Nurse Assignment', value: 'isLastMinuteJob' },
    { name: 'Locums Jobs', value: 'doctorJob' },
    { name: 'Allied Health Jobs', value: 'alliedHealthJob' },
    { name: 'Agency Nurse Jobs', value: 'agencyJob' },
  ];
  specialistAreas = [
    { name: 'Neonatal', value: 'Neonatel' },
    { name: 'ITU/HDU/CCU', value: 'ITU/HDU/CCU' },
    { name: 'Surgical Nursing', value: 'Surgical nursing' },
    { name: 'Medical Nursing', value: 'medical nursing' },
    { name: 'A&E', value: 'A & E' },
    { name: 'Diabetes', value: 'Diabetes' },
    { name: 'Stroke', value: 'Stroke' },
    { name: 'Community', value: 'community' },

  ];
  locations = [
    { name: 'Watford', value: 'Watford' },
    { name: 'Berkshire', value: 'Berkshire' },
    { name: 'Kings Lynn and Hinchingbrooke', value: 'Kings Lynn and Hinchingbrooke' },
    { name: 'Guildford', value: 'Guildford' },
    { name: 'Wexham Park Hospital', value: 'Wexham Park Hospital' },
    { name: 'University of Leicester', value: 'University of Leicester' },
    { name: 'Whipps Cross Hospital', value: 'Whipps Cross Hospital' },
    { name: 'Oxford', value: 'Oxford' },
    { name: 'Croydon', value: 'Croydon' },
    { name: 'Haringey', value: 'Haringey' },
    { name: 'Colchester', value: 'Colchester' },
    { name: 'Cambridge', value: 'Cambridge' },
    { name: 'Reading', value: 'Reading' },
    { name: 'Chertsey', value: 'Chertsey' },
    { name: 'Buckinghamshire', value: 'Buckinghamshire' },
    { name: 'Surrey', value: 'Surrey' },
    { name: 'London', value: 'London' },
    { name: 'Slough', value: 'Slough' },
    { name: 'Leicester', value: 'Leicester' },
  ];
  constructor(
    private route: ActivatedRoute,
    private crm: CrmService,
    private router: Router,
  ) {}

  truncateDescription(description: string | null | undefined, limit: number): string {
    if (!description || description.length === 0) {
      return '';
    }
  
    if (description.length <= limit) {
      return description;
    }
  
    const lastSpaceIndex = description.lastIndexOf(" ", limit);
    const truncatedText = description.slice(0, lastSpaceIndex);
  
    return truncatedText + "...";
  }
  
  applySearch() {
    this.currentPage = 1; 
    this.fetchVacancies();
  }
  ngOnInit() {
    this.activeProfession = null;
    this.activeJobType = null;
    this.currentPage = 1;
    this.maxPages = 5;
    this.fetchVacancies();

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {},
    });
  }

  updateUrlWithFilters() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        page: this.currentPage,
        profession: null,
        jobType: null,
      },
      queryParamsHandling: "merge",
    });
  }

  loadPage(page: number, profession: string[] = []) {
    this.crm
      .GetVacancies(
        page,
        this.sectors,
        0,
        0,
        0,
        0,
        this.activeProfession ?? undefined,
        this.activeSpecialistArea ?? undefined,
        this.activeLocation ?? undefined
      )
      .subscribe((res: any) => {
        console.log(res);
        this.hpsVacancies = res.vacancies;
        this.currentPage = res.currentPage;
        this.totalPages = res.totalPages;
        this.loading = false;

        this.pages = [];
        const half = Math.floor(this.maxPages / 2);
        let start = Math.max(page - half, 1);
        let end = Math.min(page + half, this.totalPages);

        if (this.totalPages < this.maxPages) {
          start = 1;
          end = this.totalPages;
        }

        for (let i = start; i <= end; i++) {
          this.pages.push(i);
        }
      });
  }


  toggleDropdown(type: 'profession' | 'jobType' | 'specialistArea' | 'location') {
    this.dropdownStates[type] = !this.dropdownStates[type];
  }

  setFilter(type: 'profession' | 'jobType' | 'specialistArea' | 'location', value: string) {
    if (this.filters[type] === value) {
      this.filters[type] = null;
    } else {
      for (let filter in this.filters) {
        if (filter !== type) {
          this.filters[filter as keyof typeof this.filters] = null;
        }
      }
      this.filters[type] = value;
    }
    
    this.applyFiltersAndUpdateUrl();
  }
  
  
  isFilterActive(type: 'profession' | 'jobType' | 'specialistArea' | 'location', value: string): boolean {
    return this.filters[type] === value;
  }
  resetFilters(updateUrl = true) { 
    this.filters = { profession: null, jobType: null, specialistArea: null, location: null };
    this.dropdownStates = { profession: false, jobType: false, specialistArea: false, location: false };
    if (updateUrl) this.applyFiltersAndUpdateUrl();
  }
  
  
  applyFiltersAndUpdateUrl() {
    this.currentPage = 1;
    this.fetchVacancies();
  
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { ...this.filters, page: this.currentPage }
    });
  }

  fetchVacancies() {
    this.loading = true;
  
    this.crm.GetVacancies(
      this.currentPage,
      this.sectors,
      this.getFilterFlag('isLastMinuteJob'),
      this.getFilterFlag('doctorJob'),
      this.getFilterFlag('alliedHealthJob'),
      this.getFilterFlag('agencyJob'),
      this.filters.profession ?? "",
      this.filters.specialistArea ?? "",
      this.filters.location ?? "",
      this.searchQuery ?? "",
    ).subscribe((res: any) => {
      console.log(res);
      this.handleVacanciesResponse(res);
    });
  }
  getFilterFlag(jobTypeKey: string): number {
    return this.filters.jobType === jobTypeKey ? 1 : 0;
  }
  
  applyFilters() {
    this.fetchVacancies();
  }

  handleVacanciesResponse(res: any) {
    this.hpsVacancies = res.vacancies;
    this.totalPages = res.totalPages;
    this.totalFilteredJobs = res.totalCount;
    this.loading = false;
    this.updatePageNavigation();
  }
  
  updatePageNavigation() {
    this.pages = [];
    const half = Math.floor(this.maxPages / 2);
    let start = Math.max(this.currentPage - half, 1);
    let end = Math.min(this.currentPage + half, this.totalPages);

    if (this.totalPages < this.maxPages) {
      start = 1;
      end = this.totalPages;
    }

    for (let i = start; i <= end; i++) {
      this.pages.push(i);
    }
  }

  goToPage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.fetchVacancies();
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { page: page },
        queryParamsHandling: "merge",
      });
    }
  }
}
