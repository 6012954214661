<owl-carousel-o [options]="customOptionsOne" class="banner__slider" class="has-text-centered">
    <ng-template carouselSlide>
        <div class="container">
            <div class="Slider">
                <h4>Maria C</h4>
                <h5>In service with us {{ calculateYearsOfService(2013) }} years</h5>
                <p>{{ truncateDescription('"Absolutely adore working with the HP team…They have cultivated a feeling of belonging, just like being part of a family..."', 300) }}</p>

                <!-- <div class="item--full"> -->
                <!-- <h3 class="is-hidden-mobile">Test1</h3> -->
                <!-- <h4 class="no-mg-bottom-mobile has-margin-bottom-xl has-margin-top-sm has-text-centered-mobile">What our Health Professionals say</h4>
                    <ul class="button-links centered">
                        <li><a routerLink="/join-us" class="dark__blue--btn">Join Us Today</a></li>
                    </ul> -->
                <!-- </div> -->
            </div>
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="container">
            <div class="Slider">
                <h4>Valentino L</h4>
                <h5>Part of our team for {{ calculateYearsOfService(2019) }} years</h5>
                <p>{{ truncateDescription('My experience working with Health Professionals has been remarkably smooth and responsive...', 300) }}</p>
                <!-- <div class="item--full"> -->
                <!-- <h3 class="is-hidden-mobile">Test2</h3> -->
                <!-- <h1 class="no-mg-bottom-mobile has-margin-bottom-xl has-margin-top-sm has-text-centered-mobile">25
                        years of putting nurses and Trusts 1st.</h1>
                    <ul class="button-links">
                        <li><a routerLink="/join-us" class="dark__blue--btn">Join us</a></li>
                    </ul> -->
                <!-- </div> -->
            </div>
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="container">
            <div class="Slider">
                <h4>Sarah D</h4>
                <h5>In service with us {{ calculateYearsOfService(2015) }} years</h5>
                <p>{{ truncateDescription('I have been working with Health Professionals for a few years now and have always found them to be very professional and understanding. What’s more, I’ve never been out of work!', 300) }}</p>
                <!-- <div class="item--full"> -->
                <!-- <h3 class="is-hidden-mobile">Test3</h3> -->
                <!-- <h1 class="no-mg-bottom-mobile has-margin-bottom-xl has-margin-top-sm has-text-centered-mobile">25
                        years of putting nurses and Trusts 1st.</h1>
                    <ul class="button-links">
                        <li><a routerLink="/join-us" class="dark__blue--btn">Join us</a></li>
                    </ul> -->
                <!-- </div> -->
            </div>
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="container">
            <div class="Slider">
                <h4>Conciliah H (Connie)</h4>
                <h5>Registered with us for {{ calculateYearsOfService(2015) }} years</h5>

                <p>
                    “Working with Health Professionals is a pleasure... MyNurseApp is excellent because I can book or reschedule my shifts anywhere, at any time – at the tap of my phone!"</p>
                <!-- <div class="item--full"> -->
                <!-- <h3 class="is-hidden-mobile">Test4</h3> -->
                <!-- <h1 class="no-mg-bottom-mobile has-margin-bottom-xl has-margin-top-sm has-text-centered-mobile">25
                        years of putting nurses and Trusts 1st.</h1>
                    <ul class="button-links">
                        <li><a routerLink="/join-us" class="dark__blue--btn">Join us</a></li>
                    </ul> -->
                <!-- </div> -->
            </div>
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="container">
            <div class="Slider">
                <h4>Kristian C</h4>
                <h5>With us for {{ calculateYearsOfService(2017) }} years</h5>

                <p>"Choosing to work with Health Professionals has been one of the best decisions I've made in my nursing career..."</p>
                <!-- <div class="item--full"> -->
                <!-- <h3 class="is-hidden-mobile">Test5</h3> -->
                <!-- <h1 class="no-mg-bottom-mobile has-margin-bottom-xl has-margin-top-sm has-text-centered-mobile">25
                        years of putting nurses and Trusts 1st.</h1>
                    <ul class="button-links">
                        <li><a routerLink="/join-us" class="dark__blue--btn">Join us</a></li>
                    </ul> -->
                <!-- </div> -->
            </div>
        </div>
    </ng-template>
</owl-carousel-o>