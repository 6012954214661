import { Component, OnInit } from '@angular/core';

interface Tab {
  [index: number]: boolean;
}

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css']
})
export class TabsComponent implements OnInit {

  tabs: Tab = {
    0: true,
    1: false,
    2: false,
    3: false
  };

  constructor() { }

  ngOnInit() {
  }

  toggleTab(index: string) {
    for (let key in this.tabs) {
      this.tabs[key] = key === index;
    }
  }

}
