<div class="banner__slider">
    <div>
        <div class="container">
            <div class="slider__grid">
                <div class="item__half--one">
                    <h3 class="is-hidden-mobile">About Health Professionals</h3>
                    <h1 class="no-mg-bottom-mobile has-margin-bottom-xl has-margin-top-sm has-text-centered-mobile">We put our health professionals 1st.</h1>
                    <ul class="button-links">
                        <li><a routerLink="/join-us" class="dark__blue--btn">Sign up</a></li>
                    </ul>
                </div>
                <div class="item__half--two">
                    <img src="../assets/png/nurses-put-first.webp" alt="" />
                </div>
            </div>
        </div>
    </div>
</div>

<section class="section">
    <div class="container">
        <p>
            Health Professionals is a specialist nursing agency with 25 years of placing specialist nursing staff in nursing jobs across the UK. We take great pride in the high levels of service, support and training that we provide to our nursing candidates. We
            strive to ensure that they, in turn, are enabled to provide work of the highest standard, whether this is in the commercial, public (NHS and Crown procurement service) or homecare services.
        </p>
        <p>
            Join our small family of experts who get to know each nurse individually. We ensure you get the right shifts. In the right locations. At rates that work for you. Powered by MyNurseApp, you can access shifts from anywhere at any time, giving you maximum
            control over your career and your earnings. We think it's important you get to know and trust us, so here are some of your core team that you'll hear from!

        </p>

    </div>
</section>

<section class="section light__green--bg has-padding-top-lg has-padding-bottom-lg">
    <div class="container">
        <nav class="level">
            <div class="level-left">
                <div class="level-item">
                    <h4 class="has-text-centered-mobile">We are there for you 24/7, 365 days a year</h4>
                </div>
            </div>
            <div class="level-right">
                <a routerLink="/" class="lime__green--btn has-text-centered-mobile is-block-mobile">Sign Up</a>
            </div>
        </nav>
    </div>
</section>

<section class="has-padding-top-lg" style="margin-top: 40px;"> 
    <h2 class="has-text-centered has-padding-bottom-lg">Testimonials</h2>
    <app-testimonials-slider></app-testimonials-slider>
    <a routerLink="/testimonials" class="lime__green--btn centered" style="margin-top: 0;">Find out more...</a>
</section>

<app-meet-the-team></app-meet-the-team>