<section class="section has-padding-bottom-xl">
  <div class="container">
    <div class="columns is-vcentered">
      <div class="column is-6">
        <h3>25 years of service</h3>
        <h1 class="no-mg-bottom-mobile has-margin-bottom-xl has-margin-top-sm has-text-centered-mobile">The history of Health Professionals Nursing Agency</h1>
      </div>
      <div class="column is-6">
        <img src="../assets/png/slide-heart.webp" alt="History Image" />
      </div>
    </div>
  </div>
</section>

<section class="section light__green--bg curved has-padding-top-none has-padding-bottom-none">
  <div class="container">
    <h2 class="has-text-centered">The beginnings</h2>
    <div class="columns has-margin-top-md tiles">
      <div class="column is-6">
        <p>"I have worked for Health Professionals for the last 5 years. This has been the best decision I have made. Professional and patient team. Always willing to listen and take requests on board. Thank you for all your hard work. Nokuthula N RN"</p>
        <ol class="numbered__list">
          <li>simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</li>
        </ol>
      </div>
      <div class="column is-6">
        <p>"I have worked with other Nursing Agencies before but they haven't been as good as HP. Well done HP for keeping standards high..  Lilian K RN"</p>
        <p>"I have worked with other Nursing Agencies before but they haven't been as good as HP. Well done HP for keeping standards high..  Lilian K RN"</p>
      </div>
    </div>
  </div>
</section>

<section class="section has-padding-top-xxl has-padding-bottom-xxl">
  <div class="container">
    <div class="columns is-vcentered">
      <div class="column is-6">
        <h3 class="has-text-weight-semibold">The nurse drought on the late 1990’s</h3>
        <p>
          Health Professional Nursing Agency was established in November 1997 by Hugo Lyons, himself formerly a qualified nurse who had the vision to see an opportunity when the UK faced major shortages of nurses and agencies had to start looking
          abroad in earnest to meet demand.
        </p>
        <p>HP came into an established marketplace with plenty of competition, but it came with fresh perspectives that have birth to an ethos of constant innovation rather than resting on one’s laurels in an established arena. </p>
        <p>
          The first taste of technology came through the use of the internet, which was then very much in its infancy in the UK. It allowed HP to steal a march on the competition by reaching out to nurses across the globe in ways that the established
          agencies had yet fully to wake up to. As a result, HP was able to recruit nurses from many countries across the world including Australia, New Zealand, the US, the Philippines and parts of Europe and Africa.
        </p>
      </div>
      <div class="column is-6">
        <figure class="image">
          <img src="../assets/svg/ipad-egg.webp" alt="" class="is-egg" />
        </figure>
      </div>
    </div>
    <div class="columns is-vcentered">
      <div class="column is-6">
        <figure class="image">
          <img src="../assets/svg/ipad-egg.webp" alt="" class="is-egg" />
        </figure>
      </div>
      <div class="column is-6">
        <h3 class="has-text-weight-semibold">The foundations of the key to our success, the personal approach</h3>
        <p>
          From the outset, it felt right to look after people venturing to the UK from abroad, understanding their need for such things given the perhaps daunting prospect of living in a strange land. This insight led to a whole gamut of things
          that marked HP apart from most established agencies fo the time.
        </p>
        <p>
          So it was that the now Director of Health Professionals Home Care Services, Michele Jogee, herself an experienced registered nurse at the time, was recruited into HP. Her initial role was to build the foundations of the personal touches
          by meeting and greeting all new nurses arriving from abroad for the first time, taking them to their accommodation and answering their concerns to allow them to settle comfortably in the UK. It cannot be overstated how much this provided
          a solid foundation for the ethos of HP that ensured its rapid success.
        </p>
        <p>
          Michele went on to get involved in the whole recruitment process for nurses for the agency before moving into home care some 4 or 5 years after joining us. Though her primary focus in now on heading up HP Home Care Services, she is still
          involved with agency, overseeing things from a clinical point of view and dealing with any customer service issues that arise form time to time involving nurses.
        </p>
      </div>
    </div>
  </div>
</section>

<section class="section light__green--bg curved has-padding-top-none has-padding-bottom-none">
  <div class="container">
    <h3 class="has-text-centered has-text-weight-semibold">Expanding our use of innovative technology</h3>
    <div class="columns has-margin-top-md tiles">
      <div class="column is-6">
        <p>
          Having been founded on the back of the revolution that was the internet in 1997, the company went on to expand first its use of the internet and, later, other technology. This eventually led to the formation of a technology arm of
          HP that not only serves its own nurses and clients, but has been able to provide innovative support within the NHS andother companies in the medical sector.
        </p>
        <p>
          We started developing our own innovative technology in about 2010, building on its early adoption of the internet, and then some 5 or 6 years ago they introduced the first nurse app. Our nurses were absolutely delighted with this development
          as they hadn’t seen anything like it before, being able to see their shifts and book their them on their smartphones.
        </p>
      </div>
      <div class="column is-6">
        <p>
          Since then, our nurse app has been taken to a new level with automatic shift booking requiring little or no human input at all and we are constantly looking to improve on it to the benefit of our nurses and clients. Obviously, our
          priority was always on clinical supervision, but we increasingly found that technology supported all aspects of our operations and allowed us to focus on the most important aspects of our work, especially supervision and safety.
        </p>
        <p>Over the years, the company has never stood still for long when comes to the use of technology. It is no surprise, therefore, that this was and remains key to our success and our high reputation in the marketplace. </p>
      </div>
    </div>
  </div>
</section>

<section class="section has-padding-top-xl">
  <div class="container">
    <div class="columns is-vcentered">
      <div class="column is-6">
        <h3 class="has-text-weight-semibold">Developing the personal touch</h3>
        <p>
          By 2006, our agency had earned an excellent reputation in the marketplace and we began working with major hospitals like Moorfields Eye Hospital and, later on, The Royal Brompton Hospital in London, as well as companies like The British
          Heart Foundation. As our name spread, we gradually worked our way along the M40 corridor, especially in Berkshire (including Reading) and Oxford.
        </p>
      </div>
      <div class="column is-6"><img src="../assets/svg/ipad-egg.webp" alt="" class="is-egg" /></div>
    </div>
  </div>
</section>

<section class="section light__green--bg curved has-padding-top-none has-padding-bottom-none">
  <div class="container">
    <h3 class="has-text-centered has-text-weight-semibold">Expanding our use of innovative technology</h3>
    <div class="columns has-margin-top-md tiles">
      <div class="column is-6">
        <p>
          Having been founded on the back of the revolution that was the internet in 1997, the company went on to expand first its use of the internet and, later, other technology. This eventually led to the formation of a technology arm of
          HP that not only serves its own nurses and clients, but has been able to provide innovative support within the NHS andother companies in the medical sector.
        </p>
        <p>
          We started developing our own innovative technology in about 2010, building on its early adoption of the internet, and then some 5 or 6 years ago they introduced the first nurse app. Our nurses were absolutely delighted with this development
          as they hadn’t seen anything like it before, being able to see their shifts and book their them on their smartphones.
        </p>
      </div>
      <div class="column is-6">
        <p>
          Since then, our nurse app has been taken to a new level with automatic shift booking requiring little or no human input at all and we are constantly looking to improve on it to the benefit of our nurses and clients. Obviously, our
          priority was always on clinical supervision, but we increasingly found that technology supported all aspects of our operations and allowed us to focus on the most important aspects of our work, especially supervision and safety.
        </p>
        <p>Over the years, the company has never stood still for long when comes to the use of technology. It is no surprise, therefore, that this was and remains key to our success and our high reputation in the marketplace. </p>
      </div>
    </div>
  </div>
</section>

<section class="section has-padding-top-xl">
  <div class="container">
    <div class="columns is-vcentered">
      <div class="column is-6">
        <h3 class="has-text-weight-semibold">The professional fundamentals of the agency</h3>
        <p>
          Our history has turned out to focus on these two core distinguishing features of our agency, but naturally, the core aspects of running a nursing agency. Obviously, HP has therefore always been part of Framework Agreements with NHS
          hospitals over the years and we always ensure we comply with all relevant legislation, passing all audits in these areas as appropriate.
        </p>
      </div>
      <div class="column is-6"><img src="../assets/svg/ipad-egg.webp" alt="" class="is-egg" /></div>
    </div>
  </div>
</section>

<section class="section light__green--bg curved__top has-padding-top-none has-padding-bottom-xl">
  <div class="container">
    <div class="columns has-margin-top-md tiles">
      <div class="column is-6">
        <h3 class="has-text-weight-semibold">Our focus and reach in 2021</h3>
        <p>
          In 2021, though we have extended our reach even further across the globe, the current demands of changed global realities in the light of the Covid-19 pandemic have meant that our focus is now on helping nurses already working in the UK
          that want a better experience of working with an agency to handle their work schedule and key places of work. We do consider applications from nurses from overseas, but nurses must be in position to gain full registration with the
          NMC and be eligible for a visa that allows you to work without restrictions.
        </p>
        <p>As always, we aim to be flexible within the latest factual and professional demands whilst maintaining our core values and key distinguishing attributes in all that we do. </p>
      </div>
      <div class="column is-6">
        <h3 class="has-text-weight-semibold">Looking forward</h3>
        <p>
          The fast pace of change in the world these days means that it’s anyone’s guess about where HP will go in the years ahead. However, since HP was founded all those years ago, we have already expanded our operations beyond the nursing
          agency and will always maintain our key strengths, namely, our personal touch with our nurses and our use of technology in all areas of our business.
        </p>
        <p>
          Within the nursing agency, no doubt new generations of nurses (and clients, as a result) will benefit from the current drive for development of both technology and our services across the board. In particular, instead of being drip-fed
          new technology over time, our new nurse recruits will come into a whole new experience in that regard with the launch of our new system. We look forward to seeing where the next leg of our long journey will take us and our nurses!
        </p>
      </div>
    </div>
  </div>
</section>
