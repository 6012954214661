import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./pages-main/home/home.component";
import { AboutComponent } from "./pages-main/about/about.component";
import { PrivacyComponent } from "./pages-main/policies/privacy/privacy.component";
import { TermsComponent } from "./pages-main/policies/terms/terms.component";
import { FaqComponent } from "./pages-main/faq/faq.component";
import { JobItemComponent } from "./pages-main/job-item/job-item.component";
import { LiveShiftsComponent } from "./pages-main/live-shifts/live-shifts.component";
import { LiveShiftItemComponent } from "./pages-main/live-shift-item/live-shift-item.component";
import { NurseOfTheMonthComponent } from "./pages-main/nurse-of-the-month/nurse-of-the-month.component";
import { JoinUsOldComponent } from "./pages-main/join-us-old/join-us-old.component";
import { ContactUsComponent } from "./pages-main/contact-us/contact-us.component";
import { JoinUsComponent } from "./pages-main/join-us/join-us.component";
import { TestimonialsComponent } from "./components-main/testimonials/testimonials.component";
import { PointsComponent } from "./pages-main/points/points.component";
import { MynurseAppComponent } from "./pages-main/mynurseapp/mynurseapp.component";
import { ForHospitalsTrustsComponent } from "./pages-main/for-hospitals-trusts/for-hospitals-trusts.component";
import { Covid19UpdateComponent } from "./pages-main/covid19-update/covid19-update.component";
import { PayComponent } from "./pages-main/pay/pay.component";
import { HistoryComponent } from "./pages-main/history/history.component";
import { JobListingsComponent } from "./components-main/job-listings/job-listings.component";
import { JobViewComponent } from "./pages-main/job-view/job-view.component";
import { ModernSlaveryComponent } from "./pages-main/policies/modern-slavery/modern-slavery.component";
import { HealthJobFinderComponent } from "./pages-main/health-job-finder/health-job-finder.component";
import { CareersComponent } from "./pages-main/careers/careers.component";
import { NurseJourneyComponent } from "./pages-main/nurse-journey/nurse-journey.component";
import { AmbassadorComponent } from "./pages-main/ambassador/ambassador.component";
import { OpenDayComponent } from "./pages-main/open-day/open-day.component";
import { CarbonReductionPlanComponent } from "./pages-main/carbon-reduction-plan/carbon-reduction-plan.component";
import { TrainingEventComponent } from "./pages-main/training-event/training-event.component";
import { FrimleyHealthComponent } from "./pages-main/for-hospitals-trusts/case-studies/frimley-health/frimley-health.component";
import { CaseStudiesComponent } from "./pages-main/for-hospitals-trusts/case-studies/case-studies.component";
import { ClientPortalComponent } from "./pages-main/mynurseapp/client-portal/client-portal.component";
import { AshfordStpetersComponent } from "./pages-main/for-hospitals-trusts/case-studies/ashford-stpeters/ashford-stpeters.component";
import { OxfordUniversityHospitalComponent } from "./pages-main/for-hospitals-trusts/case-studies/oxford-university-hospital/oxford-university-hospital.component";
import { BuckinghamshireHealthComponent } from "./pages-main/for-hospitals-trusts/case-studies/buckinghamshire-health/buckinghamshire-health.component";
import { RoyalBerkshireComponent } from "./pages-main/for-hospitals-trusts/case-studies/royal-berkshire/royal-berkshire.component";
import { FtAwardComponent } from "./pages-main/ft-award/ft-award.component";
import { QAComponent } from "./pages-main/qa/qa.component";
import { ReferAFriendComponent } from "./pages-main/refer-a-friend/refer-a-friend.component";
import { JobRegionComponent } from "./pages-main/job-region/job-region.component";
import { SkillsInformationComponent } from "./pages-main/skills-information/skills-information.component";
import { JobListingLastMinuteComponent } from "./pages-main/job-listing-last-minute/job-listing-last-minute.component";

// Doctor Pages
import { DoctorHomeComponent } from "./pages-doctor/doctor-home/doctor-home.component";
import { DoctorAboutComponent } from "./pages-doctor/doctor-about/doctor-about.component";
import { DoctorMymedappComponent } from "./pages-doctor/doctor-mymedapp/doctor-mymedapp.component";
import { DoctorJourneyComponent } from "./pages-doctor/doctor-journey/doctor-journey.component";
import { DoctorJoinUsComponent } from "./pages-doctor/doctor-join-us/doctor-join-us.component";
import { DoctorsJobListingComponent } from "./pages-doctor/doctors-job-listing/doctors-job-listing.component";
import { DoctorJobListingLastMinuteComponent } from "./pages-doctor/doctor-job-listing-last-minute/doctor-job-listing-last-minute.component";
import { DoctorContactUsComponent } from "./pages-doctor/doctor-contact-us/doctor-contact-us.component";
import { AlliedHealthJobListingComponent } from "./pages-allied-health/allied-health-job-listing/allied-health-job-listing.component";
import { AgencyHomeComponent } from "./pages-main/agency-home/agency-home.component";
import { AlliedHealthJoinUsComponent } from "./pages-allied-health/allied-health-join-us/allied-health-join-us.component";
import { AlliedHealthOrganisationsComponent } from "./pages-allied-health/allied-health-organisations/allied-health-organisations.component";
import { TechnologyComponent } from "./pages-main/technology/technology.component";
import { AlliedHealthHomeComponent } from "./pages-allied-health/allied-health-home/allied-health-home.component";
import { DoctorsForHospitalsAndTrustsComponent } from "./pages-doctor/doctors-for-hospitals-and-trusts/doctors-for-hospitals-and-trusts.component";
import { GeneralJoinUsComponent } from "./pages-main/general-join-us/general-join-us.component";
import { DoctorsJobViewsComponent } from "./components-doctor/doctors-job-views/doctors-job-views.component";
import { AlliedHealthJobViewsComponent } from "./components-allied-health/allied-health-job-views/allied-health-job-views.component";
import { AgencyNurseJobListingComponent } from "./pages-agency-nurse/agency-nurse-job-listing/agency-nurse-job-listing.component";
import { AgencyNurseAssignmentComponent } from "./pages-agency-nurse/agency-nurse-assignment/agency-nurse-assignment.component";
import { AgencyNurseJoinUsComponent } from "./pages-agency-nurse/agency-nurse-join-us/agency-nurse-join-us.component";
import { JobListingComponent } from "./pages-main/job-listing/job-listing.component";
import { AgencyNurseJobViewComponent } from "./pages-agency-nurse/agency-nurse-job-view/agency-nurse-job-view.component";
import { MindCareComponent } from "./pages-main/mind-care/mind-care.component";
import { ComplexCareComponent } from "./pages-main/complex-care/complex-care.component";
import { ServicesComponent } from "./pages-main/services/services.component";

const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
    pathMatch: "full",
    data: {
      breadcrumb: {
        label: `Health Professionals`,
        description: `Agency for nursing jobs and nursing assignments`,
      },
    },
  },
  {
    path: "join-us",
    component: GeneralJoinUsComponent,
    data: {
      breadcrumb: {
        label: `Join Us`,
        description: `Agency for nursing jobs and nursing assignments`,
      },
    },
  },
  {
    path: "mind-care",
    component: MindCareComponent,
    data: {
      breadcrumb: {
        label: `Mind Care`,
        description: `Mental Healthcare`,
      },
    },
  },
  {
    path: "complex-care",
    component: ComplexCareComponent,
    data: {
      breadcrumb: {
        label: `Complex Care`,
        description: `Mental Healthcare`,
      },
    },
  },
  {
    path: "services",
    component: ServicesComponent,
    data: {
      breadcrumb: {
        label: `Services`,
        description: `Services`,
      },
    },
  },
  {
    path: "agency-nurses",
    component: AgencyHomeComponent,
    data: {
      breadcrumb: {
        label: `Agency Nurses`,
        description: `Agency for nursing jobs and nursing assignments`,
      },
    },
  },
  {
    path: "allied-health-join-us",
    component: AlliedHealthJoinUsComponent,
    data: {
      breadcrumb: {
        label: `Allied Health Join Us`,
        description: `Agency for nursing jobs and nursing assignments`,
      },
    },
  },
  {
    path: "allied-health-organisations",
    component: AlliedHealthOrganisationsComponent,
    data: {
      breadcrumb: {
        label: `Allied Health Organisations`,
        description: `Agency for nursing jobs and nursing assignments`,
      },
    },
  },
  {
    path: "allied-health",
    component: AlliedHealthHomeComponent,
    data: {
      breadcrumb: {
        label: `AHP HSS`,
        description: `Agency for nursing jobs and nursing assignments`,
      },
    },

  },
  {
    path: "technology",
    component: TechnologyComponent,
    data: {
      breadcrumb: {
        label: `Technology`,
        description: `Agency for nursing jobs and nursing assignments`,
      },
    },
  },
  {
    path: "about",
    component: AboutComponent,
    data: {
      breadcrumb: {
        label: `About`,
        description: `Health Professionals is a specialist nursing agency with 25 years of placing specialist nursing staff in nursing jobs across the UK. We are there for you 24/7, 365 days a year`,
      },
    },
  },

  {
    path: "privacy",
    component: PrivacyComponent,
    data: {
      breadcrumb: {
        label: `Privacy Policy`,
        description: `Data controller for client, nurse, and carer personal data.`,
      },
    },
  },
  {
    path: "modern-slavery",
    component: ModernSlaveryComponent,
    data: {
      breadcrumb: {
        label: `Modern Slavery`,
        description: `Committed to combating modern slavery.`,
      },
    },
  },
  {
    path: "terms",
    component: TermsComponent,
    data: {
      breadcrumb: {
        label: `Terms & Conditions`,
        description: `Usage terms for our health website.`,
      },
    },
  },
  {
    path: "faq",
    component: FaqComponent,
    data: {
      breadcrumb: {
        label: `FAQs`,
        description: `Answers to common healthcare queries.`,
      },
    },
  },
  {
    path: "jobs",
    component: JobListingsComponent,
    data: {
      breadcrumb: {
        label: `Jobs`,
        description: `Your gateway to nursing careers.`,
      },
    },
  },
  {
    path: "jobs/:id",
    component: JobListingComponent,
    data: {
      breadcrumb: {
        label: `Job Listing`,
        description: `Your gateway to nursing careers.`,
      },
    },
  },
  {
    path: "agency-nurse-job-listing",
    component: AgencyNurseJobListingComponent,
    data: {
      breadcrumb: {
        label: `Agency Nurse Job Listing`,
        description: `Latest healthcare job opportunities.`,
      },
    },
  },
  {
    path: "agency-nurse-assignment",
    component: AgencyNurseAssignmentComponent,
    data: {
      breadcrumb: {
        label: `agency-nurse-assignment`,
        description: `Last-minute healthcare roles.`,
      },
    },
  },
  {
    path: "job-item",
    component: JobItemComponent,
    data: {
      breadcrumb: {
        label: `[JobTitle]`,
        description: `Your gateway to nursing careers.`,
      },
    },
  },
  {
    path: "job-view/:id",
    component: JobViewComponent,
    data: {
      breadcrumb: {
        label: `Job View`,
        description: `In-depth look at nursing jobs.`,
      },
    },
  },
  {
    path: "agency-nurse-job-view/:id",
    component: AgencyNurseJobViewComponent,
    data: {
      breadcrumb: {
        label: `Agency Nurse Job View`,
        description: `In-depth look at nursing jobs.`,
      },
    },
  },
  {
    path: "doctors-job-view/:id",
    component: DoctorsJobViewsComponent,
    data: {
      breadcrumb: {
        label: `Job Details`,
        description: `In-depth look at nursing jobs.`,
      },
    },
  },
  {
    path: "allied-health-job-view/:id",
    component: AlliedHealthJobViewsComponent,
    data: {
      breadcrumb: {
        label: `Job Details`,
        description: `In-depth look at nursing jobs.`,
      },
    },
  },
  {
    path: "live-shifts",
    component: LiveShiftsComponent,
    data: {
      breadcrumb: {
        label: `Live Shifts`,
        description: `Real-time nursing shift updates.`,
      },
    },
  },
  {
    path: "live-shift-item",
    component: LiveShiftItemComponent,
    data: {
      breadcrumb: {
        label: `Live Shift`,
        description: `Details of current nursing shifts.`,
      },
    },
  },
  {
    path: "nurse-of-month",
    component: NurseOfTheMonthComponent,
    data: {
      breadcrumb: {
        label: `Nurse of the Month`,
      },
    },
  },
  {
    path: "agency-nurse-join-us",
    component: AgencyNurseJoinUsComponent,
    data: {
      breadcrumb: {
        label: `Agency Nurse Join Us`,
        description: `Agency for nursing jobs and nursing assignments`,
      },
    },
  },
  {
    path: "contact-us",
    component: ContactUsComponent,
    data: {
      breadcrumb: {
        label: `Contact Us`,
        description: `Looking to work as a full time agency nurse? Join an agency that will put you 1st. Contact our team on 020 7201 1140 any time, whatever your query... we'd love to help`,
      },
    },
  },
  {
    path: "ambassadors",
    component: AmbassadorComponent,
    data: {
      breadcrumb: {
        label: `Ambassadors`,
        description: `Looking to work as a full time agency nurse? Join an agency that will put you 1st. Contact our team on 020 7201 1140 any time, whatever your query... we'd love to help`,
      },
    },
  },
  {
    path: "open-day",
    component: OpenDayComponent,
    data: {
      breadcrumb: {
        label: `Open-Day`,
        description: `Looking to work as a full time agency nurse? Join an agency that will put you 1st. Contact our team on 020 7201 1140 any time, whatever your query... we'd love to help`,
      },
    },
  },
  // {
  //   path: "join-us",
  //   component: SignUpComponent,
  //   data: {
  //     breadcrumb: {
  //       label: `Join us`,
  //       description: `We're not just any agency, you're not just any nurse, join us today.`,
  //     },
  //   },
  // },
  {
    path: "blog",
    component: JoinUsComponent,
    data: {
      breadcrumb: {
        label: `Blog`,
        description: `Agency for nursing jobs and nursing assignments`,
      },
    },
  },
  {
    path: "testimonials",
    component: TestimonialsComponent,
    data: {
      breadcrumb: {
        label: `Testimonials`,
        description: `What our Nurses say about us. I have worked for Health Professionals for the last 5 years. This has been the best decision I have made. Professional and patient team. `,
      },
    },
  },
  {
    path: "points-system",
    component: PointsComponent,
    data: {
      breadcrumb: {
        label: `Points System`,
        description: `Agency for nursing jobs and nursing assignments`,
      },
    },
  },
  {
    path: "locations",
    component: JoinUsComponent,
    data: {
      breadcrumb: {
        label: `Locations`,
      },
    },
  },
  {
    path: "mynurseapp",
    component: MynurseAppComponent,
    data: {
      breadcrumb: {
        label: `MyNurseApp`,
        description: `Industry leading software supports your career. We've developed MyNurseApp especially for you, our Health Professionals' Registered Nurses.`,
      },
    },
  },
  {
    path: "mynurseapp/client-portal",
    component: ClientPortalComponent,
    data: {
      breadcrumb: {
        label: `Client Portal`,
        description: `Industry leading software supports your career. We've developed MyNurseApp especially for you, our Health Professionals' Registered Nurses.`,
      },
    },
  },

  {
    path: "for-hospitals-trusts",
    component: ForHospitalsTrustsComponent,
    data: {
      breadcrumb: {
        label: `For Hospital & Trusts`,
        description: `We're not just any nursing agency. Our Nurses are not just any nurses. Let us make a difference to your organisation - call today 0207 201 1143`,
      },
    },
  },
  {
    path: "for-hospitals-trusts/case-studies",
    component: CaseStudiesComponent,
    data: {
      breadcrumb: {
        label: `Case Studies`,
        description: `case studies`,
      },
    },
  },
  {
    path: "for-hospitals-trusts/case-studies/frimley-health",
    component: FrimleyHealthComponent,
    data: {
      breadcrumb: {
        label: `Frimley-Health`,
        description: `frimley-health`,
      },
    },
  },
  {
    path: "for-hospitals-trusts/case-studies/ashford-st-peters",
    component: AshfordStpetersComponent,
    data: {
      breadcrumb: {
        label: `Ashford St Peters`,
        description: `Ashford St Peters`,
      },
    },
  },
  {
    path: "for-hospitals-trusts/case-studies/oxford-university-hospital",
    component: OxfordUniversityHospitalComponent,
    data: {
      breadcrumb: {
        label: `Oxford University Hospital`,
        description: `Oxford University Hospital`,
      },
    },
  },
  {
    path: "for-hospitals-trusts/case-studies/buckinghampshire-health",
    component: BuckinghamshireHealthComponent,
    data: {
      breadcrumb: {
        label: `Buckinghamshire Health`,
        description: `Buckinghamshire Health`,
      },
    },
  },
  {
    path: "for-hospitals-trusts/case-studies/royal-berkshire",
    component: RoyalBerkshireComponent,
    data: {
      breadcrumb: {
        label: `Royal Berkshire`,
        description: `Royal Berkshire`,
      },
    },
  },
  {
    path: "health-job-finder",
    component: HealthJobFinderComponent,
    data: {
      breadcrumb: {
        label: `Health Job Finder`,
        description: `The UK's Leading Specialist Recruitment Agency for Travel Nurses, Full-Time Positions, and Occupational Health Nurses`,
      },
    },
  },
  {
    path: "covid-19-update",
    component: Covid19UpdateComponent,
    data: {
      breadcrumb: {
        label: `Covid 19 Update`,
        description:
          "To Our Nurses. COVID-19 awareness: Via your Health Professionals MyNurseApp, please review daily the latest NHS advice and interventions, to ensure that your are completely up-to-date at all times.",
      },
    },
  },
  {
    path: 'award-winning-approach',
    component: FtAwardComponent,
    data: {
      breadcrumb: {
        label: `Award Winning Approach`,
        description: `Award Winning Approach`,
      },
    },
  },
  {
    path: "pay",
    component: PayComponent,
    data: {
      breadcrumb: {
        label: `Pay`,
        description: `Agency for nursing jobs and nursing assignments`,
      },
    },
  },

  {
    path: "history",
    component: HistoryComponent,
    data: {
      breadcrumb: {
        label: `History`,
      },
    },
  },
  {
    path: "careers",
    component: CareersComponent,
    data: {
      breadcrumb: {
        label: `Be part of a great team`,
      },
    },
  },
  {
    path: "Q&A",
    component: QAComponent,
    data: {
      breadcrumb: {
        label: `Question and Answers`,
      },
    },
  },
  {
    path: "refer-a-friend",
    component: ReferAFriendComponent,
    data: {
      breadcrumb: {
        label: `Refer Your friends for a big bonus!`,
      },
    },
  },
  {
    path: "regions",
    component: JobRegionComponent,
    data: {
      breadcrumb: {
        label: `Job Regions`,
      },
    },
  },
  {
    path: "skills",
    component: SkillsInformationComponent,
    data: {
      breadcrumb: {
        label: `Skills Information`,
      },
    },
  },
  {
    path: "nurse-journey",
    component: NurseJourneyComponent,
    data: {
      breadcrumb: {
        label: `Nurse Journey`,
      },
    },
  },
  {
    path: "carbon-reduction-plan",
    component: CarbonReductionPlanComponent,
    data: {
      breadcrumb: {
        label: `Carbon Reduction Plan`,
      },
    },
  },
  {
    path: "training",
    component: TrainingEventComponent,
    data: {
      breadcrumb: {
        label: `Training`,
        description: `nurse training event`,
      },
    },
  },
  {
    path: "doctors-for-hospitals-trusts",
    component: DoctorsForHospitalsAndTrustsComponent,
    data: {
      breadcrumb: {
        label: `Doctors for Hospitals & Trusts`,
        description: `Agency for Doctors and Locums`,
      },
    },

  },
  {
    path: "doctors-about",
    component: DoctorAboutComponent,
    data: {
      breadcrumb: {
        label: `About`,
        description: `Health Professionals is a specialist nursing agency with 25 years of placing specialist nursing staff in nursing jobs across the UK. We are there for you 24/7, 365 days a year`,
      },
    },
  },
  {
    path: "doctors-contact-us",
    component: DoctorContactUsComponent,
    data: {
      breadcrumb: {
        label: `Doctor-contact-us`,
        description: `Agency for Doctors and Locums`,
      },
    },
  },
  {
    path: "doctors-job-listing",
    component: DoctorsJobListingComponent,
    data: {
      breadcrumb: {
        label: `Doctors-Job-Listing`,
        description: `Agency for Doctors and Locums`,
      },
    },
  },
  {
    path: "doctors-job-listing-last-minute",
    component: DoctorJobListingLastMinuteComponent,
    data: {
      breadcrumb: {
        label: `Doctor-Job-Listing-Last-Minute`,
        description: `Agency for Doctors and Locums`,
      },
    },
  },
  {
    path: "doctors",
    component: DoctorHomeComponent,
    data: {
      breadcrumb: {
        label: `Doctor`,
        description: `Agency for Doctors and Locums`,
      },
    },
  },
  {
    path: "doctors-journey",
    component: DoctorJourneyComponent,
    data: {
      breadcrumb: {
        label: `Doctor-Journey`,
        description: `Agency for Doctors and Locums`,
      },
    },
  },
  {
    path: "doctors-join-us",
    component: DoctorJoinUsComponent,
    data: {
      breadcrumb: {
        label: `Doctor-Join-Us`,
        description: `Agency for Doctors and Locums`,
      },
    },
  },
  {
    path: "doctors-mymedapp",
    component: DoctorMymedappComponent,
    data: {
      breadcrumb: {
        label: `Mynurseapp`,
        description: `Industry leading software supports your career. We've developed MyNurseApp especially for you, our Health Professionals' Registered Nurses.`,
      },
    },
  },
  {
    path: "allied-health-jobs",
    component: AlliedHealthJobListingComponent,
    data: {
      breadcrumb: {
        label: `Allied Health Jobs`,
        description: `Agency for Doctors and Locums`,
      },
    },

  },
  {
    path: "404",
    loadChildren: () =>
      import("./modules/not-found/not-found.module").then(
        (m) => m.NotFoundModule
      ),
    data: {
      breadcrumb: {
        label: `404 Page Not Found`,
        description: `The content you requested could not be found`,
      },
    },
  },
  {
    path: "not-found",
    loadChildren: () =>
      import("./modules/not-found/not-found.module").then(
        (m) => m.NotFoundModule
      ),
    data: {
      breadcrumb: {
        label: `404 Page Not Found`,
        description: `The content you requested could not be found`,
      },
    },
  },
  {
    path: "**",
    redirectTo: "not-found",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      initialNavigation: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
