import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { CrmService } from "src/app/services/crm.service";

@Component({
  selector: 'app-doctors-for-hospitals-and-trusts',
  templateUrl: './doctors-for-hospitals-and-trusts.component.html',
  styleUrls: ['./doctors-for-hospitals-and-trusts.component.scss']
})
export class DoctorsForHospitalsAndTrustsComponent implements OnInit {
  formGroup: FormGroup;
  captchaSolved: boolean;
  formSubmitted: boolean;
  formSuccess: boolean;
  formFailure: boolean;
  isLoading: boolean = false;

  constructor(private crmService: CrmService, private route: ActivatedRoute) {
    this.formGroup = new FormGroup({
      firstName: new FormControl("", [Validators.required]),
      lastName: new FormControl("", [Validators.required]),
      phone: new FormControl("", [
        Validators.required,
        Validators.minLength(9),
        Validators.maxLength(15),
      ]),
      nmcPin: new FormControl("", [
        Validators.pattern("[0-9]{2}[A-Z][0-9]{4}[A-Z]"),
      ]),
      email: new FormControl("", [Validators.required, Validators.email]),
      nhsExperience6Months: new FormControl("", [Validators.required]),
      lookingFor: new FormControl(""),
      rightToWork: new FormControl("", [Validators.required]),
      enhancedRates: new FormControl("", [Validators.required]),
      specialistArea: new FormControl("", [Validators.required]),
      workTravelDistance: new FormControl("", [Validators.required]),
      postCode: new FormControl("", [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(9),
      ]),
      callingConsent: new FormControl("", [Validators.required]),
      hps_vacancyid: new FormControl(""),
      cr5b8_title: new FormControl(""),
      dob: new FormControl(new Date(), [Validators.required]),
    });

    this.captchaSolved = false;
    this.formSubmitted = false;
    this.formSuccess = false;
    this.formFailure = false;
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.formGroup.patchValue({
        hps_vacancyid: params["hps_vacancyid"],
        cr5b8_title: params["cr5b8_title"],
      });
    });
  }

  isImageVisible = false;

  toggleImage() {
    this.isImageVisible = !this.isImageVisible;
  }

  hideImage() {
    this.isImageVisible = false;
  }

  get f(): any {
    return this.formGroup.controls;
  }

  formSubmission() {
    console.log(this.formGroup.controls);
    this.formSubmitted = true;
    this.isLoading = true; // Start loading

    if (this.formGroup.invalid) {
      this.isLoading = false; // Stop loading if form is invalid
      return;
    }

    // Solve captcha
    if (true) {
      var leadDTO: any = {
        firstName: this.formGroup.value.firstName,
        lastName: this.formGroup.value.lastName,
        mobile: this.formGroup.value.phone,
        email: this.formGroup.value.email,
        postCode: this.formGroup.value.postCode,
        subject: "Doctors Hospital/Trust Application",
        nmcPin: this.formGroup.value.nmcPin,
        dob: this.formGroup.value.dob,
        description: JSON.stringify({
          nhsExperience6Months: this.formGroup.value.nhsExperience6Months,
          rightToWork: this.formGroup.value.rightToWork,
          enhancedRates: this.formGroup.value.enhancedRates,
          workTravelDistance: this.formGroup.value.workTravelDistance,
          specialistArea: this.formGroup.value.specialistArea,
          postcode: this.formGroup.value.postCode,
          lookingFor: this.formGroup.value.lookingFor,
          callingConsent: this.formGroup.value.callingConsent,
          hps_vacancyid: this.formGroup.value.hps_vacancyid,
          cr5b8_title: this.formGroup.value.cr5b8_title,
        }),
      };

      this.crmService.CreateLead(leadDTO).subscribe({
        next: (res: any) => {
          this.formSuccess = true;
          this.formFailure = false;
          this.isLoading = false; // Stop loading on success
        },
        error: (err: any) => {
          this.formFailure = true;
          this.isLoading = false; // Stop loading on error
          console.error("Error while submitting form:", err);
        },
      });
    } else {
      alert("Please solve captcha");
    }
  }
  processForm(): void {
    this.formSubmission();
  }

  resolved(captchaResponse: string) {
    this.captchaSolved = true;
  }
}
