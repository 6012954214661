<div class="curved">
    <section class="section has-padding-top-none light__green--bg">
        <div class="container">
            <div class="columns is-multiline" *ngFor="let liveShift of liveShifts">
                <div class="column is-6 is-flex">
                    <div class="job__listing has-padding-lg">
                        <h5 class="color__darkblue">
                            NHS Trust has around 6,000 highly-trained staff caring for more than half a million patients from Buckinghamshire and neighbouring counties every year.
                        </h5>
                        <hr />
                        <ul>
                            <li class="is-flex is-align-items-center has-margin-bottom-sm"><img src="../assets/svg/why-choose-2.svg" alt="" />Late Shift</li>
                            <li class="is-flex is-align-items-center has-margin-bottom-sm"><img src="../assets/svg/why-choose-2.svg" alt="" />Renal Transplant Ward</li>
                            <li class="is-flex is-align-items-center has-margin-bottom-sm"><img src="../assets/svg/why-choose-2.svg" alt="" />General RN 00</li>
                            <li class="is-flex is-align-items-center has-margin-bottom-sm"><img src="../assets/svg/why-choose-2.svg" alt="" />3 hours 20 minutes</li>
                        </ul>
                        <a routerLink="/" class="lime__green--btn has-margin-top-lg is-block">View full assignment</a>
                    </div>
                </div>
                <div class="column is-6 is-flex">
                    <div class="job__listing has-padding-lg">
                        <h5 class="color__darkblue">
                            NHS Trust has around 6,000 highly-trained staff caring for more than half a million patients from Buckinghamshire and neighbouring counties every year.
                        </h5>
                        <hr />
                        <ul>
                            <li class="is-flex is-align-items-center has-margin-bottom-sm"><img src="../assets/svg/why-choose-2.svg" alt="" />Late Shift</li>
                            <li class="is-flex is-align-items-center has-margin-bottom-sm"><img src="../assets/svg/why-choose-2.svg" alt="" />Renal Transplant Ward</li>
                            <li class="is-flex is-align-items-center has-margin-bottom-sm"><img src="../assets/svg/why-choose-2.svg" alt="" />General RN 00</li>
                            <li class="is-flex is-align-items-center has-margin-bottom-sm"><img src="../assets/svg/why-choose-2.svg" alt="" />3 hours 20 minutes</li>
                        </ul>
                        <a routerLink="/" class="lime__green--btn has-margin-top-lg is-block">View full assignment</a>
                    </div>
                </div>
                <div class="column is-6 is-flex">
                    <div class="job__listing has-padding-lg">
                        <h5 class="color__darkblue">
                            NHS Trust has around 6,000 highly-trained staff caring for more than half a million patients from Buckinghamshire and neighbouring counties every year.
                        </h5>
                        <hr />
                        <ul>
                            <li class="is-flex is-align-items-center has-margin-bottom-sm"><img src="../assets/svg/why-choose-2.svg" alt="" />Late Shift</li>
                            <li class="is-flex is-align-items-center has-margin-bottom-sm"><img src="../assets/svg/why-choose-2.svg" alt="" />Renal Transplant Ward</li>
                            <li class="is-flex is-align-items-center has-margin-bottom-sm"><img src="../assets/svg/why-choose-2.svg" alt="" />General RN 00</li>
                            <li class="is-flex is-align-items-center has-margin-bottom-sm"><img src="../assets/svg/why-choose-2.svg" alt="" />3 hours 20 minutes</li>
                        </ul>
                        <a routerLink="/" class="lime__green--btn has-margin-top-lg is-block">View full assignment</a>
                    </div>
                </div>
                <div class="column is-6 is-flex">
                    <div class="job__listing has-padding-lg">
                        <h5 class="color__darkblue">
                            NHS Trust has around 6,000 highly-trained staff caring for more than half a million patients from Buckinghamshire and neighbouring counties every year.
                        </h5>
                        <hr />
                        <ul>
                            <li class="is-flex is-align-items-center has-margin-bottom-sm"><img src="../assets/svg/why-choose-2.svg" alt="" />Late Shift</li>
                            <li class="is-flex is-align-items-center has-margin-bottom-sm"><img src="../assets/svg/why-choose-2.svg" alt="" />Renal Transplant Ward</li>
                            <li class="is-flex is-align-items-center has-margin-bottom-sm"><img src="../assets/svg/why-choose-2.svg" alt="" />General RN 00</li>
                            <li class="is-flex is-align-items-center has-margin-bottom-sm"><img src="../assets/svg/why-choose-2.svg" alt="" />3 hours 20 minutes</li>
                        </ul>
                        <a routerLink="/" class="lime__green--btn has-margin-top-lg is-block">View full assignment</a>
                    </div>
                </div>
            </div>
            <nav class="pagination is-centered has-margin-top-xl" role="navigation" aria-label="pagination">
                <a class="pagination-previous"></a>
                <a class="pagination-next"></a>
                <ul class="pagination-list">
                    <li><a class="pagination-link is-current" aria-label="Goto page 1">1</a></li>
                    <li><a class="pagination-link" aria-label="Goto page 2">2</a></li>
                    <li><a class="pagination-link" aria-label="Goto page 2">3</a></li>
                </ul>
            </nav>

        </div>
    </section>
</div>