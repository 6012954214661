import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-doctor-about',
  templateUrl: './doctor-about.component.html',
  styleUrls: ['./doctor-about.component.css']
})
export class DoctorAboutComponent {

  constructor(private router: Router) { }


}
