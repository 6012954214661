import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agency-nurse-job-view',
  templateUrl: './agency-nurse-job-view.component.html',
  styleUrls: ['./agency-nurse-job-view.component.scss']
})
export class AgencyNurseJobViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
