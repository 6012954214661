import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-doctor-contact-us',
  templateUrl: './doctor-contact-us.component.html',
  styleUrls: ['./doctor-contact-us.component.scss']
})
export class DoctorContactUsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
