import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CrmService } from "src/app/services/crm.service";

@Component({
  selector: "app-allied-health-job-listings",
  templateUrl: "./allied-health-job-listings.component.html",
  styleUrls: ["./allied-health-job-listings.component.scss"],
})
export class AlliedHealthJobListingsComponent implements OnInit {
  hpsVacancies: any[] = [];
  currentPage = 1;
  totalPages = 0;
  itemsPerPage = 10;
  sectors: string[] = ["Nurse", "HCA"];
  pages: number[] = [];
  createdon: any;
  loading: boolean = true;
  maxPages!: number;

  constructor(private route: ActivatedRoute, private crm: CrmService) {}

  truncateDescription(description: string | null | undefined, limit: number): string {
    if (!description || description.length === 0) {
      return '';
    }
  
    if (description.length <= limit) {
      return description;
    }
  
    const lastSpaceIndex = description.lastIndexOf(" ", limit);
    const truncatedText = description.slice(0, lastSpaceIndex);
  
    return truncatedText + "...";
  }

  ngOnInit() {
    var page = Number(this.route.snapshot.queryParams["page"] ?? 1);
    console.log("page: ", page);
    this.currentPage = page;
    this.maxPages = 5; // Or set it based on some dynamic condition
    this.loadPage(page);
  }

  loadPage(page: number) {
    this.crm
      .GetVacancies(page, this.sectors, 0, 0, 1, 0)
      .subscribe((res: any) => {
        console.log(res);
        this.hpsVacancies = res.vacancies;
        this.currentPage = res.currentPage;
        this.totalPages = res.totalPages;
        this.loading = false;

        // generate pages array
        this.pages = [];
        const half = Math.floor(this.maxPages / 2);
        let start = Math.max(page - half, 1); // Ensure start is not less than 1
        let end = Math.min(page + half, this.totalPages); // Ensure end does not exceed totalPages

        if (this.totalPages < this.maxPages) {
          // If there are fewer pages than maxPages
          start = 1;
          end = this.totalPages;
        }

        for (let i = start; i <= end; i++) {
          this.pages.push(i);
        }
      });
  }
  goToPage(page: number): void {
    if (page >= 1 && page <= this.totalPages && this.hpsVacancies.length > 0) {
      this.currentPage = page;
      this.crm
        .GetVacancies(this.currentPage, this.sectors, 0, 0, 1, 0)
        .subscribe((res: any) => {
          this.hpsVacancies = res.vacancies;
          this.totalPages = res.totalPages;
          this.loadPage(this.currentPage); // refresh the pages array
        });
    }
  }
}
