<section class="section">
    <div class="container">
        <h2 class="has-text-centered">Q&A: Client Satisfaction Insights
            <br>
            Ashford & St Peter’s Hospitals NHS Trust
        </h2>
    </div>
</section>
<section class="curved section light__green--bg has-padding-top-none has-padding-bottom-lg">
    <div class="container">
        <h4 class="has-text-centered has-padding-bottom-lg">We recently interviewed Emma Hill, Temporary Staffing Team Leader, to see how working with Health Professionals makes a positive difference to how she fulfils her role, and in turn, serves her patients. </h4>
        
        <div class="" *ngFor="let item of items">
            <article class="" (click)="item.show = !item.show">
                <div class="message-header">
                    <p>{{ item.question }}</p>
                    <i [ngClass]="{'bi-plus': !item.show, 'bi-dash': item.show, 'rotate': item.show}" class="bi"></i>
                </div>
                <div [@openClose]="item.show ? 'open' : 'closed'" class="message-body">
                    {{ item.answer }}
                </div>
            </article>
        </div>
    </div>
</section>