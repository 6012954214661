<div class="banner__slider">
    <div>
        <div class="container">
            <div class="slider__grid">
                <div class="item__half--one">
                    <h3 class="is-hidden-mobile">About Health Professionals</h3>
                    <h1 class="no-mg-bottom-mobile has-margin-bottom-xl has-margin-top-sm has-text-centered-mobile">
                        Our Doctors are at the Heart of Healthcare Excellence</h1>

                </div>
                <div class="item__half--two">
                    <img src="../assets/png/nurses-put-first.webp" alt="" />
                </div>
            </div>
        </div>
    </div>
</div>

<section class="section light__green--bg has-padding-top-none curved">
    <div class="container">
        <h3>Let us make a difference to your organisation - call today <a href="tel:020 7201 1140">020 7201 1140</a>
        </h3><br />

        <p>
            <b>Unparalleled Compliance in Locums Staffing:</b> Celebrating over 25 years of expertise, we're not just
            another recruitment agency; we're your partners in healthcare excellence.
            <a class="clickable-phrase" (click)="toggleImage()">
                Our recent audit by PricewaterhouseCoopers (PwC) affirmed our impeccable compliance, marking us with a
                100% perfect score in the provision of nurses.
            </a>. This recognition reflects our unwavering dedication and seamless integration with NHS and private
            sectors across the central UK region, from Surrey to Leicester.
        </p>
        <div class="overlay" [ngClass]="{'show': isImageVisible}" (click)="hideImage()">
            <div class="overlay-content" (click)="$event.stopPropagation()">
                <img src="../assets/png/pwc-certification.webp" alt="certification">
            </div>
        </div>
        <div class="columns is-vcentered is-multiline-desktop" style="margin-bottom: -3rem; margin-top: -3rem;">
            <div class="column is-9">
                <p><b>Your Trusted Staffing Partner:</b> As a preferred partner in many NHS hospitals, we're renowned
                    for our cost-effective and efficient staffing solutions. Whether it's a specialist nurse for a
                    hospital, clinic, industry, or home care through our sister company <a
                        href="https://www.hphomecare.co.uk">HP Homecare</a>, we're here to help. </p>
            </div>
            <div class="column is-3 has-padding-top-none is-hidden-mobile">
                <img src="../assets/png/hphomecare-logo-agency.webp" alt="homecare agency">
            </div>
        </div>
        <p><b>Tech-Forward, Value-Driven:</b> Our commitment to technological innovation enhances our value, offering
            competitive and fair fee structures. Our state-of-the-art tech, paired with human expertise, ensures an
            immediate, perfect match for your urgent staffing needs. Experience our exceptional service by calling <a
                href="tel:020 7201 1140">020 7201 1140.</a> </p>
        <p><b>Health Professionals:</b> We are more than a nursing agency; we are a beacon of healthcare excellence,
            your partner in advancing the standards of care and compliance. </p>

    </div>
</section>

<section class="has-padding-top-lg has-padding-bottom-lg">
    <div class="container">
        <h2 class="has-text-centered">Testimonial</h2>
        <!-- <hr class="short__hr" /> -->
        <i>
            <h4 class="has-text-centered has-margin-top-md">“Whenever I have spoken to members of the team on the phone,
                I’ve always found them polite and helpful, and have built a rapport with them all. In our line of work I
                feel it is so important to have this type of relationship and I am happy to say that I have this with
                the Health Professionals’ team.” </h4>
        </i>
        <b>
            <h4 class="has-text-centered has-margin-top-md">Emma Hill, Temporary Staffing Team Leader, Ashford & St
                Peter’s Hospitals NHS Trust </h4>
        </b>
    </div>
    <div class="level-item">
        <a routerLink="/Q&A" class="lime__green--btn has-text-centered-mobile is-block-mobile">Read more</a>
    </div>
</section>


<div class="curved__top">
    <section class="section light__green--bg has-padding-top-none">
        <div class="container" *ngIf="formSubmitted && formFailure">
            <h2 class="has-text-centered">We were unable to process your form submission</h2>
        </div>
        <div class="container" [hidden]="formSubmitted && formSuccess">
            <h2 class="has-text-centered">Contact</h2>
            <div class="white__wrapper has-margin-top-lg">
                <form [formGroup]="formGroup" (submit)="processForm()" class="has-margin-top-md">
                    <div class="field is-horizontal has-padding-bottom-md">
                        <div class="field-label is-normal is-flex is-align-items-center">
                            <label class="label">Name</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <input formControlName="firstName" class="input" type="text" placeholder="First name"
                                    [ngClass]="formSubmitted && f.firstName.errors ? 'is-invalid' : ''">
                            </div>
                            <div class="field">
                                <input formControlName="lastName" class="input" type="text" placeholder="Last name"
                                    [ngClass]="formSubmitted && f.lastName.errors ? 'is-invalid' : ''">
                            </div>
                        </div>
                    </div>

                    <div class="field is-horizontal has-padding-bottom-md">
                        <div class="field-label is-normal is-flex is-align-items-center">
                            <label class="label">Phone</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <input formControlName="phone" class="input" type="tel"
                                    [ngClass]="formSubmitted && f.phone.errors ? 'is-invalid' : ''">
                            </div>
                        </div>
                    </div>

                    <div class="field is-horizontal has-padding-bottom-md">
                        <div class="field-label is-normal is-flex is-align-items-center">
                            <label class="label">Email</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <input formControlName="email" class="input" type="email"
                                    [ngClass]="formSubmitted && f.email.errors ? 'is-invalid' : ''">
                            </div>
                        </div>
                    </div>
                    <div class="field is-horizontal has-padding-bottom-md">
                        <div class="field-label is-normal is-flex is-align-items-center">
                            <label class="label">Your Questions</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <textarea style="height:200px; max-width: none;" formControlName="questions"
                                    class="input" rows="3" cols="5" placeholder="Expand with right corner." type="text"
                                    [ngClass]="formSubmitted && f.questions.errors ? 'is-invalid' : ''">
                </textarea>

                            </div>
                        </div>
                    </div>

                    <hr />

                    <div class="field is-horizontal has-padding-bottom-md is-justify-content-space-between">
                        <div class="is-normal is-flex has-padding-bottom-md is-align-items-center">
                            <label class="label">Do you consent to us calling you?</label>
                        </div>
                        <div class="field">
                            <div class="control">
                                <div class="containered custom-radio">
                                    <label class="radio">
                                        <input class="has-margin-right-sm" type="radio" value="Yes"
                                            formControlName="callingConsent"
                                            [ngClass]="formSubmitted && f.callingConsent.errors ? 'is-invalid' : ''">
                                        <a class="my-button-one">Yes</a>
                                    </label>
                                    <label class="radio">
                                        <input class="has-margin-right-sm" type="radio" value="No"
                                            formControlName="callingConsent"
                                            [ngClass]="formSubmitted && f.callingConsent.errors ? 'is-invalid' : ''">
                                        <a class="my-button">No</a>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- CAPTCHA -->
                    <!-- <div class="g-recaptcha" style="transform: scale(0.77); -webkit-transform: scale(0.77); transform-origin: 0 0; -webkit-transform-origin: 0 0;" data-theme="light">
                        <re-captcha (resolved)="resolved($event)" siteKey="6LfaYXcUAAAAAOBGI_d6YSUs2Fb2rVVf7ajE2MiJ"></re-captcha>
                    </div> -->
                    <div class="submit-or-loading">
                        <button *ngIf="!isLoading" class="dark__blue--btn has-margin-top-lg centered" type="submit">
                            Submit Form
                        </button>

                        <div *ngIf="isLoading" class="spinner-container">
                            <div class="spinner"></div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="container is-flex is-justify-content-center is-flex-direction-column"
            *ngIf="formSubmitted && formSuccess && !isLoading">
            <h2 class="has-text-centered m-auto">Thank You for submitting our form. Our recruitment team will reach out to you as soon possible.</h2>
        
        </div>
        <div class="container is-flex is-justify-content-center is-flex-direction-column"
            *ngIf="formSubmitted && formFailure && !isLoading">
            <h2 class="has-text-centered">We were unable to process your form submission</h2>
        </div>
    </section>
</div>