<section class="section">
    <div class="container">
        <h2 class="has-text-centered">You have selected an assignment on <strong class="color__darkblue">28 July
        2021</strong></h2>
    </div>
</section>

<div class="curved">
    <section class="section has-padding-top-none has-padding-bottom-none light__green--bg">
        <div class="container">
            <h2 class="has-text-centered">At-a-glance</h2>
            <div class="column white__wrapper has-margin-top-lg">
                <div class="columns">
                    <div class="column is-6 is-12-mobile">
                        <hr />
                    </div>
                    <div class="column is-6 is-hidden-mobile">
                        <hr />
                    </div>
                </div>
                <ul class="list__columns">
                    <li class="has-text-weight-bold color__darkblue has-margin-bottom-sm"><img src="../assets/svg/why-choose-2.svg" alt="" />Late Shift </li>
                    <li class="has-text-weight-bold color__darkblue has-margin-bottom-sm"><img src="../assets/svg/why-choose-2.svg" alt="" />Ward <span>- Renal Transplant Ward</span></li>
                    <li class="has-text-weight-bold color__darkblue has-margin-bottom-sm"><img src="../assets/svg/why-choose-2.svg" alt="" />Hospital</li>
                    <li class="has-text-weight-bold color__darkblue has-margin-bottom-sm"><img src="../assets/svg/why-choose-2.svg" alt="" />Band/Grade</li>
                    <li class="has-text-weight-bold color__darkblue has-margin-bottom-sm"><img src="../assets/svg/why-choose-2.svg" alt="" />Start Time - <a routerLink="/">Login for details</a></li>
                    <li class="has-text-weight-bold color__darkblue has-margin-bottom-sm"><img src="../assets/svg/why-choose-2.svg" alt="" />Start Time - <a routerLink="/">Login for details</a></li>
                </ul>
            </div>
        </div>
    </section>
</div>

<section class="section">
    <div class="container green__wrapper">
        <div class="columns">
            <div class="column is-6">
                <div class="has-margin-bottom-lg">
                    <h4>What?</h4>
                    <p><strong>Late shifts for an RN with previous NHS experience</strong></p>
                    <p>
                        The grade for this particular position is UNKNOWN, carefully selected at this level from the outset to ensure that you only work within your scope of experience.
                    </p>
                    <p class="color__lime">
                        <strong class="color__lime"> Health Pro Tip:</strong> Remember that once you are registered as a Health Professionals Nurse, you will have the flexibility to choose the type of working pattern that best suits you, via NurseApp
                        on your Smart-phone!
                    </p>
                </div>
                <div class="has-margin-bottom-lg">
                    <h4>Where?</h4>
                    <p><strong>Late shifts for an RN with previous NHS experience</strong></p>
                    <p>
                        The grade for this particular position is UNKNOWN, carefully selected at this level from the outset to ensure that you only work within your scope of experience.
                    </p>
                </div>
                <div class="has-margin-bottom-lg">
                    <h4>When?</h4>
                    <p><strong>Late shifts for an RN with previous NHS experience</strong></p>
                    <p>
                        The grade for this particular position is UNKNOWN, carefully selected at this level from the outset to ensure that you only work within your scope of experience.
                    </p>
                </div>
            </div>
            <div class="column is-6">
                <div class="has-margin-bottom-lg">
                    <h4>How?</h4>
                    <p><strong>Late shifts for an RN with previous NHS experience</strong></p>
                    <p>
                        The grade for this particular position is UNKNOWN, carefully selected at this level from the outset to ensure that you only work within your scope of experience.
                    </p>
                </div>
                <div class="has-margin-bottom-lg">
                    <h4>Why?</h4>
                    <p><strong>Late shifts for an RN with previous NHS experience</strong></p>
                    <p>
                        The grade for this particular position is UNKNOWN, carefully selected at this level from the outset to ensure that you only work within your scope of experience.
                    </p>
                </div>
                <hr />
                <div class="has-margin-bottom-lg">
                    <h4>How?</h4>
                    <p><strong>Late shifts for an RN with previous NHS experience</strong></p>
                    <p>
                        The grade for this particular position is UNKNOWN, carefully selected at this level from the outset to ensure that you only work within your scope of experience.
                    </p>
                </div>
            </div>
        </div>
        <hr />
        <a routerLink="/" class="dark__blue--btn mt-6 mb-5 centered">Sign up</a>
    </div>
</section>