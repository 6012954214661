<section class="section">
    <div class="container">
        <h1 class="has-text-centered-mobile">Theatre Nurse – Surrey</h1>
        <ul class="dotted__left--list has-text-centered-mobile">
            <li>Temp</li>
            <li>£15.66 £32.80</li>
        </ul>
    </div>
</section>

<div class="curved">
    <section class="section light__green--bg has-padding-top-none has-padding-bottom-none">
        <div class="container">
            <div class="tab__wrapper">
                <div class="tabs">
                    <ul>
                        <li class="tab__button active" data-id="tab-1">Job Description</li>
                        <li class="tab__button" data-id="tab-2">Pay Scale</li>
                        <li class="tab__button" data-id="tab-3">Contact</li>
                        <li class="tab__button" data-id="tab-4">Location</li>
                    </ul>
                </div>
                <div class="tab__contents">
                    <div class="tab__content active" id="tab-1">
                        <h4>Theatre Nurse - Surrey</h4>
                        <h2>Job Description</h2>
                        <p>Fantastic new project for a team of Theatre Nurses in Surrey</p>
                        <p>Health Professionals have been given the opportunity to supply a team of Nurses to run two Theatres which are soon to be re-opening for our NHS client in Surrey. We require the following for this special project:
                        </p>
                        <ul class="line__bullet--list">
                            <li>2 x ODP’s – 1 for each theatre (weekdays 8 am-6 pm)</li>
                            <li>3 x scrub nurses (weekdays 8am-6pm)</li>
                            <li>2 x band 5’s,</li>
                            <li>1 x band 3</li>
                            <li>1 x recovery nurse (weekdays 8 am-8 pm)</li>
                        </ul>
                        <p>
                            Shifts times across the week will equate to 10 sessions.
                        </p>
                        <p>
                            This project will be starting in summer 2021 until the end of March 2022.
                        </p>
                        <p>
                            *** Rates are negotiable.
                        </p>
                        <p>
                            Only trained individuals need to apply, please.
                        </p>
                        <p>
                            If you are interested in any of these posts please apply via Indeed now. These roles are being snapped up quickly, please apply to become a part of this fantastic opportunity.
                        </p>
                        <p>
                            If you have any questions regarding the roles please do call our team on 0207 201 1140 and they would be happy to answer your questions.
                        </p>
                        <p>
                            Contract length: 9 months
                        </p>
                    </div>
                    <p class="tab__content" id="tab-2">
                        About ipsum dolor sit amet consectetur adipisicing elit. Corporis maxime itaque veritatis iste soluta placeat obcaecati laudantium repellat corrupti! Eius sunt rerum inventore magnam? Perspiciatis facere error suscipit quisquam quibusdam. Lorem ipsum,
                        dolor sit amet consectetur adipisicing elit. Totam, corporis voluptatem quo dignissimos eius quis perferendis vero culpa reiciendis nulla quisquam fugit minima sed molestiae excepturi beatae repudiandae ea? Aliquid! Lorem ipsum
                        dolor sit amet, consectetur adipisicing elit. Enim sapiente officia vel consequuntur, hic at quis? Illo repellendus dolores totam facilis sunt sequi qui hic, nulla ratione harum porro perspiciatis.
                    </p>
                    <p class="tab__content" id="tab-3">
                        Contact ipsum dolor sit amet consectetur adipisicing elit. Dignissimos sit incidunt nostrum? Magni, quam vero, magnam odio similique ipsam minima et repellat rerum cupiditate totam in repudiandae. Sed, dicta corrupti? Lorem ipsum dolor sit amet consectetur
                        adipisicing elit. Asperiores, dolore quas quis earum incidunt voluptas! Ducimus quod libero aliquid consequatur et modi porro officia, quibusdam quas commodi placeat maxime qui? Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Ab ea debitis eligendi accusamus deleniti maxime pariatur. Assumenda, facere placeat eius quam magni accusantium aut quae minima iure atque incidunt illum.
                    </p>
                </div>
            </div>

            <a routerLink="/" class="dark__blue--btn mt-6 mb-5 centered">Sign up</a>
        </div>
    </section>
</div>