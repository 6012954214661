<section class="section">
    <div class="container">
        <h1>Carbon Reduction Plan</h1>
        <p>Comprehensive Presentation</p>
        <h4>Supplier Name: Health Professionals Recruitment Services Ltd</h4>
        <p><strong>Publication Date:</strong> 4th December 2023</p>
        <h4>Introduction:</h4>
        <p>Health Professionals Recruitment Services Ltd is steadfast in its commitment to reducing its carbon footprint
            and
            promoting sustainability within its operations. This comprehensive Carbon Reduction Plan provides a
            strategic
            roadmap to minimise emissions associated with home offices and travel over the next 5 years.</p>

        <h4>Baseline Emissions Footprint:</h4>
        <p>Baseline Year: 2023</p>

        <h4>Baseline Year Emissions:</h4>
        <table>
            <tr>
                <th>EMISSIONS TOTAL (tCO2e)</th>
                <th>Scope 1 (Home Office Emissions)</th>
                <th>Scope 2 (Travel Emissions)</th>
                <th>Scope 3 (Included Sources)</th>
                <th>Total Emissions</th>
            </tr>
            <tr>
                <td>2023</td>
                <td>1.75 tCO2e</td>
                <td>6.06 tCO2e</td>
                <td>XX tCO2e</td>
                <td>7.81 tCO2e</td>
            </tr>
        </table>

        <h4>1. Home Office Emissions Reduction:</h4>
        <p><strong>Objective:</strong> Significantly reduce emissions associated with home offices.</p>
        <p><strong>Initiatives:</strong></p>
        <ul>
            <li>Conduct energy audits for home offices.</li>
            <li>Promote energy-efficient practices.</li>
            <li>Encourage energy-efficient appliances.</li>
        </ul>
        <p><strong>Timeline:</strong> Rollout by 5th December 2023.</p>
        <p><strong>Targets:</strong> 15% reduction by 5th December 2024.</p>

        <h4>2. Travel Emissions (Nurses) Reduction:</h4>
        <p><strong>Objective:</strong> Minimise carbon emissions related to nurse travel.</p>
        <p><strong>Initiatives:</strong></p>
        <ul>
            <li>Encourage sustainable commuting.</li>
            <li>Incentivise eco-friendly commuting.</li>
            <li>Implement remote work policies.</li>
        </ul>
        <p><strong>Timeline:</strong> Launch by 5th December 2023.</p>
        <p><strong>Targets:</strong> 20% reduction by 5th December 2024.</p>

        <h4>3. Monitoring and Reporting:</h4>
        <p><strong>Objective:</strong> Establish robust monitoring and reporting system.</p>
        <p><strong>Initiatives:</strong></p>
        <ul>
            <li>Implement emission monitoring tools.</li>
            <li>Compile annual emission reports.</li>
        </ul>
        <p><strong>Timeline:</strong> Implement by 5th December 2023.</p>
        <p><strong>Targets:</strong> 10% overall reduction by 5th December 2025.</p>

        <h4>4. Employee Engagement and Training:</h4>
        <p><strong>Objective:</strong> Engage employees in carbon reduction efforts.</p>
        <p><strong>Initiatives:</strong></p>
        <ul>
            <li>Conduct training on energy efficiency and sustainable commuting.</li>
            <li>Recognise and reward eco-friendly practices.</li>
        </ul>
        <p><strong>Timeline:</strong> Launch by 5th December 2023.</p>
        <p><strong>Targets:</strong> 25% increase in engagement by 5th December 2024.</p>

        <h4>5. Continuous Improvement:</h4>
        <p><strong>Objective:</strong> Continuously enhance carbon reduction initiatives.</p>
        <p><strong>Initiatives:</strong></p>
        <ul>
            <li>Conduct regular initiative assessments.</li>
            <li>Explore new technologies for emission reduction.</li>
        </ul>
        <p><strong>Timeline:</strong> Start assessments from 5th December 2024.</p>
        <p><strong>Targets:</strong> 30% efficiency increase by 5th December 2025.</p>

        <h4>Conclusion:</h4>
        <p>This plan presents an ambitious approach to address home office and travel emissions. Health Professionals
            Recruitment Services Ltd is committed to actively pursuing these initiatives, focusing on continuous
            assessment
            and improvement to achieve sustainable results.</p>

        <h4>Current Emissions Reporting:</h4>
        <p>Reporting Year: 2023</p>
        <table>
            <tr>
                <th>EMISSIONS TOTAL (tCO2e)</th>
                <th>Scope 1</th>
                <th>Scope 2</th>
                <th>Scope 3 (Included Sources)</th>
                <th>Total Emissions</th>
            </tr>
            <tr>
                <td>2023</td>
                <td>1.75 tCO2e</td>
                <td>6.06 tCO2e</td>
                <td>XX tCO2e</td>
                <td>7.81 tCO2e</td>
            </tr>
        </table>

        <h4>Declaration and Sign Off:</h4>
        <p>This Carbon Reduction Plan adheres to PPN 06/21 standards, SECR requirements, and the Corporate Value Chain
            (Scope 3) Standard. It has been reviewed and signed off by the board of directors.</p>
        <p>Signed on behalf of the Supplier: [Authorised Signature]</p>
        <p>Date: 4th December 2023</p>
    </div>
</section>