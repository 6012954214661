<!-- <header>
  <nav
    class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow mb-3"
  >
    <div class="container">
      <a class="navbar-brand" [routerLink]="['/']">healthprofessionals</a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target=".navbar-collapse"
        aria-label="Toggle navigation"
        [attr.aria-expanded]="isExpanded"
        (click)="toggle()"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse"
        [ngClass]="{ show: isExpanded }"
      >
        <ul class="navbar-nav flex-grow">
          <li
            class="nav-item"
            [routerLinkActive]="['link-active']"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <a class="nav-link text-dark" [routerLink]="['/']">Home</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']">
            <a class="nav-link text-dark" [routerLink]="['/counter']"
              >Counter</a
            >
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']">
            <a class="nav-link text-dark" [routerLink]="['/fetch-data']"
              >Fetch data</a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
-->

<header>

  <nav class="navigation slope curved__bottom" role="navigation" aria-label="Main Navigation"
    [ngClass]="{ slope: !menuActive }">

    <div class="container">

      <div class="columns">

        <div class="column has-padding-bottom-none has-padding-top-none">

          <div class="nav__wrapper slope__reverse" [ngClass]="{ slope__reverse: !menuActive }">

            <a routerLink="/" class="logo">
              <img src="../../assets/svg/logo.svg" alt="logo">
            </a>

            <button id="navbar__toggle" aria-label="Open menu" (click)="toggleMenu()"
              [ngClass]="{ active: menuActive }">
              <span></span>
              MENU
            </button>

            <div class="mobile__overlay" [ngClass]="{ active: menuActive }">
              <div class="tagline__wrap">
                <span>Call us <a href="tel:020 7201 1140">020 7201 1140</a></span>
                <ul class="social__icons is-hidden-mobile">
                  <!-- <li>
                    <a routerLink="/"><img src="../../assets/png/google-plus.webp"></a>
                  </li> -->
                  <li>
                    <a href="https://www.facebook.com/HealthProNurses"><img src="../../assets/png/facebook.webp"
                        alt="logo"></a>
                  </li>
                  <li>
                    <a href="https://twitter.com/healthpronurse"><img src="../../assets/png/twitter.webp"
                        alt="logo"></a>
                  </li>
                  <!--<li>
                    <a href="https://twitter.com/healthpronurse"><img src="../../assets/png/whatsapp-normal.png"></a>
                  </li>-->
                </ul>
              </div>

              <div class="same--line">
                <ul class="nav-links" id="main__nav" [ngClass]="{ active: menuActive }">
                  <li class="navbar-item has-dropdown"
                    [ngClass]="{'is-hoverable': isHoverable, 'is-active': isDropdownActive('jobs')}">
                    <a href="/job-search" (click)="toggleDropdown($event, 'jobs'); $event.preventDefault()">
                      Job Search
                      <span class="icon-container">
                        <span class="is-hidden-mobile"
                          [ngClass]="{'icon-down': !isDropdownActive('jobs'), 'icon-up': isDropdownActive('jobs')}"></span>
                        <span class="is-hidden-tablet"
                          [ngClass]="{'icon-down': !isDropdownActive('jobs'), 'icon-up': isDropdownActive('jobs')}"></span>
                      </span>
                    </a>
                    <div class="navbar-dropdown is-boxed" *ngIf="isDropdownActive('jobs') || isHoverable"
                      [ngClass]="{'show': isDropdownActive('jobs')}">
                      <a class="navbar-item" routerLink="/jobs" (click)="toggleMenu()">
                        <i class="fa fa-user" aria-hidden="true"></i> All Jobs
                      </a>
                      <a class="navbar-item agency-colour" routerLink="/agency-nurse-job-listing"
                        (click)="toggleMenu()">
                        <i class="fa fa-user-nurse" aria-hidden="true"></i> Agency Nurses
                      </a>
                      <a class="navbar-item locum-colour" routerLink="/doctors-job-listing" (click)="toggleMenu()">
                        <i class="fa fa-user-md" aria-hidden="true"></i> Locum Doctors
                      </a>
                      <a class="navbar-item allied-health-colour" routerLink="/allied-health-jobs"
                        (click)="toggleMenu()">
                        <i class="fa fa-medkit" aria-hidden="true"></i> AHP / HSS
                      </a>
                      <!-- <a class="navbar-item" routerLink="/assignments" (click)="toggleMenu()">
                        <i class="fa fa-clock" aria-hidden="true"></i> Assignments
                      </a>
                      <a class="navbar-item" routerLink="/regions" (click)="toggleMenu()">
                        <i class="fa fa-map" aria-hidden="true"></i> By Region
                      </a>
                      <a class="navbar-item" routerLink="/skills" (click)="toggleMenu()">
                        <i class="fa fa-puzzle-piece" aria-hidden="true"></i> By Skill
                      </a> -->
                    </div>
                  </li>
                  <li class="navbar-item has-dropdown"
                    [ngClass]="{'is-hoverable': isHoverable, 'is-active': isDropdownActive('candidates')}">
                    <a href="/candidates"
                      (click)="toggleDropdown($event, 'candidates'); $event.preventDefault()">Candidates
                      <span class="icon-container">
                        <span class="is-hidden-mobile"
                          [ngClass]="{'icon-down': !isDropdownActive('candidates'), 'icon-up': isDropdownActive('candidates')}"></span>
                        <span class="is-hidden-tablet"
                          [ngClass]="{'icon-down': !isDropdownActive('candidates'), 'icon-up': isDropdownActive('candidates')}"></span>
                      </span>
                    </a>
                    <div class="navbar-dropdown is-boxed" *ngIf="isDropdownActive('candidates') || isHoverable"
                      [ngClass]="{'show': isDropdownActive('candidates')}">
                      <a class="navbar-item agency-colour" routerLink="/agency-nurses" (click)="toggleMenu()">
                        <i class="fa fa-user-nurse" aria-hidden="true"></i> Agency Nurses
                      </a>
                      <a class="navbar-item locum-colour" routerLink="/doctors" (click)="toggleMenu()">
                        <i class="fa fa-user-md" aria-hidden="true"></i> Locum Doctors
                      </a>
                      <a class="navbar-item allied-health-colour" routerLink="/allied-health" (click)="toggleMenu()">
                        <i class="fa fa-medkit" aria-hidden="true"></i> AHP / HSS
                      </a>
                    </div>
                  </li>

                  <li class="navbar-item has-dropdown"
                    [ngClass]="{'is-hoverable': isHoverable, 'is-active': isDropdownActive('services')}">
                    <a href="/services" (click)="toggleDropdown($event, 'services'); $event.preventDefault()">Services
                      <span class="icon-container">
                        <span class="is-hidden-mobile"
                          [ngClass]="{'icon-down': !isDropdownActive('services'), 'icon-up': isDropdownActive('services')}"></span>
                        <span class="is-hidden-tablet"
                          [ngClass]="{'icon-down': !isDropdownActive('services'), 'icon-up': isDropdownActive('services')}"></span>
                      </span>
                    </a>
                    <div class="navbar-dropdown is-boxed" *ngIf="isDropdownActive('services') || isHoverable"
                      [ngClass]="{'show': isDropdownActive('services')}">
                      <a class="navbar-item" routerLink="/services" (click)="toggleMenu()">
                        <i class="fa fa-circle-h" aria-hidden="true"></i> Services
                      </a>
                      <a class="navbar-item" routerLink="/mind-care" (click)="toggleMenu()">
                        <i class="fa fa-brain" aria-hidden="true"></i> Mind Care
                      </a>
                      <a class="navbar-item" routerLink="/complex-care" (click)="toggleMenu()">
                        <i class="fa fa-hand-holding-hand" aria-hidden="true"></i> Complex care
                      </a>
                    </div>
                  </li>

                  <li class="navbar-item has-dropdown"
                    [ngClass]="{'is-hoverable': isHoverable, 'is-active': isDropdownActive('about')}">
                    <a href="/about" (click)="toggleDropdown($event, 'about'); $event.preventDefault()">About
                      <span class="icon-container">
                        <span class="is-hidden-mobile"
                          [ngClass]="{'icon-down': !isDropdownActive('about'), 'icon-up': isDropdownActive('about')}"></span>
                        <span class="is-hidden-tablet"
                          [ngClass]="{'icon-down': !isDropdownActive('about'), 'icon-up': isDropdownActive('about')}"></span>
                      </span>
                    </a>
                    <div class="navbar-dropdown is-boxed" *ngIf="isDropdownActive('about') || isHoverable"
                      [ngClass]="{'show': isDropdownActive('about')}">
                      <a class="navbar-item" routerLink="/about" (click)="toggleMenu()">
                        <i class="fa fa-info-circle" aria-hidden="true"></i> About HP
                      </a>
                      <a class="navbar-item" routerLink="/nurse-journey" (click)="toggleMenu()">
                        <i class="fa fa-road" aria-hidden="true"></i> Onboarding
                      </a>
                      <a class="navbar-item" routerLink="/careers" (click)="toggleMenu()">
                        <i class="fa fa-folder" aria-hidden="true"></i> Careers
                      </a>
                      <a class="navbar-item" routerLink="/open-day" (click)="toggleMenu()">
                        <i class="fa fa-door-open" aria-hidden="true"></i> Open Day
                      </a>
                    </div>
                  </li>
                  <li>
                    <a class="has-text-weight-bold" routerLink="/mynurseapp" (click)="toggleMenu()">MyNurseApp</a>
                  </li>
                  <li>
                    <a class="" routerLink="/for-hospitals-trusts" (click)="toggleMenu()">Hospitals&Trusts</a>
                  </li>
                </ul>
              </div>


              <ul class="button-links">
                <li><a routerLink="/join-us" class="lime__green--btn">Join Now</a></li>
                <li><a href="https://nurseapp.healthprofessionals.co.uk" target="_blank"
                    class="dark__blue--btn">MyNurseApp Login</a></li>
              </ul>
            </div>

          </div>

        </div>

      </div>

    </div>

  </nav>

</header>