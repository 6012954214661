<section class="section">
  <div class="container">
    <h2>Health Professionals Privacy Statement </h2>


    <p>
      We are a recruitment agency and home care services provider which provides services to its clients, nurses and carers. We must process personal data (including sensitive personal data) so that it can provide these services – in doing so, we act as a data controller.
    </p>
    <p>
      You may give your personal details to us directly, such as on an application or registration form or via our website, or we may collect them from another source such as a jobs board. we must have a legal basis for processing your personal data. For the purposes of providing you with work-finding services and/or information relating to roles relevant to you we will only use your personal data in accordance with this privacy statement. At all times we will comply with current data protection laws.
    </p>
    <!--Contents

  Collection and use of personal data

  Purpose of processing and legal basis

  Legitimate interest

  Statutory/contractual requirement

  Recipients of data

  Data retention

  Your rights

  Cookies

  Login files

  Links to external sites

  Sale of the business

  Data security

  Changes to this privacy statement

  Complaints or queries-->
    <h3>1. Collection and use of personal data </h3>
    <p><span class="SubHead">A. Purpose of processing and legal basis </span></p>
    <p>
      We will collect your personal data (which may include sensitive personal data) and will process your personal data for the purposes of providing you with work-finding services. This includes, for example, contacting you about work opportunities, assessing your suitability for those opportunities, updating our databases, putting you forward for work, arranging payments to you, developing and managing our services and relationship with you and our clients and informing you about our other services from time to time.
    </p>
    <p>
      If you have opted-in we may also send you news and marketing information via email/text.  You can opt-out from receiving these at any time by clicking “unsubscribe” when you receive these communications from us.
    </p>
    <p>
      In some cases we may be required to use your data for the purpose of investigating, reporting and detecting crime and also to comply with laws that apply to us. We may also use your information during the course of internal audits to demonstrate our compliance with certain industry standards as well as professional compliance matters.
    </p>
    <p>
      We must have a legal basis to process your personal data. The legal bases we rely upon to offer our work-finding services to you are:
    </p>
    <ul>
      <li>- Your consent; </li>
      <li>- Where we have a legitimate interest; </li>
      <li>- To comply with a legal obligation that we have; </li>
      <li>- To fulfil a contractual obligation that we have with you. </li>
    </ul>
    <p><span class="SubHead">B. Legitimate interest  </span></p>
    <p>
      This is where we have a legitimate reason to process your data provided it is reasonable and does not go against what you would reasonably expect from us.  Where we have relied on a legitimate interest to process your personal data our legitimate interests is/are as follows:
    </p>
    <ul>
      <li>- managing our database and keeping work-seeker records up to date;  </li>
      <li>- providing work-finding services to you and our clients;  </li>
      <li>- contacting you to seek your consent where we need it;  </li>
      <li>- giving you information about similar products or services that you have used from us recently. </li>
    </ul>
    <p><span class="SubHead">C. Statutory/contractual requirement  </span></p>
    <p>
      We have certain legal and contractual requirements to collect personal data (e.g. to comply with the Conduct of Employment Agencies and Employment Businesses Regulations 2003, immigration and tax legislation, and in some circumstances safeguarding requirements).  Our clients may also require this personal data, and/or we may need your data to enter into a contract with you. If you do not give us personal data we need to collect we may not be able to continue to provide work-finding services to you.
    </p>
    <p><span class="SubHead">D. Recipient/s of data </span></p>
    <p>
      We will process your personal data and/or sensitive personal data with the following recipients:
    </p>
    <ul>
      <li>- clients (whom we may introduce or supply you to);  </li>
      <li>- former employers whom we may seek references from;  </li>
      <li>- other recruitment agencies in the supply chain;  </li>
      <li>- our legal and accounting advisers including our own auditors as well as auditors appointed by the NHS for compliance purposes.  </li>
    </ul>
    <p></p>
    <h3>1. Data retention </h3>
    <p>
      We will retain your personal data only for as long as is necessary for the purpose we collect it. Different laws may also require us to keep different data for different periods of time. For example, the Conduct of Employment Agencies and Employment Businesses Regulations 2003, require us to keep work-seeker records for at least one year from (a) the date of their creation or (b) after the date on which we last provide you with work-finding services.
    </p>
    <p>
      We must also keep your payroll records, holiday pay, sick pay and pensions auto-enrolment records for as long as is legally required by HMRC and associated national minimum wage, social security and tax legislation. This is currently 3 to 6 years.
    </p>
    <p>
      Where we have obtained your consent to process your personal data and any sensitive personal data, we will do so in line with our retention policy, a copy of which is set out below after the end of this privacy statement. Upon expiry of that period, we will seek further consent from you. Where consent is not granted, we will cease to process your personal data and any sensitive personal data.
    </p>
    <h3>1. Your rights </h3>
    <p>
      Please be aware that you have the following data protection rights:
    </p>
    <ul>
      <li>- The right to be informed about the personal data we process on you; </li>
      <li>- The right of access to the personal data we process on you; </li>
      <li>- The right to rectification of your personal data; </li>
      <li>- The right to erasure of your personal data in certain circumstances; </li>
      <li>- The right to restrict processing of your personal data; </li>
      <li>- The right to data portability in certain circumstances; </li>
      <li>- The right to object to the processing of your personal data that was based on a public or legitimate interest; </li>
      <li>- The right not to be subjected to automated decision making and profiling; and </li>
      <li>- The right to withdraw consent at any time. </li>
    </ul>
    <p>
      Where you have consented to us processing your personal data and any sensitive personal data, you have the right to withdraw that consent at any time by contacting: Sara Oktemgil: privacy@healthprofessionals.co.uk. Please note that if you withdraw your consent to further processing, that does not affect any processing done prior to the withdrawal of that consent, or which is done according to another legal basis.
    </p>
    <p>
      There may be circumstances where we will still need to process your data for legal or official reasons. Where this is the case, we will tell you and we will restrict the data to only what is necessary for those specific reasons.  .
    </p>
    <p>
      If you believe that any of your data that we process is incorrect or incomplete, please contact us using the details above and we will take reasonable steps to check its accuracy and correct it where necessary.
    </p>
    <p>
      You can also contact us using the above details if you want us to restrict the type or amount of data we process for you, access your personal data or exercise any of the other rights listed above.
    </p>
    <h3>2. Cookies </h3>
    <p>
      We may obtain data about you from cookies.  These are are small text files that are placed on your computer by websites that you visit. They are widely used in order to make websites work, or work more efficiently, as well as to provide information to the owners of the site. Cookies also enable us to deliver more personalised content.
    </p>
    <p>
      At this time, the only cookies that we use are cookies added by Google for Google Analytics purposes.
    </p>
    <p>
      Regarding our nurses using the MyNurseApp, we use cookies for authentication purposes, enhanced user experience and ease of use. We store user authentication cookies for each use of the MyNurseApp by a user, so that when a user sets a basic setting for a particular entry into the system, it only keeps that authentication data for the duration of the specific session. In this way. a suer does not need to keep resetting during a particular session and indeed a user cannot access the MyNurseApp unless it is able to hold that data for that particular session.
    </p>
    <h3>1. Log Files </h3>
    <p>We use IP addresses to analyse trends, administer the site, track users’ movements, and to gather broad demographic information for aggregate use.  IP addresses are not linked to personally identifiable information. </p>
    <h3>2. Links to external websites </h3>
    <p>
      Our website may contains links to other external websites. Please be aware that we are not responsible for the privacy practices of such other sites.  When you leave our site, we encourage you to read the privacy statements of each and every website that collects personally identifiable information. This privacy statement applies solely to information collected by our website.
    </p>
    <h3>3. Sale of business </h3>
    <p>
      If our business is sold or integrated with another business, your details may be disclosed to our advisers and any prospective purchasers and their advisers and will be passed on to the new owners of the business.
    </p>
    <h3>4. Data Security </h3>
    <p>
      We take every reasonable precaution to protect our users’ information. Specifically, all of our web applications force an SSL (secure) connection to our servers. We use an authentication service to allow secure access to user information. Our authentication provider undergoes an ISO 27001/27018 audit annually. This authentication provider is also CSA STAR certified as well as being GDPR ready. We also make it mandatory for users to confirm their email addresses on creation of an account databases that store user information are only accessible by services that are behind a robust firewall.
    </p>
    <p>
      Only employees who need the information to perform a specific job (for example, consultants, our accounts clerk or a marketing assistant) are granted access to your information.
    </p>
    <p>
      We use all reasonable efforts to safeguard your personal information. However, you should be aware that the use of email/ the Internet is not entirely secure and, for this reason, we cannot guarantee the security or integrity of any personal information which is transferred from you or to you via email/ the Internet.
    </p>
    <p>
      If you share a device with others we recommend that you do not select the “remember my details” function when that option is offered.
    </p>
    <p>
      If you have any questions about the security at our website, you can email Sara Oktemgil: privacy@healthprofessionals.co.uk.
    </p>
    <h3>5. Changes to this privacy statement</h3>
    <p>
      We will update this privacy statement from time to time.  We will post any changes on the statement with revision dates.  If we make any material changes, we will notify you.
    </p>
    <h3>6. Complaints or queries </h3>
    <p>
      If you wish to complain about this privacy notice or any of the procedures set out in it please contact Sara Oktemgil: privacy@healthprofessionals.co.uk.
    </p>
    <p>
      You also have the right to raise concerns with Information Commissioner’s Office on 0303 123 1113 or at https://ico.org.uk/concerns/, or any other relevant supervisory authority should your personal data be processed outside of the UK, if you believe that your data protection rights have not been adhered to.
    </p>



  </div>
</section>
